import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const PrimevalGarden = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/battles' className='trn' data-trn-key='wiki_battles'>Battles</NavLink>
						<a>Forgotten Primeval Garden</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title'>Forgotten Primeval Garden</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='/assets/img/wiki/primevalgarden_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<div className='row'>
							<div className='col-6'>
								<ul className='trn' data-trn-key='label_primevalgarden_01'>
									<li>Forgotten Primeval Garden is a daily dimensional hunting zone for characters of level 76 and higher higher.</li>
									<li>Entrance is available via special hunting zone interface.</li>
									<li>Teleportation fee is 10,000 adena.</li>
									<li>After teleportation, there’ll be a timer for 1 hour (basic time of stay) near the mini-map. Amount of time left can be viewed on the left of the mini-map and in special hunting zone interface.</li>
									<li>The time of stay can be increased up to 300 minutes using <strong>Forgotten Primeval Garden`s Time Stone</strong>, which adds 60 minutes.</li>
									<li>You can also leave the zone before time runs out by using a Scroll of Escape. You can’t use teleportation function to leave the zone.</li>
									<li>My Teleports and summoning are not available in the zone.</li>
									<li>PvP is available in the zone, and there’s penalty for death.</li>
								</ul>
								<hr className='invisible' />
								<h3 className='wiki-subtitle trn' data-trn-key='label_guide'>Guide</h3>
								<ul className='trn' data-trn-key='label_primevalgarden_02'>
									<li>After being teleported, the character will be near <strong>NPC Kiku</strong>, who can teleport the character to the other part of the hunting zone.</li>
									<li>Special monsters Kalix and Kastia`s Elite Captain drop Master`s Books.</li>
								</ul>
							</div>
							<img className='wiki-featured-image float-right' style={{width: '100%', maxWidth: 400 }} src='/assets/img/wiki/primevalgarden_npc.jpg' alt='' />
						</div>
						
					</div>
				</div>
			</div>
		</div>		
	)
}

export default PrimevalGarden