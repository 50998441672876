import React from 'react';
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Statpoints = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a className='trn' data-trn-key='label_charpoints'>Characteristic Points</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_charpoints'>Characteristic Points</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='../assets/img/wiki/characteristics_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_characteristic_01'>
							<li>Characteristic points is divided by STR, DEX, CON, INT, WIT and MEN.</li>
							<li>Each race/class have certain points for each characteristic. You can find information and additional related actions in the Character window (Alt + T).</li>
							<li>The base characteristic points give impact to the character battle status depending the value:</li>
						</ul>
						<table>
						    <thead>
						        <tr>
						            <th>Stat</th>
						            <th className='trn' data-trn-key='label_impact'>Impact</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>STR</td>
						            <td>P. Atk.</td>
						        </tr>
						        <tr>
						            <td>DEX</td>
						            <td>P. Critical Rate, P. Accuracy, P. Evasion, Atk. Spd.</td>
						        </tr>
						        <tr>
						            <td>CON</td>
						            <td>Max. HP/CP; HP/CP recovery speed, weight limit, lung capacity, Shield Defense Rate.</td>
						        </tr>
						        <tr>
						            <td>INT</td>
						            <td>M. Atk.</td>
						        </tr>
						        <tr>
						            <td>WIT</td>
						            <td>M. Critical Rate, M. Accuracy, M. Evasion, Casting Spd., Magic Resistance.</td>
						        </tr>
						        <tr>
						            <td>MEN</td>
						            <td>M. Def., Max. MP and MP restoration speed, M. cancel rate.</td>
						        </tr>
						    </tbody>
						</table>


						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_upgradingcharacteristics'>Upgrading Characteristics</h3>
						<ul className='trn' data-trn-key='label_characteristic_02'>
							<li>A character who’s level 76 and above gets an extra stat point, each level, that you can distribute it when leveling up.</li>
							<li>The distributed points can be reset for a certain fee by clicking the Reset button. The stats points spent are retained.	
							</li>
							<li>Once a certain stats value is obtained, a bonus passive skill is activated.</li>
							<li>There are 3 levels of bonus Skills, all the Skills are passive:</li>
						</ul>
						<div className='table-scrolling'>
						    <table>
						        <thead>
						            <tr>
						                <th width='100'>Stat</th>
						                <th width='260'>
						                	Bonus <span className='label_lv'>Lv.</span> 1<br/>
						                	(<span className='trn' data-trn-key='label_value'>Value</span>) <span className='trn' data-trn-key='label_effect'>Effect</span>
						                </th>
						                <th width='260'>
						                	Bonus <span className='label_lv'>Lv.</span> 2<br/>
						                	(<span className='trn' data-trn-key='label_value'>Value</span>) <span className='trn' data-trn-key='label_effect'>Effect</span>
						                </th>
						                <th width='260'>
						                	Bonus <span className='label_lv'>Lv.</span> 3<br/>
						                	(<span className='trn' data-trn-key='label_value'>Value</span>) <span className='trn' data-trn-key='label_effect'>Effect</span>
						                </th>
						            </tr>
						        </thead>
						        <tbody>
						            <tr>
						                <td><strong>STR</strong></td>
						                <td>(60) P. Atk. +20</td>
						                <td>(70) P. Critical Damage +200</td>
						                <td>(90) P. Skill Damage +10%</td>
						            </tr>
						            <tr>
						                <td><strong>INT</strong></td>
						                <td>(60) M. Atk. +20</td>
						                <td>(70) M. Critical Damage +200</td>
						                <td>(90) M. Skill Damage +10%</td>
						            </tr>
						            <tr>
						                <td><strong>DEX</strong></td>
						                <td>(50) P. Critical Rate +50</td>
						                <td>(60) P. Skills Cooldown -10%</td>
						                <td>(80) P. Evasion +20%</td>
						            </tr>
						            <tr>
						                <td><strong>WIT</strong></td>
						                <td>(40) M. Critical Rate +50</td>
						                <td>(50) M. Skills Cooldown -10%</td>
						                <td>(70) M. Evasion +20%</td>
						            </tr>
						            <tr>
						                <td><strong>CON</strong></td>
						                <td>(50) P. Def. +200</td>
						                <td>(65) Max. HP +1000</td>
						                <td>(90) HP Recovery Bonus +10</td>
						            </tr>
						            <tr>
						                <td><strong>MEN</strong></td>
						                <td>(45) M. Def. +200</td>
						                <td>(60) Max. MP +1000</td>
						                <td>(85) MP Recovery Bonus +10</td>
						            </tr>
						        </tbody>
						    </table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle'>Dyes / tattoos</h3>
						<ul className='trn' data-trn-key='label_characteristic_03'>
							<li>Within Tattoo, a Basic Characteristic can be increased up to +15.</li>
							<li>Certain dyes are used for making tattoos for 1st, 2nd or 3rd class characters.</li>
							<li>1st class characters can wear up to 2 tattoos and 2nd/3rd class characters can wear up to 3 tattoos.</li>
							<li>You can find information about Dyes/Tattoos in the Character's Inventory (Alt + V).</li>
						</ul>

						<hr className='invisible' />
						<p><strong><span className='trn' data-trn-key='label_howtoobtain'>How to obtain</span> dyes</strong></p>
						<ul className='trn' data-trn-key='label_characteristic_04'>
							<li>
								In each township, Dye Merchants or Grocery Shops sell dyes for making tattoos.
								<ul>
									<li>Dyes and Greater Dyes can be purchased from both NPCs. Used by 1st and 2nd class characters.</li>
									<li>Best Dyes can only be purchased from Dye Merchants located in the Town of Aden and the Town of Giran. Used by 3rd class characters.</li>
								</ul>
							</li>
							<li>
								Hunting Monsters
								<ul>
									<li>You can get dyes as loot from Mobs or when you use the  Sweeper skill.</li>
								</ul>
							</li>
						</ul>

						<hr className='invisible' />
						<p><strong>Tattoos: <span className='trn' data-trn-key='label_process'>about the process</span></strong></p>
						<ul className='trn' data-trn-key='label_characteristic_05'>
						    <li>To make a tattoo, talk to NPC Symbol Maker in any township (except starting villages) and choose the 'Make a tattoo' option in the dialogue.</li>
						    <li>You'll need to pay a certain amount of Adena for making a tattoo. The better the tattoo, the bigger the price.</li>
						    <li>To make a tattoo, you need 10 piece of the same dye.</li>
						    <li>The dye for your tattoo should be placed into your inventory. If you have dyes of different kinds, choose the necessary one from the list.</li>
						    <li>If you already have 3 tattoos and you want a new one, you need to remove one of the old ones first.</li>
						</ul>

					</div>
				</div>
			</div>
		</div>
	)
}

export default Statpoints