import React from 'react'

const Terms = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h2 className='internal-title trn' data-trn-key='terms'>
					Terms & Conditions
				</h2>
				<div className='box-wrapper'>
					<div className='box-content wiki-content'>
					    <p>Cookies and other technologies employed by L2Abyss and its partners.</p>
					    <h4>WHAT ARE COOKIES?</h4>
					    <p>
					        Cookies are pieces of data that websites you visit can set on your browser and device hard drive. The term may also be used to refer to other technologies that when employed in a certain manner may be able to track the online
					        behavior of a web user.
					    </p>
					    <p>
					        L2Abyss and its partners use a number of technologies to support and improve the gaming experience we offer. Some of these technologies are used to identify users when they are on L2Abyss games and others may be used to track
					        unique web users. This page was developed to describe the technologies we use, including how they work, our purposes for using them and your choices about their use.
					    </p>
					    <h4>TECHNOLOGY L2Abyss USES</h4>
					    <p><b>Local Shared Objects (LSOs), which are also known as “flash cookies”, are used to:</b></p>
					    <ul>
					        <li>Identify devices so we can prevent and respond to fraud.</li>
					        <li>Store game preferences so we can present the game experience you want. These preferences let us know things like whether you want music on or off or whether you want us to hide certain L2Abyss games.</li>
					        <li>Store game assets (files) on your device so you can have faster load times. To learn more about LSOs and how to manage them visit Adobe.</li>
					    </ul>
					    <p><b>Browser Cookies are used by L2Abyss primarily to:</b></p>
					    <ul>
					        <li>Identify specific users and track whether they are logged in (Authentication)</li>
					        <li>Customize site content and remember site preferences.</li>
					    </ul>
					    <p>
					        <b>
					            There are a number of ways to manage browser cookies on your device. Most major browsers offer choices for whether and how you might receive future cookies and for deleting cookies already on your machine. Here are links for
					            some of the larger browsers:
					        </b>
					    </p>
					    <ul>
					        <li>Chrome</li>
					        <li>Firefox</li>
					        <li>Safari</li>
					        <li>Internet Explorer</li>
					    </ul>
					    <p>
					        Html eTags is a technology that allows a web service to validate the temporary storage (cache) of web documents like html pages and images. They are used by L2Abyss or service providers working on L2Abyss’s behalf to optimize web
					        caching for each user.
					    </p>
					    <p>You may be able to remove eTags from your browser by following your browser instructions for clearing cache.</p>
					    <p>
					        Beacons, pixel tags, clear gifs are all terms for similar technology that often works in the same way. Small strings of (software) code that track events such as when a web user visits a page or opens an email. They are used by
					        L2Abyss to measure the effectiveness of our email campaigns, deliver more relevant content and manage advertising for L2Abyss’s games.
					    </p>
					    <p>
					        Generally you should be able manage beacons and pixel tags in the same way that you manage cookies – using your browser’s preferences (see browser links above). NOTE: There are several browser plugins available to web users who want
					        to be better informed about and have more control over these technologies. Two popular ones are Ghostery and Abine. Other Companies’ Technology L2Abyss uses within its games, apps and sites.
					    </p>
					    <h4>ANALYTICS</h4>
					    <p>
					        L2Abyss analyzes traffic and other end user activity to improve the user experience. L2Abyss and companies working its behalf may use web server log files and tracking technologies to collect and analyze certain types of technical
					        information, such as cookies, IP addresses, device type and usage information including time spent on particular pages or number of clicks.
					    </p>
					    <p>
					        One of our analytics partners is Google Analytics. Google Analytics uses browser cookies to generate statistics about visitors to websites, such as the number of page views or times of peak traffic. If you don’t want your anonymous
					        browser data to be collected by Google Analytics when you visit sites that use Google Analytics, you can install an opt-out in your web browser.
					    </p>
					    <p>To learn more or inform Google that you do not want data about your website visit sent to Google, go here</p>
					    <h4>ADS FOR L2Abyss GAMES (L2Abyss AS AN ADVERTISER)</h4>
					    <p>
					        L2Abyss works with web publishers and advertising networks (“Advertising Partners”) to deliver interest-based ads to users that have visited L2Abyss sites, played L2Abyss’s games or accessed L2Abyss’s content including videos
					        (“L2Abyss Users”). This practice is also referred to as online behavioral advertising and here’s a brief description of how it works at L2Abyss.
					    </p>
					    <p>
					        Our Advertising Partners use software that recognizes pixels and cookies (identifiers) that are placed on the browsers of L2Abyss Users. When the ad software recognizes these identifiers, they deliver ads for L2Abyss’s products
					        and services to L2Abyss Users who are on other web sites. Many of these ads for L2Abyss’s products will include an icon the ad recipient can click to learn more about the ad, why she received it and how to exercise choice. The
					        icon displayed may range from the ad choices icon to a question mark (“?”) in the corner of the ad unit.
					    </p>
					    <h4>ADS FOR OTHER SITES AND SERVICES (L2Abyss AS A PUBLISHER)</h4>
					    <p>
					        As you know L2Abyss is a free service. One of the ways we generate revenue, which supports our ability to develop and publish fun games, is by publishing ads. Our advertising partners may serve targeted or personalized ads that are
					        intended to be more relevant to you.
					    </p>
					    <h4>ADVERTISING IN WEB GAMES AND EMAILS</h4>
					    <p>
					        Tracking Pixels, Web Bugs, Pixel Tags, Browser Cookies – these are all references to technology that we permit others to set or read within our service. These technologies may be used to identify the interests and behavior of web
					        users and target advertising to them based on those interests. They are also used to limit the number of times a user may see a particular ad or prevent certain ads from being shown at all.
					    </p>
					    <h4>ADVERTISING IN MOBILE GAMES</h4>
					    <p>
					        When you play our mobile games we or our advertising partners may use one or more of several different identifiers for your device, including UDID and Mac Address, to target and deliver ads in our games. This means that your device
					        identifier may be accessed by third party ad networks and used to (a) help manage the number and types of ads you see; (b) to track the source of installs related to ads seen in other developer apps, and; (c) identify the interests
					        and behavior of users and target advertising to them based on those interests.
					    </p>
					    <p>
					        Identifiers L2Abyss uses IP Addresses are numbers assigned to the device you use to access the internet. L2Abyss, like many other web services, automatically collects IP Addresses from visitors to its games and sites. L2Abyss
					        puts this information to several uses including providing security features and identifying general user locations, which may be used to target advertising.
					    </p>
					    <p>
					        Mobile Identifiers are numbers that identify devices or access points for devices that access L2Abyss’s mobile offerings. When you play our mobile games we may use one or more of several different identifiers for your device,
					        including UDID and Mac Address, to identify you and your game account and to recognize players from one L2Abyss app to another. If you elect to participate in an SMS program we will use and may store your mobile phone number.
					    </p>
					    <h4>FACEBOOK AND OTHER SOCIAL NETWORKS</h4>
					    <p>
					        In many instances users must be logged in to Facebook or other social networks to access our games. We typically collect and store the social network user ID and use it for several things including identifying specific players and
					        accounts.
					    </p>
					    <p>L2Abyss IDs</p>
					    <p>When you create an account with L2Abyss or establish a profile (like on L2Abyss.com) we will generate and assign a L2Abyss ID to your account.</p>
					    <p>NOTE: The social networks that offer L2Abyss games may use tracking technology of their own to recognize users and provide certain products and features. To learn more about Facebook’s practices visit Facebook.</p>
						
					</div>
				</div>
			</div>
		</div>
	)
}

export default Terms