import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Battles = props => {
	return (
	<div id='internal' className='wiki'>
		<div className='wrapper'>
			<h1 className='internal-title'>
				Essence Wiki
			</h1>
			<div className='box-wrapper'>
				<HeaderWiki />
				<div className='box-breadcrumb'>
					<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
					<a className='trn' data-trn-key='wiki_battles'>Battles</a>
				</div>

				<div className='box-content'>
					<h2 className='wiki-title trn' data-trn-key='wiki_battles'>Battle Systems</h2>
					<p className='wiki-paragraph trn' data-trn-key='wiki_battles_desc'>
						All the battle systems and content related to PvP and PvE can be found here. Discover all the resources and be a part from amazing fights.
					</p>
					<div className='wiki-boxes'>
						<NavLink to="/olympiads" className='wiki-box'>
							<img src='../assets/img/wiki/img_wiki_olympiad.jpg' alt='' />
							<h3 className='trn' data-trn-key='label_olympiads'>
								Olympiads
							</h3>
							<p className='trn' data-trn-key='label_olympiads_desc'>
								Olympiad is a tournament for characters to fight for the title of Hero. 
							</p>
						</NavLink>
						<NavLink to="/castlesieges" className='wiki-box'>
							<img src='../assets/img/wiki/img_wiki_sieges.jpg' alt='' />
							<h3 className='trn' data-trn-key='label_castlesieges'>
								Castle Sieges
							</h3>
							<p className='trn' data-trn-key='label_castlesieges_desc'>
								PvP content for clans. A clan owning a castle gets various bonuses.
							</p>
						</NavLink>
						<NavLink to="/orcfortress" className='wiki-box'>
							<img src='../assets/img/wiki/img_wiki_orcfortress.jpg' alt='' />
							<h3 className='trn' data-trn-key='label_orcfortress'>
								Orc Fortress
							</h3>
							<p className='trn' data-trn-key='label_orcfortress_desc'>
								An event that reward players and the clan which has conquered the fortress.
							</p>
						</NavLink>
						<NavLink to="/transcendent" className='wiki-box'>
							<img src='../assets/img/wiki/img_wiki_transcendance.jpg' alt='' />
							<h3 className='trn' data-trn-key='label_transcendent'>
								Transcendent Instance Zones
							</h3>
							<p className='trn' data-trn-key='label_transcendent_desc'>
								Solo special hunting zone that rewards with a lot of exp and craft points.
							</p>
						</NavLink>
						<NavLink to="/primevalgarden" className='wiki-box'>
							<img src='../assets/img/wiki/img_wiki_primevalgarden.jpg' alt='' />
							<h3>
								Forgotten Primeval Garden
							</h3>
							<p className='trn' data-trn-key='label_primevalgarden_desc'>
								A daily dimensional hunting zone for characters of level 76 and higher.
							</p>
						</NavLink>
						<NavLink to="/primevalisle" className='wiki-box'>
							<img src='../assets/img/wiki/img_wiki_primevalisle.jpg' alt='' />
							<h3>
								Primeval Isle
							</h3>
							<p className='trn' data-trn-key='label_primevalisle_desc'>
								A daily dimensional hunting zone for characters of level 40 and higher.
							</p>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	</div>		
	)
}

export default Battles