import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Transcendent = props => {
	return (
	<div id='internal' className='wiki'>
		<div className='wrapper'>
			<h1 className='internal-title'>
				Essence Wiki
			</h1>
			<div className='box-wrapper'>
				<HeaderWiki />
				<div className='box-breadcrumb'>
					<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
					<NavLink to='/battles' className='trn' data-trn-key='wiki_battles'>Battles</NavLink>
					<a className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zones</a>
				</div>

				<div className='box-content wiki-content'>
					<div className='alert trn margin-small-bottom' data-trn-key='label_feature_disabled'>
						This feature is currently disabled on Lineage II Essence.Land.
					</div>
					<h2 className='wiki-title trn' data-trn-key='label_transcendent'>Transcendent Instance Zones</h2>
					<div className='wiki-title-image'>
						<img className='wiki-featured-image' src='/assets/img/wiki/transcendent_header.jpg' alt='' />
					</div>
					<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
					<ul className='trn' data-trn-key='label_transcendent_01'>
						<li>
							Transcendent instance zones are special hunting zones that are available via special hunting zones interface, which can be opened in the sidebar or from the menu (Alt + X -> Special hunting zones).
						</li>
					    <li>
					        Availability of entry into transcendent instance zones is reset daily at 6:30 AM.
					        <ul>
					            <li>All transcendent instance zones have a shared availability time, and if the character has already visited one zone, they won’t be able to visit another before the daily reset.</li>
					        </ul>
					    </li>
					    <li>
					        Walkthrough time of a transcendent instance zone is 10 minutes, after which the character will be teleported out of the zone.
					        <ul>
					            <li>Stopping and extension of stay time aren’t available in transcendent instance zones.</li>
					        </ul>
					    </li>
					    <li>
					        There’s no XP penalty for dying in transcendent instance zones.
					        <ul>
					            <li>If a character died in a transcendent instance zone and left it, they have 3 minutes to return.</li>
					            <li>Re-entry to transcendent instance zones is free of charge.</li>
					        </ul>
					    </li>
					    <li>After entry, the character is placed near NPC Grand Master, there are no monsters in the zone.</li>
					    <li>
					        After talking to NPC Grand Master, the character receives the buff Grand Master's Transcendent Blessing, which improves some skills, the zone’s timer starts (10 minutes), and monsters start to appear.
					        <ul>
					            <li>The buff makes the main attack skill of your class AoE, damaging several target in its range. That skill is bordered with yellow frame (like the border for Earth Attribute).</li>
					            <li>After the buff is over or you leave the transcendent instance zone, skills return to normal.</li>
					        </ul>
					    </li>
					    <li>After leaving the transcendent instance zone you can view the results: acquired XP, acquired items and adena.</li>
					    <li>Chaotic characters can’t raise &nbsp;reputation points&nbsp; while hunting monsters in transcendent instance zones.</li>
					    <li>Each zone contains monsters of appropriate level, the higher the level, the better are rewards.</li>
					    <li>Incredible Guardian Kura may appear in Transcendent instance zones. As a reward for defeating him, characters may receive raw materials that replenish the Random Craft bar.</li>
					</ul>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_listtrancendent'>List of transcendent instance zones</h3>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_name'>Name</th>
						            <th style={{ minWidth: 80 }} className='trn' data-trn-key='label_level'>Level</th>
						            <th>Re-entry</th>
						            <th className='trn' data-trn-key='label_fee'>Entrance fee</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td><span className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zone</span> - Sea of Spores</td>
						            <td>40—49</td>
						            <td><span className='trn' data-trn-key='label_daily'>Daily</span>, 6:30</td>
						            <td>10,000 adena</td>
						        </tr>
						        <tr>
						            <td><span className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zone</span> - Enchanted Valley</td>
						            <td>50—59</td>
						            <td><span className='trn' data-trn-key='label_daily'>Daily</span>, 6:30</td>
						            <td>30,000 adena</td>
						        </tr>
						        <tr>
						            <td><span className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zone</span> - Blazing Swamp</td>
						            <td>60—69</td>
						            <td><span className='trn' data-trn-key='label_daily'>Daily</span>, 6:30</td>
						            <td>50,000 adena</td>
						        </tr>
						        <tr>
						            <td><span className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zone</span> - War-Torn Plains</td>
						            <td>70—79</td>
						            <td><span className='trn' data-trn-key='label_daily'>Daily</span>, 6:30</td>
						            <td>100,000 adena</td>
						        </tr>
						        <tr>
						            <td><span className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zone</span> - Dragon Valley</td>
						            <td>80—99</td>
						            <td><span className='trn' data-trn-key='label_daily'>Daily</span>, 6:30</td>
						            <td>10,000 adena</td>
						        </tr>
						        <tr>
						            <td><span className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zone</span> - Sel Mahum Base</td>
						            <td>85—99</td>
						            <td><span className='trn' data-trn-key='label_daily'>Daily</span>, 6:30</td>
						            <td>500,000 adena</td>
						        </tr>
						    </tbody>
						</table>						
					</div>		
				</div>
			</div>
		</div>
	</div>
	)
}

export default Transcendent