import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const LCoinStore = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a>L-Coin Store</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title'>L-Coin Store</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='../assets/img/wiki/lcoinstore_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<p className='trn' data-trn-key='label_lcoinstore_desc'>L-Coin Store is a special shop where you can buy different items for special coins obtained in the game.</p>
						<div>
							<ul className='trn' data-trn-key='label_lcoinstore_01'>
								<li>To buy items, you’ll need L-Coin, which can be obtained during hunting or special packs.</li>
								<li>L-Coins cannot be exchanged, dropped, destroyed, sold in a private shop or other shops.</li>
								<li>Information about L-Coins and L-Coin Store, the amount of the coins your character has, and the button of L-Coin Store, are available in a special window below the map.</li>
								<li>To purchase some items in L-Coin Store, in addition to L-Coins, you might also need a certain amount of Adena.</li>
								<li>There’s a limit on buying certain items in L-Coin Store. The limit is reset every day at 6.30 a.m.</li>
							</ul>
						</div>
						<hr className='invisible' />
						<img src='../assets/img/wiki/lcoin_button_01.png' alt='' />
						<div className='trn' data-trn-key='label_lcoinstore_02'>
							<p>L-coins can be used to teleport instantaneously to several Raid Bosses. At the L-Coin Store, you can buy unique items that will greatly boost your character's stats, enhance your leveling and PvP experience. You can access L-Coin Store by clicking on the button in the right part of the status bar, or from the General Menu: (Alt+X)→ L-Coin Store.</p>
							<p>Starting from level 20, you can obtain L-Coins by hunting regular monsters.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default LCoinStore