import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const ItemEnchanting = props => {
	return (
<div id='internal' className='wiki'>
		<div className='wrapper'>
			<h1 className='internal-title'>
				Essence Wiki
			</h1>
			<div className='box-wrapper'>
				<HeaderWiki />
				<div className='box-breadcrumb'>
					<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
					<NavLink to='/items' className='trn' data-trn-key='wiki_items'>Items</NavLink>
					<a className='trn' data-trn-key='label_itemenchanting'>Item Enchanting</a>
				</div>

				<div className='box-content wiki-content'>
					<h2 className='wiki-title trn' data-trn-key='label_itemenchanting'>Item Enchanting</h2>
					<div className='wiki-title-image'>
						<img className='wiki-featured-image' src='/assets/img/wiki/itemenchant_header.jpg' alt='' />
					</div>
					<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
					<ul className='trn' data-trn-key='label_itemenchanting_01'>
					    <li>Weapons, armor, shields, sigils and accessories can be enchanted using Enchant scrolls for weapons and armor.</li>
					    <li>Enchant scrolls for weapons and armor can be of different grades, from D to A, and can be used to enchant an item of a corresponding grade.</li>
					    <li>Enchantment using scrolls for weapon and armor is safe up to +4.</li>
					    <li>Enchantment increases P./ M. Atk. (Weapon Enchant scrolls) or P./ M. Def. (Armor Enchant scrolls).</li>
					    <li>
					        Special weapons (Boss weapons and Death Knight's Flame Sword) and special armor (for example, Gloves of Silence, Ice Crystal Breastplate) require A-grade Enchant scrolls for weapon and armor. Enchantment adds extra effects and
					        skills.
					    </li>
					    <li>
					        Also can be enchanted: talismans, agathions, hair accessories, belts, cloaks, rare accessories (for example, Queen Ant's Ring, Dragon Valley's Earring). Enchanting of each of those items requires its own special scroll. Enchantment
					        adds extra effects, such as: increased Max HP, Received Damage Decrease, et c.
					    </li>
					    <li>
					        There’s a chance that enchanting will fail. On a failed enchanting attempt, the item is crystallized. The amount of crystals depends on the enchantment level at which the item broke.
					        <ul>
					            <li>If the item had a high enough enchantment level, the character will also receive Enhancement Stones for weapons and armor, see more: Enhancement Stones for weapons and armor.</li>
					        </ul>
					    </li>
					</ul>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_process'>About the process</h3>
					<div className='trn' data-trn-key='label_itemenchanting_02'>
						<ul>
						    <li>Enchantment requires an Enchant scroll and an item that you want to enchant, which should be of the same grade.</li>
						    <li>To open the Enchanting Items interface, click the right mouse button on an Enchant scroll in your inventory.</li>
							<li>On a successful enchantment, the enchantment level increases and a number, like “+1”, appears on the item’s icon and in the name.</li>
						    <li>
						        On a failed enchanting attempt, the item is crystallized, the crystals are the same grade as the crystallized item.
						        <ul>
						            <li>
						                If the enchantment level of the item is high enough, the character will not only receive crystals, but also a <strong>Weapon Enhancement Stone</strong> or
						                <strong>Armor Enhancement Stone</strong> (depending on the type of the item that had been crystallized).
						            </li>
						        </ul>
						    </li>
						</ul>
						<p>Enhancement Stones can be exchanged for Enchant scrolls for weapon/armor via NPC <strong>Galladucci</strong> in <strong>Town of Giran</strong>:</p>
					</div>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th>Item</th>
						            <th  className='trn' data-trn-key='label_price'>Price</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						                <strong>Scroll: Enchant A-grade Armor</strong> (Exchangeable) — 1 pc.
						            </td>
						            <td>
						                368,234 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Armor Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						        <tr>
						            <td><strong>Scroll: Enchant B-grade Armor</strong> (Exchangeable) — 2 pcs.</td>
						            <td>
						                208,234 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Armor Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						        <tr>
						            <td><strong>Scroll: Enchant C-grade Armor</strong> (Exchangeable) — 5 pcs.</td>
						            <td>
						                38,234 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Armor Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						        <tr>
						            <td><strong>Scroll: Enchant D-grade Armor</strong> (Exchangeable) — 10 pcs.</td>
						            <td>
						                8,234 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Armor Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <strong>Scroll: Enchant A-grade Weapon</strong> (Exchangeable) — 1 pc.
						            </td>
						            <td>
						                3,256,544 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Weapon Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <strong>Scroll: Enchant B-grade Weapon</strong> (Exchangeable) 1 pc.
						            </td>
						            <td>
						                656,544 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Weapon Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						        <tr>
						            <td><strong>Scroll: Enchant C-grade Weapon</strong> (Exchangeable) — 2 pcs.</td>
						            <td>
						                316,544 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Weapon Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						        <tr>
						            <td><strong>Scroll: Enchant D-grade Weapon</strong> (Exchangeable) — 5 pcs.</td>
						            <td>
						                156,544 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span><br />
						                Weapon Enhancement Stone — 1 pc.
						            </td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_weapons'>Weapon</h3>
					<div className='trn' data-trn-key='label_itemenchanting_03'>
						<ul>
							<li>When a weapon is enchanted, its P. Atk. and M. Atk. are increased.</li>
							<li>The higher a weapon’s enchantment level, the higher the damage when using Soulshots/Spiritshots</li>
							<li>The increase of P./ M. Atk. from Enchant scrolls depends on the enchantment level. Scroll: Enchant A-grade Weapon gives a higher increase of P./ M. Atk.</li>
						</ul>
						<p>Enchant scrolls for weapons of grades D to B:</p>
					</div>
					<div className='table-scrolling'>
						<table style={{ width: '100%' }}>
						    <thead>
						        <tr>
						        	<th style={{ minWidth: 170 }} className='trn' data-trn-key='label_itemtype'>Item type</th>
						            <th>P. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+1 ~ +3)</th>
						            <th>P. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+4 ~ +10)</th>
						            <th>M. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+1 ~ +3)</th>
						            <th>M. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+4 ~ +10)</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						                <span className='trn' data-trn-key='label_onehandedswords'>One-handed swords</span><br />
						                <span className='trn' data-trn-key='label_onehandedblunts'>One-handed blunts</span><br />
						                <span className='trn' data-trn-key='label_daggers'>Daggers</span><br />
						                <span className='trn' data-trn-key='label_polearms'>Spears</span>
						            </td>
						            <td>+4</td>
						            <td>+8</td>
						            <td>+3</td>
						            <td>+6</td>
						        </tr>
						        <tr>
						            <td>
						                <span className='trn' data-trn-key='label_twohandedblunts'>Two-handed blunts</span><br />
						                <span className='trn' data-trn-key='label_dualswords'>Dual swords</span><br />
						                <span className='trn' data-trn-key='label_fistweapon'>Fist weapons</span>
						            </td>
						            <td>+5</td>
						            <td>+10</td>
						            <td>+3</td>
						            <td>+6</td>
						        </tr>
						        <tr>
						            <td><span className='trn' data-trn-key='label_bows'>Bow</span></td>
						            <td>+8</td>
						            <td>+16</td>
						            <td>+3</td>
						            <td>+6</td>
						        </tr>
						    </tbody>
						</table>
					</div>
					<p>Enchant scrolls A-grade weapons:</p>
					<div className='table-scrolling'>
						<table style={{ width: '100%' }}>
						    <thead>
						        <tr>
						        	<th style={{ minWidth: 170 }} className='trn' data-trn-key='label_itemtype'>Item type</th>
						            <th>P. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+1 ~ +3)</th>
						            <th>P. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+4 ~ +10)</th>
						            <th>M. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+1 ~ +3)</th>
						            <th>M. Atk. <span className='trn' data-trn-key='label_added'>added</span><br/>(+4 ~ +10)</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						                <span className='trn' data-trn-key='label_onehandedswords'>One-handed swords</span><br />
						                <span className='trn' data-trn-key='label_onehandedblunts'>One-handed blunts</span><br />
						                <span className='trn' data-trn-key='label_daggers'>Daggers</span><br />
						                <span className='trn' data-trn-key='label_polearms'>Spears</span>
						            </td>
						            <td>+10</td>
						            <td>+30</td>
						            <td>+6</td>
						            <td>+18</td>
						        </tr>
						        <tr>
						            <td>
						                <span className='trn' data-trn-key='label_twohandedblunts'>Two-handed blunts</span><br />
						                <span className='trn' data-trn-key='label_dualswords'>Dual swords</span><br />
						                <span className='trn' data-trn-key='label_fistweapon'>Fist weapons</span>
						            </td>
						            <td>+12</td>
						            <td>+36</td>
						            <td>+6</td>
						            <td>+18</td>
						        </tr>
						        <tr>
						            <td><span className='trn' data-trn-key='label_bows'>Bow</span></td>
						            <td>+16</td>
						            <td>+48</td>
						            <td>+6</td>
						            <td>+18</td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_armors'>Armor</h3>
					<div className='trn' data-trn-key='label_itemenchanting_04'>
						<ul>
							<li>When armor, shields and sigils are enchanted, their P. Def. is increased. When accessories are enchanted, their M. Def. is increased.</li>
							<li>When all parts of a set are enchanted to +6 ++, extra effects are <span className='trn' data-trn-key='label_added'>added</span>. Higher enchantment levels include the effects from previous levels, for example: +10 set will have all effects of each enchantment level (+6, +7, +8, +9 and +10).</li>
							<li>The increase of P. Def. from Enchant scrolls depends on the enchantment level. Scroll: Enchant A-grade Armor gives a higher increase of P. Def.</li>
						</ul>
						<p>Enchant scrolls for armor of grades D to B:</p>
					</div>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						        	<th className='trn' data-trn-key='label_itemtype'>Item type</th>
						            <th>P. Def. <span className='trn' data-trn-key='label_added'>added</span> (+1 ~ +3)</th>
						            <th>P. Def. <span className='trn' data-trn-key='label_added'>added</span> (+4 ++)</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td><span className='trn' data-trn-key='label_armors'>Armor</span></td>
						            <td>+1</td>
						            <td>+3</td>
						        </tr>
						    </tbody>
						</table>
					</div>
					<p>Enchant scrolls A-grade armor:</p>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						        	<th className='trn' data-trn-key='label_itemtype'>Item type</th>
						            <th>P. Def. <span className='trn' data-trn-key='label_added'>added</span> (+1 ~ +3)</th>
						            <th>P. Def. <span className='trn' data-trn-key='label_added'>added</span> (+4 ++)</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						            	<span className='trn' data-trn-key='label_armors'>Armors</span><br/>
						            	<span className='trn' data-trn-key='label_shields'>Shields</span><br/>
						            	<span className='trn' data-trn-key='label_sigils'>Sigils</span>
						           	</td>
						            <td>+4</td>
						            <td>+9</td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<div className='trn' data-trn-key='label_itemenchanting_05'>
						<p><strong>Armor Enchantment HP increase</strong></p>
						<p>On enchantment level of +4 ++, armor increases character’s Max HP:</p>
						<ul>
							<li>Sealed and unsealed armor gives the same bonus. Armor HP bonus is fixed and isn’t affected by other buffs and modifiers.</li>
						</ul>
					</div>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th>Grade/Armor</th>
						            <th>+1</th>
						            <th>+2</th>
						            <th>+3</th>
						            <th>+4</th>
						            <th>+5</th>
						            <th>+6</th>
						            <th>+7</th>
						            <th>+8</th>
						            <th>+9</th>
						            <th>+10</th>
						            <th>+11</th>
						            <th>+12</th>
						            <th>+13 ++</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>D-grade / Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>9</td>
						            <td>26</td>
						            <td>52</td>
						            <td>54</td>
						            <td>56</td>
						            <td>58</td>
						            <td>60</td>
						            <td>62</td>
						            <td>64</td>
						            <td>64</td>
						        </tr>
						        <tr>
						            <td>D-grade / One-Piece Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>13</td>
						            <td>39</td>
						            <td>78</td>
						            <td>78</td>
						            <td>78</td>
						            <td>78</td>
						            <td>78</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						        </tr>
						        <tr>
						            <td>C-grade / Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>12</td>
						            <td>36</td>
						            <td>71</td>
						            <td>73</td>
						            <td>75</td>
						            <td>77</td>
						            <td>79</td>
						            <td>81</td>
						            <td>83</td>
						            <td>83</td>
						        </tr>
						        <tr>
						            <td>C-grade / One-Piece Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>18</td>
						            <td>54</td>
						            <td>106</td>
						            <td>106</td>
						            <td>106</td>
						            <td>106</td>
						            <td>106</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						        </tr>
						        <tr>
						            <td>B-grade / Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>14</td>
						            <td>42</td>
						            <td>84</td>
						            <td>86</td>
						            <td>88</td>
						            <td>90</td>
						            <td>92</td>
						            <td>94</td>
						            <td>96</td>
						            <td>96</td>
						        </tr>
						        <tr>
						            <td>B-grade / One-Piece Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>21</td>
						            <td>63</td>
						            <td>126</td>
						            <td>126</td>
						            <td>126</td>
						            <td>126</td>
						            <td>126</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						        </tr>
						        <tr>
						            <td>A-grade / Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>16</td>
						            <td>47</td>
						            <td>94</td>
						            <td>96</td>
						            <td>98</td>
						            <td>100</td>
						            <td>102</td>
						            <td>104</td>
						            <td>106</td>
						            <td>106</td>
						        </tr>
						        <tr>
						            <td>A-grade / One-Piece Armor</td>
						            <td>0</td>
						            <td>0</td>
						            <td>0</td>
						            <td>24</td>
						            <td>70</td>
						            <td>141</td>
						            <td>141</td>
						            <td>141</td>
						            <td>141</td>
						            <td>141</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						            <td>&nbsp;</td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<div className='trn' data-trn-key='label_itemenchanting_06'>
						<p><strong>Extra benefits for enchanting armor of grades D to A</strong></p>
						<ul>
							<li>A-grade sets don’t have an extra benefit for enchanting all parts of a set, but there are effects related to enchanting of separate items.</li>
							<li>When enchanting all parts of a set to +6 ++, you’ll receive the following benefits (all consequent effects of a set include the previous effects):</li>
						</ul>
					</div>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th width='150' className='trn' data-trn-key='label_armors'>Armor</th>
						            <th className='trn' data-trn-key='label_enchantment'>Enchantment</th>
						            <th style={{ minWidth: 300 }} className='trn' data-trn-key='label_effect'>Effect</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td rowSpan={5}>Heavy Armor</td>
						            <td>+6</td>
						            <td>
						                Max HP increased by 360<br />
						                Recovery Bonus while standing/ sitting HP +10, MP +4, M. Def. +30
						            </td>
						        </tr>
						        <tr>
						            <td>+7</td>
						            <td>P. Def. + 47, M. Def. + 28</td>
						        </tr>
						        <tr>
						            <td>+8</td>
						            <td>Vampiric Rage +2%, P. Skill Cooldown -4%</td>
						        </tr>
						        <tr>
						            <td>+9</td>
						            <td>Stun Resistance +10%</td>
						        </tr>
						        <tr>
						            <td>+10</td>
						            <td>Bow Resistance +10%, P. Def. +6%</td>
						        </tr>
						        <tr>
						            <td rowSpan={5}>Light Armor</td>
						            <td>+6</td>
						            <td>
						                Max HP increased by 200<br />
						                Max MP increased by 80<br />
						                Recovery Bonus while standing/ sitting HP +10, MP +4, M. Def. +30
						            </td>
						        </tr>
						        <tr>
						            <td>+7</td>
						            <td>P. Def. + 47, M. Def. + 28</td>
						        </tr>
						        <tr>
						            <td>+8</td>
						            <td>Speed +4, P./ M. Skill Power +7%</td>
						        </tr>
						        <tr>
						            <td>+9</td>
						            <td>Stun/ Paralysis/ Mental Resistance +5%</td>
						        </tr>
						        <tr>
						            <td>+10</td>
						            <td>Magic Damage Resistance +15%, M. Def. +7%</td>
						        </tr>
						        <tr>
						            <td className='border-bottom-none' rowSpan={5}>Robe</td>
						            <td>+6</td>
						            <td>
						                Max HP increased by 140<br />
						                Recovery Bonus while standing/ sitting HP +4, MP +6
						            </td>
						        </tr>
						        <tr>
						            <td>+7</td>
						            <td>P. Def. + 47, M. Def. + 28</td>
						        </tr>
						        <tr>
						            <td>+8</td>
						            <td>Power of Healing Magic +52, M. Skill MP Consumption -4%</td>
						        </tr>
						        <tr>
						            <td>+9</td>
						            <td>Paralysis Resistance +10%, Stun Resistance +5%</td>
						        </tr>
						        <tr>
						            <td>+10</td>
						            <td>Debuff Resistance +7%, Received P. Critical Rate -20%</td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<div className='trn' data-trn-key='label_itemenchanting_07'>
						<p><strong>A-grade armor effects</strong></p>
						<ul>
							<li>Special effects are applied when equipping A-grade armor enchanted to +5 or higher.</li>
							<li>Effects are applied to each item separately, even if they are not from the same set.</li>
							<li>Enchantment effect for helmets, top/bottom armor and one-piece armor is the same no matter the type of armor.</li>
						</ul>
					</div>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_enchantment'>Enchantment</th>
						            <th style={{ minWidth: 100 }} className='trn' data-trn-key='label_helmets'>Helmet</th>
						            <th style={{ minWidth: 180 }} className='trn' data-trn-key='label_toparmor'>Top armor</th>
						            <th style={{ minWidth: 180 }}  className='trn' data-trn-key='label_bottomarmor'>Bottom armor</th>
						            <th style={{ minWidth: 180 }}  className='trn' data-trn-key='label_onepiecearmor'>One-piece armor</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>+5</td>
						            <td>HP +50</td>
						            <td>HP Recovery Rate +5</td>
						            <td>MP Recovery Rate +3</td>
						            <td>
						                HP Recovery Rate +5<br />
						                MP Recovery Rate +3
						            </td>
						        </tr>
						        <tr>
						            <td>+6</td>
						            <td>HP +150</td>
						            <td>HP Recovery Rate +10</td>
						            <td>MP Recovery Rate +6</td>
						            <td>
						                HP Recovery Rate +10<br />
						                MP Recovery Rate +6
						            </td>
						        </tr>
						        <tr>
						            <td>+7</td>
						            <td>HP +300</td>
						            <td>HP Recovery Rate +11</td>
						            <td>MP Recovery Rate +7</td>
						            <td>
						                HP Recovery Rate +11<br />
						                MP Recovery Rate +7
						            </td>
						        </tr>
						        <tr>
						            <td>+8</td>
						            <td>HP +500</td>
						            <td>HP Recovery Rate +12</td>
						            <td>MP Recovery Rate +8</td>
						            <td>
						                HP Recovery Rate +12<br />
						                MP Recovery Rate +8
						            </td>
						        </tr>
						        <tr>
						            <td>+9</td>
						            <td>HP +750</td>
						            <td>HP Recovery Rate +13</td>
						            <td>MP Recovery Rate +9</td>
						            <td>
						                HP Recovery Rate +13<br />
						                MP Recovery Rate +9
						            </td>
						        </tr>
						        <tr>
						            <td>+10 ++</td>
						            <td>HP +1000</td>
						            <td>HP Recovery Rate +15</td>
						            <td>MP Recovery Rate +10</td>
						            <td>
						                HP Recovery Rate +15<br />
						                MP Recovery Rate +10
						            </td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<ul>
						<li>
							<strong className='trn' data-trn-key='label_itemenchanting_08'>Gloves and boots are divided into P./M. types</strong>
							<ul>
								<li>P.: Heavy armor, Light Armor (Dark Crystal/Nightmare/Majestic).</li>
								<li>M.: Robe, Light Armor (Tallum).</li>
							</ul>
						</li>
					</ul>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_enchantment'>Enchantment</th>
						            <th style={{ minWidth: 180 }} ><span className='trn' data-trn-key='label_gloves'>Gloves</span> (M.)</th>
						            <th style={{ minWidth: 180 }} ><span className='trn' data-trn-key='label_gloves'>Gloves</span> (P.)</th>
						            <th style={{ minWidth: 180 }} ><span className='trn' data-trn-key='label_boots'>Boots</span> (M.)</th>
						            <th style={{ minWidth: 180 }} ><span className='trn' data-trn-key='label_boots'>Boots</span> (P.)</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>+5</td>
						            <td>M. Skill Power +2%</td>
						            <td>P. Skill Power +2%</td>
						            <td>M. Skill Cooldown -1%</td>
						            <td>P. Skill Cooldown -1%</td>
						        </tr>
						        <tr>
						            <td>+6</td>
						            <td>M. Skill Power +3%</td>
						            <td>P. Skill Power +3%</td>
						            <td>M. Skill Cooldown -2%</td>
						            <td>P. Skill Cooldown -2%</td>
						        </tr>
						        <tr>
						            <td>+7</td>
						            <td>M. Skill Power +5%</td>
						            <td>P. Skill Power +5%</td>
						            <td>M. Skill Cooldown -3%</td>
						            <td>P. Skill Cooldown -3%</td>
						        </tr>
						        <tr className=''>
						            <td>+8</td>
						            <td>M. Skill Power +7%</td>
						            <td>P. Skill Power +7%</td>
						            <td>M. Skill Cooldown -5%</td>
						            <td>P. Skill Cooldown -5%</td>
						        </tr>
						        <tr>
						            <td>+9</td>
						            <td>M. Skill Power +10%</td>
						            <td>P. Skill Power +10%</td>
						            <td>M. Skill Cooldown -7%</td>
						            <td>P. Skill Cooldown -7%</td>
						        </tr>
						        <tr>
						            <td>+10 ++</td>
						            <td>M. Skill Power +15%</td>
						            <td>P. Skill Power +15%</td>
						            <td>M. Skill Cooldown -10%</td>
						            <td>P. Skill Cooldown -10%</td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<h3 className='wiki-subtitle'><span className='trn' data-trn-key='label_accessories'>Accessories</span> (<span className='trn' data-trn-key='label_rings'>rings</span>, <span className='trn' data-trn-key='label_earrings'>earrings</span>, <span className='trn' data-trn-key='label_necklaces'>necklaces</span>)</h3>

					<div className='trn' data-trn-key='label_itemenchanting_09'>
						<ul>
							<li>When accessories are enchanted, their M. Def. is increased.</li>
							<li>The increase of M. Def. from Enchant scrolls depends on the enchantment level. Scroll: Enchant A-grade Armor gives a higher increase of M. Def.</li>
						</ul>
						<p>Enchant scrolls for accessories of grades D to B:</p>
					</div>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_itemtype'>Accessory type</th>
						            <th>M.Def <span className='trn' data-trn-key='label_added'>added</span> (+1 ~ +3)</th>
						            <th>M.Def <span className='trn' data-trn-key='label_added'>added</span> (+4 ++)</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						                <span className='trn' data-trn-key='label_rings'>Rings</span><br />
						                <span className='trn' data-trn-key='label_earrings'>Earrings</span><br />
						                <span className='trn' data-trn-key='label_necklaces'>Necklaces</span>
						            </td>
						            <td>+1</td>
						            <td>+3</td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<p>Enchant scrolls A-grade:</p>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_itemtype'>Accessory type</th>
						            <th>M.Def <span className='trn' data-trn-key='label_added'>added</span> (+1 ~ +3)</th>
						            <th>M.Def <span className='trn' data-trn-key='label_added'>added</span> (+4 ++)</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						                <span className='trn' data-trn-key='label_rings'>Rings</span><br />
						                <span className='trn' data-trn-key='label_earrings'>Earrings</span><br />
						                <span className='trn' data-trn-key='label_necklaces'>Necklaces</span>
						            </td>
						            <td>+3</td>
						            <td>+7</td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_listenchants'>List of Enchant Scrolls</h3>

					<ul className='trn' data-trn-key='label_itemenchanting_10'>
						<li>
							Common Enchant Scrolls Allow enchanting of items. On a failed enchanting attempt, the item is crystallized. <strong>Safe enchanting to +4.</strong>
						</li>
					</ul>
					<div className='table-scrolling'>
						<table>
							<thead>
								<tr>
									<th>Item</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_armor_i01.png' alt='Scroll: Enchant D-grade Armor (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant D-grade Armor</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i01.png' alt='Scroll: Enchant D-grade Weapon (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant D-grade Weapon</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_armor_i02.png' alt='Scroll: Enchant C-grade Armor (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant C-grade Armor</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i02.png' alt='Scroll: Enchant C-grade Weapon (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant C-grade Weapon</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_armor_i03.png' alt='Scroll: Enchant B-grade Armor (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant B-grade Armor</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i03.png' alt='Scroll: Enchant B-grade Weapon (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant B-grade Weapon</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_armor_i04.png' alt='Scroll: Enchant A-grade Armor (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant A-grade Armor</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i04.png' alt='Scroll: Enchant A-grade Weapon (Exchangeable)' />
										</span>
										<span>
											<strong>Scroll: Enchant A-grade Weapon</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<ul className='trn' data-trn-key='label_itemenchanting_11'>
						<li>
							<strong>Improved Enchant Scrolls</strong>
							<ul>
								<li>Improved Enchant scrolls for weapon and armor allow to make a random enchanting. On a failed enchanting attempt, the item is crystallized.</li>
								<li><strong>If the enchant value is +5 or lower, the enchanting is random from +1 to +2.</strong></li>
								<li><strong>If the enchant value is +6 or higher, the enchanting is +1.</strong></li>
							</ul>
						</li>
					</ul>
					<div className='table-scrolling'>
						<table>
							<thead>
								<tr>
									<th>Item</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_blessed_scrl_of_ench_am_a_i04.png' alt='Improved Scroll: Enchant A-grade Armor (Exchangeable)' />
										</span>
										<span>
											<strong>Improved Scroll: Enchant A-grade Armor</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_blessed_scrl_of_ench_wp_a_i04.png' alt='Improved Scroll: Enchant A-grade Weapon (Exchangeable)' />
										</span>
										<span>
											<strong>Improved Scroll: Enchant A-grade Weapon</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-cursed'>
											<img src='./Icon/Texture/etc_blessed_scrl_of_ench_am_a_i04.png' alt='Improved Scroll: Enchant A-grade Armor (Exchangeable)' />
										</span>
										<span>
											<strong>Improved Scroll: Enchant A-grade Armor</strong>
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-cursed'>
											<img src='./Icon/Texture/etc_blessed_scrl_of_ench_wp_a_i04.png' alt='Improved Scroll: Enchant A-grade Weapon (Exchangeable)' />
										</span>
										<span>
											<strong>Improved Scroll: Enchant A-grade Weapon</strong>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<ul className='trn' data-trn-key='label_itemenchanting_12'>
						<li>
							<strong>Cursed Enchant Scrolls</strong>
							<ul>
								<li>When used, enchant value -1. <strong>Only for items enchanted to +2 ++.</strong></li>
							</ul>
						</li>
					</ul>
					<div className='table-scrolling'>
						<table>
							<thead>
								<tr>
									<th>Item</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_cursed_scrl_of_ench_am_a_i00.png' alt='Cursed Scroll: Enchant A-grade Armor (Exchangeable)' />
										</span>
										<span>
											<strong>Cursed Scroll: Enchant A-grade Armor</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image'>
											<img src='./Icon/Texture/etc_cursed_scrl_of_ench_wp_a_i00.png' alt='Cursed Scroll: Enchant A-grade Weapon' />
										</span>
										<span>
											<strong>Cursed Scroll: Enchant A-grade Weapon</strong><br/>
											(Exchangeable)
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-cursed'>
											<img src='./Icon/Texture/etc_cursed_scrl_of_ench_am_a_i00.png' alt='Cursed Scroll: Enchant A-grade Armor' />
										</span>
										<span>
											<strong>Cursed Scroll: Enchant A-grade Armor</strong>
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-cursed'>
											<img src='./Icon/Texture/etc_cursed_scrl_of_ench_wp_a_i00.png' alt='Cursed Scroll: Enchant A-grade Weapon (Exchangeable)' />
										</span>
										<span>
											<strong>Cursed Scroll: Enchant A-grade Weapon</strong>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>		
	)
}

export default ItemEnchanting