import React from 'react'

const Privacity = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h2 className='internal-title trn' data-trn-key='privacity'>
					Privacity Policy
				</h2>
				<div className='box-wrapper'>
					<div className='box-content wiki-content'>
					    <h4>Общие положения.</h4>
					    <p>Настоящая Политика является официальным документом интернет–ресурса L2Abyss сайта https://l2abyss.com, и определяет порядок обработки и защиты информации о Пользователях.</p>
					    <p>Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях Сайта, регулируются настоящей Политикой, Пользовательским соглашением, иными официальными документами Администрации Сайта и действующим законодательством Украины.</p>
					    <p>Регистрируясь на Сайте и используя его, Пользователь выражает свое согласие с условиями, изложенными в настоящей Политике. В случае несогласия Пользователя с настоящей Политикой использование Сайта и его сервисов должно быть немедленно прекращено.</p>
					    <p>Администрация Сайта не проверяет достоверность получаемой (собираемой) информации о Пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения Администрацией Сайта обязательств перед Пользователем.
						</p>
						<p>
						</p>
					    <h4>Условия и цели обработки персональных данных.</h4>
						<p>Администрация Сайта не требует предоставление персональных данных, но в случаях добровольного предоставления их Пользователем, может хранить только те персональные данные, которые необходимы для предоставления обратной связи с Пользователем.
						</p>
					    <p>Персональную информацию Пользователя Администрация Сайта может использовать в следующих целях:</p>
					    <ul>
					        <li>Идентификация стороны в рамках соглашений и договоров с Администрацией Сайта;</li>
					        <li>Связь с пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Сайтом, а также обработка запросов и заявок от Пользователя;</li>
					        <li>Улучшение качества сервисов на Сайте, удобства Сайта, разработка новых функций Сайта;</li>
					        <li>Проведение статистических и иных исследований, на основе обезличенных данных.</li>
					    </ul>
						<p>Администрация Сайта хранит персональную информацию Пользователей в соответствии с внутренними регламентами.
						</p>
						<p>В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления пользователем информации о себе для общего доступа неограниченному кругу лиц.
						</p>
						<p>Администрация Сайта вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:
						</p>
						<ul>
					        <li>Пользователь выразил свое согласие на такие действия;</li>
					        <li>Передача необходима в рамках использования Пользователем Сайтом;</li>
					        <li>Передача предусмотрена украинским или иным применимым законодательством в рамках установленной законодательством процедуры;</li>
					        <li>Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;</li>
							<li>В целях обеспечения возможности защиты прав и законных интересов Администрации Сайта или третьих лиц в случаях, когда Пользователь нарушает Пользовательское соглашение.</li>
					    </ul>
						<p>При обработке персональных данных Пользователей Администрация Сайта руководствуется Законом Украины «О защите персональных данных».
						</p>
					    <h4>Порядок ввода в действие и изменения Политики.</h4>
					    <p>Политика вступает в силу с момента публикации на Сайте и действует бессрочно, до замены его новой Политикой.</p>
					    <p>
					        Действующая редакция Политики, являющаяся публичным документом.
					    </p>
						<p>Администрация Сайта вправе вносить изменения в Политику. При внесении изменений Администрация Сайта уведомляет об этом пользователей путем размещения новой редакции на Сайте по постоянному адресу.
						</p>
						<p>Продолжение пользования Сайтом Пользователем после размещения Политики в новой редакции либо размещения Политики в новой редакции признается Сторонами принятием условий Политики Пользователем в полном объеме.
						</p>
					    <h4>Состав персональных данных.</h4>
					    <p>Персональные данные Пользователей включают в себя предоставляемые Пользователями и минимально необходимые для регистрации на Сайте.</p>
					    <h4>Учредитель:</h4>
					    <p>Natural Poland LTD 
					        
					    </p>
					    
					    <p>Poland, Lodz, Stolarska str., N13, Floor 1,</p>
					    <p>
					        E-mail: l2abyss.com@gmail.com
					    </p>
					    <h4>Контакты:</h4>
					    <p>Discord: L2Abyss#8322 
					        
					    </p>
					    
					    <p>Skype: Marsian</p>
					    <p>
					        E-mail: l2abyss.com@gmail.com
					    </p>					    
					</div>
				</div>
			</div>
		</div>		
	)
}

export default Privacity