import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const OtherItems = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h2 className='internal-title'>
					Essence Wiki
				</h2>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a className='trn' data-trn-key='wiki_otheritems'>Other Items</a>
					</div>
					<div className='box-content'>
						<div className='wiki-main-header fluid'>
							<div>
								<h2 style={{ maxWidth: 350 }} className='wiki-title trn' data-trn-key='wiki_otheritems'>Other Items</h2>
								<div data-item='weapons' className='wiki-filter'>
									<div className='select'>
										<div className='item-selected trn' data-trn-key='label_selectitem'>
											Select item
										</div>
										<ul className='wiki-filter-list'>
							                <li>
							                	<NavLink activeClassName='active-submenu' to='/agathions' data-trn-key='label_agathions'>Agathions</NavLink>
							                </li>
							                <li>
							                	<NavLink activeClassName='active-submenu' to='/belts' className='trn' data-trn-key='label_belts'>Belts</NavLink>
							                </li>
							                <li>
							                	<NavLink activeClassName='active-submenu' to='/bracelets' className='trn' data-trn-key='label_bracelets'>Bracelets</NavLink>
							                </li>
							                <li>
							                	<NavLink activeClassName='active-submenu' to='/brooches' className='trn' data-trn-key='label_brooches'>Brooches</NavLink>
							                </li>
							                <li>
							                	<NavLink activeClassName='active-submenu' to='/cloaks' className='trn' data-trn-key='label_cloaks'>Cloaks</NavLink>
							                </li>
							                <li>
							                	<NavLink activeClassName='active-submenu' to='/dolls' className='trn' data-trn-key='label_dolls'>Dolls</NavLink>
							                </li>
							            </ul>
									</div>
								</div>
								<p className='wiki-paragraph big trn' data-trn-key='wiki_otheritems_desc'>
									In Lineage 2 Essence there is a complex system of items that make your character stronger and stronger. These items go beyond basic equipment, they are 'luxury' items, each type helps with different status and passive and active skills, you can find out all information about items by selecting one of the options above.
								</p>
							</div>
							<div>
								<img src='/assets/img/wiki/img_wiki_otheritems.png' alt='' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>		
	)
}

export default OtherItems