import React from 'react'
import { NavLink } from 'react-router-dom'
import { TwitchEmbed, TwitchChat, TwitchClip, TwitchPlayer } from 'react-twitch-embed'

const Streamers = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h2 className='internal-title' data-trn-key=''>
					Streamers
				</h2>
				<div className='box-wrapper'>
					<div className='streams'>
							<TwitchPlayer
								channel="instaframe"
								id="stream01"
								theme="dark"
								width="33%"
								height={190}
								withChat={false}
								muted
								onVideoPause={() => console.log(':(')}
							/>			
					</div>
				</div>
			</div>
		</div>	
	)
}

export default Streamers