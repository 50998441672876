import React from 'react'

const Home = props => {
	return (
		<div id='homepage'>

			<div className='modal'>
				<a target='_blank' href='https://discord.gg/m3BqnWwFPy'><img src='../assets/img/popup15.gif' /></a>
			</div>
		
			<div className='social hide-smartphone'>
				<a target='_blank' href='https://t.me/AbyssMarsCommunity'>
					<svg fill="#000000" width="24px" height="800px" viewBox="0 0 22 22" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><title>Telegram Glyph</title><path d="M11.99432,2a10,10,0,1,0,10,10A9.99917,9.99917,0,0,0,11.99432,2Zm3.17951,15.15247a.70547.70547,0,0,1-1.002.3515l-2.71467-2.10938L9.71484,17.002a.29969.29969,0,0,1-.285.03894l.334-2.98846.01069.00848.00683-.059s4.885-4.44751,5.084-4.637c.20147-.189.135-.23.135-.23.01147-.23053-.36152,0-.36152,0L8.16632,13.299l-2.69549-.918s-.414-.1485-.453-.475c-.041-.324.46649-.5.46649-.5l10.717-4.25751s.881-.39252.881.25751Z"/></svg>
				</a>
				<a target='_blank' href='https://www.facebook.com/L2AbyssCOM'>
					<svg xmlns='http://www.w3.org/2000/svg' width='18.844' height='18.844' viewBox='0 0 18.844 18.844'>
					  <path d='M107.815,821.344H91.04A1.04,1.04,0,0,0,90,822.385V839.16a1.041,1.041,0,0,0,1.041,1.04h9.032v-7.292H97.624v-2.854h2.449v-2.1a3.428,3.428,0,0,1,3.659-3.762,19.968,19.968,0,0,1,2.2.113v2.546h-1.5a1.181,1.181,0,0,0-1.411,1.386v1.818h2.826l-0.368,2.854h-2.458V840.2h4.8a1.041,1.041,0,0,0,1.041-1.041h0V822.384A1.04,1.04,0,0,0,107.815,821.344Z' transform='translate(-90 -821.344)'/>
					</svg>
				</a>
				<a target='_blank' href='https://discord.gg/m3BqnWwFPy'>
					<svg xmlns='http://www.w3.org/2000/svg' width='21' height='25' viewBox='0 0 21 24'>
					  <path d='M2136.92,1601.78a1.306,1.306,0,0,0-.47.09,1.256,1.256,0,0,0-.4.28,1.425,1.425,0,0,0-.25.43,1.191,1.191,0,0,0-.1.51,1.4,1.4,0,0,0,.17.68,1.161,1.161,0,0,0,.44.49,1.048,1.048,0,0,0,.61.19,1.118,1.118,0,0,0,.87-0.4,1.4,1.4,0,0,0,.35-0.96,1.368,1.368,0,0,0-.35-0.94,1.2,1.2,0,0,0-.87-0.37h0Zm-5.58,1.31a1.4,1.4,0,0,0,.35.96,1.146,1.146,0,0,0,1.74,0,1.4,1.4,0,0,0,.35-0.96,1.368,1.368,0,0,0-.35-0.94,1.208,1.208,0,0,0-1.74,0A1.368,1.368,0,0,0,2131.34,1603.09Zm13.15-10.66a2.308,2.308,0,0,0-1.71-.73h-16.13a2.34,2.34,0,0,0-1.71.73,2.4,2.4,0,0,0-.72,1.76v16.21a2.4,2.4,0,0,0,.72,1.76,2.34,2.34,0,0,0,1.71.73h13.65l-0.66-2.25c1.91,1.78,3.76,3.47,5.58,5.06v-21.51A2.408,2.408,0,0,0,2144.49,1592.43Zm-2.83,13.29a1.85,1.85,0,0,1-.4.44,7.276,7.276,0,0,1-.71.52,4.542,4.542,0,0,1-1.03.47,4.341,4.341,0,0,1-1.38.21l-0.8-.94a3.681,3.681,0,0,0,2.16-1.45,5.327,5.327,0,0,1-1.36.7,8.7,8.7,0,0,1-7.5-.42,2.7,2.7,0,0,1-.31-0.17,2.116,2.116,0,0,0-.21-0.11l0.19,0.23a2.544,2.544,0,0,0,.73.63,4.025,4.025,0,0,0,1.19.54,10.343,10.343,0,0,1-.79.99,4.205,4.205,0,0,1-2.11-.52,5.442,5.442,0,0,1-1.2-.79,1.753,1.753,0,0,1-.35-0.52,13.756,13.756,0,0,1,.49-3.54,18.319,18.319,0,0,1,.78-2.46c0.18-.41.34-0.73,0.46-0.98a5.727,5.727,0,0,1,3.38-1.27l0.09,0.14a8.085,8.085,0,0,0-3.14,1.59l0.71-.37a10.278,10.278,0,0,1,4.45-.84,9.349,9.349,0,0,1,4.5,1.21l-0.28-.23a6.126,6.126,0,0,0-1.03-.63,8.952,8.952,0,0,0-1.64-.68l0.14-.19h0.18a2.406,2.406,0,0,1,.52.07c0.22,0.05.46,0.11,0.73,0.19a7.1,7.1,0,0,1,.93.37,5.069,5.069,0,0,1,1.01.64l0.42,0.89a11.394,11.394,0,0,1,.89,2.64,14.418,14.418,0,0,1,.43,3.45h0Z' transform='translate(-2124.22 -1591.69)'/>
					</svg>
				</a>
				<a target='_blank' href='https://www.youtube.com/channel/UCWUJKLHxJA4FhI0SpvQtQKA'>
					<svg xmlns='http://www.w3.org/2000/svg' width='23.62' height='16.562' viewBox='0 0 23.62 16.562'>
					  <path d='M1803.33,576.811a2.959,2.959,0,0,0-2.08-2.085c-1.85-.506-9.25-0.506-9.25-0.506s-7.4,0-9.25.487a3.02,3.02,0,0,0-2.08,2.1,33.264,33.264,0,0,0,0,11.377,2.957,2.957,0,0,0,2.08,2.085c1.87,0.507,9.25.507,9.25,0.507s7.4,0,9.25-.488a2.956,2.956,0,0,0,2.08-2.084,31.043,31.043,0,0,0,.49-5.689,29.439,29.439,0,0,0-.49-5.708h0Zm-13.69,9.235v-7.092l6.16,3.546Zm0,0' transform='translate(-1780.19 -574.219)'/>
					</svg>
				</a>
			</div>
			<ul className='pagenav hide-smartphone'>
				<li>
					<a href='#gameplay'>
						<span className='trn' data-trn-key='pagenav_01'>Introducing</span>
					</a>
				</li>
				<li>
					<a href='#features'>
						<span className='trn' data-trn-key='pagenav_02'>Features</span>
					</a>
				</li>
				<li>
					<a href='#fastexp'>
						<span className='trn' data-trn-key='pagenav_03'>Quality of life</span>
					</a>
				</li>
				<li>
					<a href='#intensepvp'>
						<span className='trn' data-trn-key='pagenav_04'>Always fighting</span>
					</a>
				</li>
				<li>
					<a href='#promo'>
						<span className='trn' data-trn-key='pagenav_05'>Are you ready?</span>
					</a>
				</li>
				{/*<li>
					<a href='#preorder'>
						<span className='trn' data-trn-key='pagenav_06'>Pre Offers</span>
					</a>
				</li>*/}
			</ul>
			<section id='home'>
				<a href='#gameplay' className='navigate'>
					<img src='./assets/img/ic_arrow_down_alt.svg' alt='' />
				</a>
				<div className='bullets'>
					<div className='bullets-content'>
						<div className='item'>
							<strong className='trn' data-trn-key="home_bullet_01">Oficial Resources</strong>
							<span className='trn' data-trn-key="home_bullet_01_desc">All the features retail-like</span>
						</div>
						<div className='item'>
							<strong className='trn' data-trn-key="home_bullet_02">L-Coins Drop</strong>
							<span className='trn' data-trn-key="home_bullet_02_desc">Obtained by killing monsters</span>
						</div>
						<div className='item'>
							<strong className='trn' data-trn-key="home_bullet_03">No Pay 2 Win</strong>
							<span className='trn' data-trn-key="home_bullet_03_desc">Get all the items in-game</span>
						</div>
					</div>
				</div>
				<div className='fade'></div>
				<div className='wrapper'>
					<div className='hero'>
						<h1 className='wow fadeInUp trn' data-wow-delay='0.6s' data-trn-key='home_title'>First L2 Essence Project<br/><u>100% Free to Play!</u></h1>
						<p className='wow fadeInUp margin-small-bottom trn' data-wow-delay='0.7s' data-trn-key='home_subtitle'>All the official L2 Essence resources WITHOUT ANY DONATION FOR ITEMS,<br/> a whole legendary game redesigned for a faster and self-sustained gameplay.</p>
						<a href='https://lk.l2abyss.com/account/register' className='button trn' data-trn-key='signup'>Register</a>
					</div>
				</div>
				<div className='video-content'>
					<video autoPlay muted loop src='./assets/main8.mp4'></video>
				</div>
			</section>
			<div className='headerfix' id='gameplay'>
				<section>
					<div className='wrapper small'>
						<div className='col-6'>
							<span className='section-label wow fadeInUp trn' data-trn-key='home_introducing_label' data-wow-delay='0.2s'>
								Introducing a new era
							</span>
							<h2 className='section-title icon wow fadeInUp trn' data-trn-key='home_introducing_title' data-wow-delay='0.3s'>
								Your time in what really matters
							</h2>
							<p className='section-paragraph wow fadeInUp trn' data-trn-key='home_introducing_content' data-wow-delay='0.4s'>
								Essence is an ideal version for playing solo: each class is self-dependent and auto-hunting makes leveling up easy breezy for you. But in order not to miss a huge amount of interesting content, it’s better to join a clan or create your own. Fight with truly dangerous enemies that can only be defeated together with a team of allies. Make your clan the strongest on the server!
							</p>
							<div className='compare wow fadeIn' data-wow-delay='0.5s'>
								<b className='trn' data-trn-key='home_introducing_compare'>See the comparision between</b> <span>Old Lineage 2 Classic</span> / <span>Lineage 2 Essence</span>
							</div>
						</div>
						<div className='col-6 text-center diagram-row'>
							<div className='diagram-box wow zoomIn' data-wow-delay='0.7s'>
								<div className='diagram-icon'>
									<span>PVP</span>
									<i><img src='./assets/img/ic_pvp.svg' alt='' /></i>
								</div>
								<div className='diagram-icon'>
									<i><img src='./assets/img/ic_craft.svg' alt='' /></i>
									<span className='trn' data-trn-key='home_introducing_graphic02'>Craft items speed</span>
								</div>
								<div className='diagram-icon'>
									<i><img src='./assets/img/ic_pve.svg' alt='' /></i>
									<span>PVE</span>
								</div>
								<div className='diagram-icon'>
									<i><img src='./assets/img/ic_lvlup.svg' alt='' /></i>
									<span className='trn' data-trn-key='home_introducing_graphic04'>Leveling up speed</span>
								</div>

								<div className='diagram-content content-01'>
									<svg xmlns='http://www.w3.org/2000/svg' width='190.25' height='172' viewBox='0 0 190.25 172'>
									  <path className='cls-1' d='M979.749,1104L1074.5,999.248,1167.25,1104l-92.75,64.75Z' transform='translate(-978.75 -998)'/>
									  <path id='Ellipse_7_copy_3' data-name='Ellipse 7 copy 3' className='cls-2' d='M1166,1101a3,3,0,1,1-3,3A3,3,0,0,1,1166,1101Zm-184-1a3,3,0,1,1-3,3A3,3,0,0,1,982,1100Zm92-102a3,3,0,1,1-3,3A3,3,0,0,1,1074,998Zm1,166a3,3,0,1,1-3,3A3,3,0,0,1,1075,1164Z' transform='translate(-978.75 -998)'/>
									</svg>
								</div>
								<div className='diagram-content content-02'>
									<img src='./assets/img/img_diagram_02.svg' alt='' />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>	
			<div className='headerfix' id='features'>
				<section>
					<div className='wrapper'>
						<div className='col-7 features-image hide-smartphone'>
						    <img rel='1' src='./assets/img/img_content_05.png' className='wow zoomIn active' />
							<img rel='2' src='./assets/img/img_content_00.png' />
							<img rel='3' src='./assets/img/img_content_01.png' />
							<img rel='4' src='./assets/img/img_content_02.png' />
							<img rel='5' src='./assets/img/img_content_03.png' />
							<img rel='6' src='./assets/img/img_content_04.png' />
							<img rel='7' src='./assets/img/img_content_06.png' />
						</div>
						<div className='col-5 features-content'>
							<span className='section-label trn' data-trn-key='home_features_label'>
								Essence Features
							</span>

							<div rel='1' className='wow fadeInUp active'>
								<h2 className='section-title icon'>Vanguard</h2>
								<p className='section-paragraph trn' data-trn-key='home_features_content_05'>
									The Vanguard is a spear-wielding Warrior-type class, which mounts upon his beast to charge into battle. This class is restricted specifically to males of the Orc race and wears Heavy Armor.
								</p>
								<div className='section-video' video-data='jDlVKPP1fq4'>
									<img className='section-video-bg' src='./assets/img/img_content_video_05.jpg' alt='' />
								</div>
							</div>
							
							<div rel='2' className='wow fadeInUp active'>
								<h2 className='section-title icon'>Sylph</h2>
								<p className='section-paragraph trn' data-trn-key='home_features_content_00'>
									Sylphs are an independent tribe of the Ertheia people. The God of Wind Sayha breathed life into them, and they became his followers. Behind the angelic appearance of the Sylphs lies the power of ancient dragons and the sheer force of the wind element.  
								</p>
								<div className='section-video' video-data='jDlVKPP1fq4'>
									<img className='section-video-bg' src='./assets/img/img_content_video_00.jpg' alt='' />
								</div>
							</div>

							<div rel='3' className='wow fadeInUp'>
								<h2 className='section-title icon'>Death Knights</h2>
								<p className='section-paragraph trn' data-trn-key='home_features_content_01'>
									The only exclusive class of Lineage 2 Essence, The Death Knight, excels in bringing the meaning of the word 'death' to a whole new level. Against one or multiple foes, his skills will make sure everyone will be dead soon. 
								</p>
								<div className='section-video' video-data='jDlVKPP1fq4'>
									<img className='section-video-bg' src='./assets/img/img_content_video_01.jpg' alt='' />
								</div>
							</div>

							<div rel='4'>
								<h2 className='section-title icon'></h2>
								<p className='section-paragraph trn' data-trn-key='home_features_content_02'>
									Are you feeling lucky? Because the Random craft system will reward the luckiest players, with AMAZING prizes such as full items, consumables, and much more! Make sure you don't spend all your luck. 
								</p>
								<div className='section-video'>
									<img className='section-video-bg' src='./assets/img/img_content_video_02.jpg' alt='' />
								</div>
							</div>

							<div rel='5'>
								<h2 className='section-title icon'></h2>
								<p className='section-paragraph trn' data-trn-key='home_features_content_03'>
									'I don't like playing support.' Think again! On Essence, since every class has it's own buff set, there is no such thing as a Support. Every class deals damage and has its strengths and weaknesses.
								</p>
								<div className='section-video' video-data='7m5_JUSB-qU'>
									<img className='section-video-bg' src='./assets/img/img_content_video_03.jpg' alt='' />
								</div>
							</div>

							<div rel='6'>
								<h2 className='section-title icon'></h2>
								<p className='section-paragraph trn' data-trn-key='home_features_content_04'>
									The journey on Essence can be daunting, but with a companion, it's a lot easier! You can have various types of pets to help you on PvE and PvP. Every single pet has it's perks and strengths, and yeah, it does damage.
								</p>
								<div className='section-video' video-data='iY0UJLrathA'>
									<img className='section-video-bg' src='./assets/img/img_content_video_04.jpg' alt='' />
								</div>
							</div>

							<div rel='7' className='wow fadeInUp'>
								<h2 className='section-title icon'>Valakas Temple</h2>
								<p className='section-paragraph trn' data-trn-key='home_features_content_06'>
									The Flaming Dragon is waiting for the bravest warriors in his temple. This weekly raid requires a team of at least 15 heroes! For killing the final boss, you can get Lenterns with experience, Ancient Adena, Warious Equipment and Spellboks.
								</p>
								<div className='section-video' video-data='jDlVKPP1fq4'>
									<img className='section-video-bg' src='./assets/img/img_content_video_06.jpg' alt='' />
								</div>
							</div>
						</div>
						<div className='features-nav'>
							<div rel='1' className='active'>
								Vanguard
							</div>
							<div rel='2'>
								Sylph
							</div>
							<div rel='3'>
								Death Knights
							</div>
							<div rel='4'>
								Random Craft
							</div>
							<div rel='5'>
								Classes Remake
							</div>
							<div rel='6'>
								Essence Pets
							</div>
							<div rel='7'>
								Valakas Temple
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className='headerfix' id='fastexp'>
				<section >
					<div className='wrapper small'>
						<div className='col-4'>
							<span className='section-label wow fadeInUp trn' data-trn-key='home_quality_label' data-wow-delay='0.2s'>
								Quality of Life
							</span>
							<h2 className='section-title icon wow fadeInUp trn' data-trn-key='home_quality_title' data-wow-delay='0.3s'>
								Fast exp & solo players friendly
							</h2>
							<p className='section-paragraph wow fadeInUp trn' data-trn-key='home_quality_content' data-wow-delay='0.4s'>
								Your character will have everything it needs to level on its own, including gear that will evolve with you. There’s no need for an extra box for buffs or share any experience with alts. It's just leveling, even when you are sleeping! 
							</p>
						</div>
						<div className='col-1'></div>
						<div className='col-7'>
							<div className='section-video' video-data='3RNIvKO7LJ8'>
								<img className='section-video-image wow fadeInRight hide-smartphone' data-wow-delay='0.6s' src='./assets/img/img_fast_leveling.png' alt='' />
								<img className='section-video-bg' src='./assets/img/bg_fast_leveling.jpg' alt='' />
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className='headerfix' id='intensepvp'>
				<section>
					<div className='wrapper small'>
						<div className='col-4 float-right'>
							<span className='section-label wow fadeInUp trn' data-trn-key='home_fighting_label' data-wow-delay='0.2s'>
								Always fighting
							</span>
							<h2 className='section-title icon wow fadeInUp trn' data-trn-key='home_fighting_title' data-wow-delay='0.3s'>
								Intense PVP Fights and Epic Battles
							</h2>
							<p className='section-paragraph wow fadeInUp trn' data-trn-key='home_fighting_content' data-wow-delay='0.4s'>
								On Essence, everything revolves around PvP. You will fight for spots, for bosses, for epics, for castles, for items, for Adena, for glory, for honor, for your clan, for yourself... you got it.
							</p>
						</div>
						<div className='col-1 float-right'></div>
						<div className='col-7 float-left'>
							<div className='section-video'>
								<img className='section-video-image wow fadeInLeft' data-wow-delay='0.6s' src='./assets/img/img_intense_pvp.png' alt='' />
								<img className='section-video-bg' src='./assets/img/bg_intense_pvp.jpg' alt='' />
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className='headerfix' id='promo'>
				<section>
					<div className='wrapper small'>
						<div className='col-2'></div>
						<div className='col-8'>
							<h2 className='section-title icon-top text-center wow fadeInUp trn' data-trn-key='home_joining_title' data-wow-delay='0.2s'>
								Are you ready for Joining?
							</h2>
							<p className='section-paragraph text-center wow fadeInUp trn' data-trn-key='home_joining_content' data-wow-delay='0.3s'>
								Essence is the version for every type of player. Casual, hardcore, solo, and groups. In this journey, you will be a part of anything you want, from farming strong monsters to intense and massive battles for several objectives. The only real question left is... Are you ready!?
							</p>
							<div className='section-video wow zoomIn promo-video' data-wow-delay='0.5s' video-data='XwP4tChGD1I'>
								<button className='play'></button>
								<img className='section-video-bg' src='./assets/img/bg_promovideo.jpg' alt='' />
							</div>
						</div>
					</div>
				</section>
			</div>	
			{/*<div className='headerfix' id='preorder'>
				<section>
					<div className='wrapper small'>
						<h2 className='section-title text-center trn' data-trn-key='home_offers_title'>
							Guaratee your pre-launch offers
						</h2>
						<div className='offers'>
							<div>
								<img className='pack-image wow zoomIn' data-wow-delay='0.2s' src='./assets/img/img_offer_02.png' alt='' />
								<h3 className='wow fadeInUp'>Growth Kit: L-Coins</h3>
								<p className='wow fadeIn' data-wow-delay='0.3s'>
									<img src='./Icon/Texture/npoint_antaras_30day_box.png' alt='' className='icon-image' />
									Double-click to obtain 3000 L-Coins and Reward Box Lv. 60.
								</p>
								<ul className='wow fadeIn' data-wow-delay='0.5s'>
									<li>
										<img src='./assets/img/ic_coin.svg' alt='' />
										<span>
											<strong>Reward Box Lv. 60 contains:</strong>
											+2 Talisman of Aden, 500 L-Coins, Reward Box Lv. 65.
										</span>
									</li>
									<li>
										<img src='./assets/img/ic_coin.svg' alt='' />
										<span>
											<strong>Reward Box Lv. 65 contains:</strong>
											+2 Cloak of Protection, 500 L-Coins, Reward Box Lv. 70.
										</span>
									</li>
									<li>
										<img src='./assets/img/ic_coin.svg' alt='' />
										<span>
											<strong>Reward Box Lv. 70 contains:</strong>
											+2 Dragon Belt, 1000 L-Coins.
										</span>
									</li>
								</ul>
								<a className='button wow fadeInDown trn button-panel-account' data-trn-key='home_offers_button' data-wow-delay='0.4s'>Get offer now!</a>
							</div>
						</div>
					</div>
				</section>
			</div>	*/}
			<div className='timeline-shape'>
				<span></span>
			</div>
		</div>
	)
}

export default Home
