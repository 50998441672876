import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Items = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h2 className='internal-title'>
					Essence Wiki
				</h2>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a className='trn' data-trn-key='wiki_items'>Items</a>
					</div>
					<div className='box-content'>
						<div className='wiki-main-header fluid'>
							<div>
								<h2 style={{ maxWidth: 350 }} className='wiki-title trn' data-trn-key='wiki_items'>Items</h2>
								<div data-item='weapons' className='wiki-filter'>
									<div className='select'>
										<div className='item-selected trn' data-trn-key='label_selectitem'>
											Select item
										</div>
										<ul className='wiki-filter-list'>
											<li>
												<span href='./wiki-items-weapons.php' className='trn' data-trn-key='label_weapons'>Weapons</span>
											</li>
											<li>
												<span href='./wiki-items-armors.php' className='trn' data-trn-key='label_armors'>Armors</span>
											</li>
											<li>
												<span href='./wiki-items-accessories.php' className='trn' data-trn-key='label_accessories'>Accessories</span>
											</li>
											<li>
												<span href='#' className='trn' data-trn-key='label_otheritems'>Other Items</span>
											</li>
							                <li className='border-top'>
							                	<NavLink activeClassName='active-submenu' to='/itemenchanting' className='trn' data-trn-key='label_itemenchanting'>Item Enchanting</NavLink>
							                </li>
							                <li>
							                	<NavLink activeClassName='active-submenu' to='/itemblessing' className='trn' data-trn-key='label_itemblessing'>Item Blessing</NavLink>
							                </li>
										</ul>
									</div>
								</div>
								<p className='wiki-paragraph big trn' data-trn-key='wiki_items_desc'>
									In the Lineage 2 Essence there is a complex items system that make your character stronger and stronger. Each type of item helps you with a different status, from weapons and armor to cloaks and brooches. There are also systems for improving and enchanting equipments, you can find out all information about items by selecting one of the options above.
								</p>
							</div>
							<div>
								<img src='/assets/img/wiki/img_wiki_items.png' alt='' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>		
	)
}

export default Items