import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Pets = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a>Pets</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title'>Pets</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='../assets/img/wiki/pets_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_pets_01'>
						    <li>Pets are various creatures that help your character in battle, can use special items and skills.</li>
						    <li>There are 6 types of pets: Wolf, Buffalo, Tiger, Kookaburra, Dragon and Hawk, each with its unique skills.</li>
						    <li>In the pet management window you can find pet’s stats, its skills, equipment and Satiety gauge, see in detail: <a href='#summoning'>Summoning a pet</a>.</li>
						    <li>Pets can learn by getting XP while hunting with a character, and then, at a certain level, evolve, see in detail: <a href='#leveling'>Leveling up a pet</a>.</li>
						    <li>Pets can be summoned in hunting zones, but also in special places such as: the world server, on the Olympiad and in instance zones.</li>
						    <li>Pets, unlike Servitors, have to be fed, because if a pet is hungry, it will not listen to the character.</li>
						    <li>In order to acquire an item for summoning pets, you have to defeat the boss <a href='#'>Atingo</a>, and then his pet.</li>
						    <li>
						        Atingo randomly appears in following hunting zones:&nbsp;
						        <a title='Plains of the Lizardmen' href='#'>Plains of the Lizardmen</a>,&nbsp;
						        <a title='Dragon Valley' href='#'>Dragon Valley</a>,&nbsp;
						        <a title='Tower of Insolence' href='#'>Tower of Insolence</a>,&nbsp;
						        <a title='Orc Barracks' href='#'>Orc Barracks</a>.
						        <ul>
						            <li>Atingo summons one of his pets to help him, and you have to kill Atingo before killing the pet.</li>
						            <li>As a reward, player’s character receives a Sealed Necklace of an appropriate type (depending on the type of pet that Atingo had summoned).</li>
						            <li>
						                Other than pets, Atingo can also summon a <strong>Sin Eater</strong>, which doesn’t drop any
						                necklaces for summoning pets.
						            </li>
						        </ul>
						    </li>
						</ul>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Atingo's Pet</th>
							            <th>Received item for summoning</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>Kookaburra</td>         
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/kukaburo_necklace_i00.png' alt='Kookaburra Necklace' className='icon-image' />
											</span>
											<span>
												Kookaburra Necklace
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>Tiger</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_tooth_gragon.png' alt='Tiger Necklace' className='icon-image' />
											</span>
											<span>
												Tiger Necklace
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>Wolf</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_summon_wolf_r_02.png' alt='Wolf Necklace' className='icon-image' />
											</span>
											<span>
												Wolf Necklace
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>Buffalo</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/buffalo_necklace_i00.png' alt='Buffalo Necklace' className='icon-image' />
											</span>
											<span>
												Buffalo Necklace
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>Hawk</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/thunder_bird_necklace_i00.png' alt='Hawk Necklace' className='icon-image' />
											</span>
											<span>
												Hawk Necklace
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>Dragon</td>
							           <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/strider_necklace_i00.png' alt='Dragon Necklace' className='icon-image' />
											</span>
											<span>
												Dragon Necklace
											</span>
										</td>
							        </tr>
							    </tbody>
							</table>
						</div>

						<hr className='invisible' />
						<div id='summoning' className='anchor'></div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_summoningpet'>Summoning a pet</h3>
						<ul className='trn' data-trn-key='label_pets_02'>
						    <li>Each pet can be summoned using a special item, summon can be cancelled in the pet management window.</li>
						    <li>
						        There are some restrictions for summoning pets:
						        <ul>
						            <li>Only one pet can be summoned.</li>
						            <li>A pet cannot be summoned during combat.</li>
						            <li>If pet’s level is 20+ higher than the owner’s, it can’t be controlled.</li>
						            <li>When riding a Wyvern, a pet will disappear and won’t be available for summoning.</li>
						        </ul>
						    </li>
						    <li>When a pet dies, it doesn’t lose XP, but the owner receives a debuff, when the owner summons another pet, that debuff is moved to it.</li>
						    <li>When a pet that had died is summoned, its HP and MP are replenished.</li>
						</ul>

						<hr className='invisible' />
						<div id='leveling' className='anchor'></div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_levelingpet'>Leveling up a pet</h3>
						<ul className='trn' data-trn-key='label_pets_03'>
						    <li>Your pet gains XP and grows stronger while in combat.</li>
						    <li>When a pet is summoned, additional UI for Beast Spiritshots/Soulshots and a separate panel for pet potions appear. There you can tweak settings for using potions.</li>
						    <li>
						        Pets have to be fed: when a pet is summoned, its Satiety gauge is gradually depleting, and during combat it depletes even quicker.
						        <ul>
						            <li>If pet’s Satiety is below 50%, penalties are applied.</li>
						            <li>A pet can’t be summoned if its Satiety is below 1%.</li>
						            <li>If pet’s Satiety reaches 0, it will stop fighting and will become uncontrollable.</li>
						            <li>
						                Pet`s Satiety can be restored by using&nbsp;
						                <img src='./Icon/Texture/g_studentenfutter_germany.png' alt='Pet Food' className='icon-image' /> <strong>Pet Food</strong>
						                (can be used automatically).
						            </li>
						        </ul>
						    </li>
						    <li>
						        XP for killing monsters, received by pet’s owner, is converted from the amount of effort put in and shared with a pet.
						        <ul>
						            <li>If pet’s level is 11+ higher than monster’s, neither owner nor pet receive any XP or items.</li>
						        </ul>
						    </li>
						    <li>
						        Buff skill used by an owner will partially apply to a pet.
						        <ul>
						            <li>Not all buffs apply to pets.</li>
						            <li>Increased received XP effects from an owner’s items and buffs do apply to a pet.</li>
						        </ul>
						    </li>
						    <li>
						        Sayha's Grace effect is also applied to a pet.
						        <ul>
						            <li>If pet’s owner’s Sayha's Grace is off, the pet also receives a penalty for that.</li>
						            <li>When pet’s owner receives XP, Sayha's Grace is depleting depending on the amount of XP received by pet’s owner.</li>
						            <li>When a pet receives XP, Sayha’s Grace is depleting depending on the amount of XP received by the pet.</li>
						        </ul>
						    </li>
						    <li>Pets have special effects that increase XP received by them: the buff received in the Bee Hive special zone, and also item effect: Pet XP Growth Scroll:</li>
						</ul>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Buff/item</th>
							            <th style={{ minWidth: 200 }}>Bonus</th>
							            <th className='trn' data-trn-key='label_howtoobtain'>How to obtain</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>Pet Growth Effect</td>
							            <td>P. Accuracy +30, M. Accuracy +30, Acquired XP +100%. Removed when your pet is dead or recalled.</td>
							            <td>Hunting with your pet in the Bee Hive hunting zone.</td>
							        </tr>
							        <tr>
							            <td>Pet XP Growth Scroll</td>
							            <td>Acquired Pet XP +30%. Applied Time: 20 min. Cooldown: 1 min. Effect is removed when your pet is dead or recalled.</td>
							            <td>From a chest: Tag's Supplies.</td>
							        </tr>
							    </tbody>
							</table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_petevolution'>Pet Evolution</h3>
						<p className='trn' data-trn-key='label_pets_04'>
							All pets can evolve, their initial Evolution level is 0 (1 star in the pet management window), and they can evolve two more times:
						</p>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Pet <span className='trn' data-trn-key='label_lv'>Lv.</span></th>
							            <th  className='trn' data-trn-key='label_evolution'>Evolution</th>
							            <th style={{ minWidth: 270 }}><span className='trn' data-trn-key='label_evolution'>Evolution</span> item</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td><span className='trn' data-trn-key='label_level'>Level</span> 40</td>
							            <td>1) <span className='trn' data-trn-key='label_evolution'>Evolution</span></td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_grankain_chain.png' alt='Regular Pet Training Guide' className='icon-image' />
											</span>
											<span>
												<strong>Regular Pet Training Guide</strong>
											</span>
										</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_level'>Level</span> 76</td>
							            <td>2) <span className='trn' data-trn-key='label_evolution'>Evolution</span></td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_grankain_protect.png' alt='Advanced Pet Training Guide' className='icon-image' />
											</span>
											<span>
												<strong>Advanced Pet Training Guide</strong>
											</span>
										</td>
							        </tr>
							    </tbody>
							</table>
						</div>
						<ul className='trn' data-trn-key='label_pets_05'>
						    <li>
						        Having reached the required levels, “Evolution” button will appear, which allows to evolve a pet to its next evolution stage.
						        <ul>
						            <li>
						                Evolution requires&nbsp;
						                <img src='./Icon/Texture/etc_grankain_chain.png' alt='Regular Pet Training Guide' className='icon-image' /> <strong>Regular Pet Training Guide</strong>
						                or&nbsp;
						                <img src='./Icon/Texture/etc_grankain_protect.png' alt='Advanced Pet Training Guide' className='icon-image' /> <strong>Advanced Pet Training Guide</strong>
						                in inventory.
						            </li>
						            <li>Before reaching 40th and 76th level, “Evolution” button is not available.</li>
						        </ul>
						    </li>
						    <li>Having undergone the 1st Evolution, pet receives a random name and a modifier, and randomly receives a passive skill that increases stats: one out of 6 stats is additionally increased by a random number from +1 to +3. After the 2nd Evolution, pet learns higher level skills.</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_itemspet'>Items for pets</h3>
						<ul className='trn' data-trn-key='label_pets_06'>
						    <li>Pet can be equipped with equipment for characters: weapon, helmet, upper armor, lower armor, gloves, boots, necklaces, ring and earrings (max of 2 rings and earrings equipped at the same time).</li>
						    <li>Pets can’t be equipped with: augmented weapons, equipment with changing stats, event items, time-limited equipment, equipment received for finishing tutorials.</li>
						    <li>When equipment is on a pet, this equipment’s active skills can’t be used.</li>
						    <li>During combat, pet executes a standard melee attack, no matter what type of weapon is equipped.</li>
						    <li>Stats P. Accuracy, Atk. Spd., P. Critical Rate are fixed no matter what type of weapon is equipped.</li>
						    <li>Different weapons are appropriate for different pets:</li>
						</ul>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Pet</th>
							            <th  className='trn' data-trn-key='label_weapons'>Weapon</th>
							            <th>
							                Buffs<br />
							                (if a proper weapon is equipped)
							            </th>
							            <th>
							                Debuffs<br />
							                (if a proper weapon is not equipped)
							            </th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>Kookaburra</td>
							            <td><span className='trn' data-trn-key='label_rapiers'>Rapier</span>/<span className='trn' data-trn-key='label_blunts'>Blunt</span></td>
							            <td>
							                Rapier: M. Atk. +10%/+20%/+30%/+35%/+40%<br />
							                Blunt: M. Atk. +10%/+20%/+30%/+35%/+40%
							            </td>
							            <td>&nbsp;Atk. Spd. / Casting Spd. -10%</td>
							        </tr>
							        <tr>
							            <td>Tiger</td>
							            <td><span className='trn' data-trn-key='label_daggers'>Dagger</span></td>
							            <td>Dagger: P. Atk. +5%/+7%/+10%/+15%/+20%, vital point attack rate +10%/+15%/+20%/+25%/+30%</td>
							            <td>Atk. Spd. / Casting Spd. -10%</td>
							        </tr>
							        <tr>
							            <td>Buffalo</td>
							            <td><span className='trn' data-trn-key='label_blunts'>Blunt</span>/<span className='trn' data-trn-key='label_polearms'>Polearms</span></td>
							            <td>
							                Blunt Weapon: P. Atk. +10%/+15%/+20%/+25%/+30%, Atk. Spd. +10%<br />
							                Spear: P. Atk. +10%/+15%/+20%/+25%/+30%
							            </td>
							            <td>Atk. Spd. / Casting Spd. -10%</td>
							        </tr>
							        <tr>
							            <td>Hawk</td>
							            <td><span className='trn' data-trn-key='label_bows'>Bow</span></td>
							            <td>Bow: P. Atk. +5%/+7%/+10%/+12%/+15%, P. Accuracy +2/+3/+4/+4/+5</td>
							            <td>Atk. Spd. / Casting Spd. -10%</td>
							        </tr>
							        <tr>
							            <td>Dragon</td>
							            <td><span className='trn' data-trn-key='label_swords'>Sword</span>/<span className='trn' data-trn-key='label_ancientswords'>Ancient Sword</span></td>
							            <td>
							                Sword: P. Atk. +10%/+15%/+20%/+22%/+25%, Atk. Spd. +10%<br />
							                Ancient Sword: P. Atk. +10%/+15%/+20%/+22%/+25%, P. Critical Rate +10/+15/+20/+20/+20
							            </td>
							            <td>&nbsp;Atk. Spd. / Casting Spd. -10%</td>
							        </tr>
							        <tr>
							            <td>Wolf</td>
							            <td><span className='trn' data-trn-key='label_dualswords'>Dual Swords</span>/<span className='trn' data-trn-key='label_fistweapon'>Fist Weapon</span></td>
							            <td>
							                Dual Swords: P. Atk. +10%/+15%/+20%/ +25%/+30%, Atk. Spd. +10%<br />
							                Fist weapon: P. Atk. +10%/+15%/+20%/+25%/+30%, P. Critical Rate +10/+15/+20/+20/+20
							            </td>
							            <td>Atk. Spd. / Casting Spd. -10%</td>
							        </tr>
							    </tbody>
							</table>
						</div>
						<p className='trn' data-trn-key='label_pets_07'>
							Pets can use Beast Spiritshots/Soulshots and special potions, which can be bought from NPCs:
						</p>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th style={{ minWidth: 180 }}>Item</th>
							            <th style={{ minWidth: 230 }}  className='trn' data-trn-key='label_price'>Price</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_beast_spirit_shot_i00.png' alt='Beast Spiritshot' className='icon-image' />
											</span>
											<span>
												<strong>Beast Spiritshot</strong>
											</span>
										</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' className='icon-image' />
											</span>
											<span>
												55 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span>
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_beast_soul_shot_i00.png' alt='Beast Soulshot' className='icon-image' />
											</span>
											<span>
												<strong>Beast Soulshot</strong>
											</span>
										</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' className='icon-image' />
											</span>
											<span>
												22 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span>
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_instant_charisma_potion_i00.png' alt='Pet`s HP Potion' className='icon-image' />
											</span>
											<span>
												<strong>Pet`s HP Potion</strong><br/>(Exchangeable)
											</span>
										</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' className='icon-image' />
											</span>
											<span>
												132 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span>
											</span>
										</td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/g_studentenfutter_germany.png' alt='Pet Food' className='icon-image' />
											</span>
											<span>
												<strong>Pet Food</strong>
											</span>
										</td>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' className='icon-image' />
											</span>
											<span>
												2200 adena + <span className='trn' data-trn-key='label_castletax'>castle tax</span>
											</span>
										</td>
							        </tr>
							    </tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Pets