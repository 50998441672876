import React from 'react'
import { NavLink } from 'react-router-dom'

const Maintenance = props => {
	return (
		<div id='internal' className='notfound'>
			<div className='wrapper'>
				<div className='box-wrapper'>
					<div className='box-content'>
						<div className='text-center margin-small-bottom'>
							<img src='../assets/img/img_maintenance.png' alt='' />
						</div>
						<h2 className='wiki-title text-center trn' data-trn-key='maintenance'>This page is under maintenance.</h2>
						<p className='wiki-paragraph text-center trn' data-trn-key='maintenance_desc'>
							This page is currently being updated, rest assured,<br/>
							it will soon be online again, try again later. 
						</p>
						<div className='text-center'>
							<NavLink to='/wiki' className='button trn' data-trn-key='notfound_button' style={{ display: 'inline-flex' }}>Go back to the main page</NavLink>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default Maintenance