import React from 'react'
import { NavLink } from 'react-router-dom'

const Header = props => {
	return (
		<div>
			<header>
			<meta name="yandex-verification" content="09c5ca5494abc060" />
				<div>
					<NavLink to='/' className='logo'>
						<img src='./assets/img/logo_l2abyss.png' />
					</NavLink>
					<nav>
						<ul>
							<li>
								<NavLink exact={true} activeClassName='active' to='/' className='trn' data-trn-key='home'>
									Home
								</NavLink>
							</li>
							{/*<li>
								<NavLink activeClassName='active' to='/news' className='trn' data-trn-key='news'>
									News & Updates
								</NavLink>
							</li>*/}
							<li>
								<NavLink activeClassName='active' to='/wiki'>
									Wiki
								</NavLink>
							</li>
							<li>
								<a className='trn' data-trn-key='donate' href='https://lk.l2abyss.com/account/login'>Contribute</a>
							</li>
							<li>
								<a target='_blank' className='trn' data-trn-key='forums' href='https://discord.gg/m3BqnWwFPy' target='_blank'>Community</a>
							</li>
							<li>
								<NavLink activeClassName='active' to='/streamers'>
									Streamers
								</NavLink>
							</li>
							<li className='show-smartphone'>
								<a href='https://lk.l2abyss.com/account/login' className='button-alt trn' data-trn-key='signin'>My Account</a>
							</li>
							<li className='show-smartphone'>
								<NavLink to='/connect' className='button trn' data-trn-key='install'>
									Install the Game
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>
				<div>
					<div className='langs'>
						<div className='selected'>
							English
						</div>
						<ul>
							<li className='lang_selector' data-value='en'>English</li>
							<li className='lang_selector' data-value='ru'>Russian</li>
							<li className='lang_selector' data-value='pt'>Ukraine</li>
							<li className='lang_selector' data-value='es'>Spanish</li>
						</ul>
					</div>

					<a href='https://lk.l2abyss.com/account/login' className='button-alt hide-smartphone trn' data-trn-key='signin'>My Account</a>
					<NavLink to='/connect' className='button bt-download hide-smartphone trn' data-trn-key='install'>
						Install the Game
					</NavLink>

					<div className='menu-mobile'>
						<span></span>
					</div>
				</div>
			</header>
			<div className='anchor-header' id='totop'></div>		
		</div>
	)
}
export default Header