import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const RandomCraft = props => {
	return (
	<div id='internal' className='wiki'>
		<div className='wrapper'>
			<h1 className='internal-title'>
				Essence Wiki
			</h1>
			<div className='box-wrapper'>
				<HeaderWiki />
				<div className='box-breadcrumb'>
					<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
					<a>Random Craft</a>
				</div>

				<div className='box-content wiki-content'>
					<h2 className='wiki-title'>Random Craft</h2>
					<div className='float-right padding-default-left'>
						<img className='wiki-featured-image' src='../assets/img/wiki/randomcraft_window.jpg' alt='' />
					</div>
					<ul className='padding-default-top trn' data-trn-key='label_randomcraft_01'>
						<li>A feature that allows characters to create various items: weapons, armor, Enchant scrolls, epic accessories and other items.</li>
					    <li>Random Craft is available to all classes and doesn’t require special recipes, just adena and craft points.</li>
					    <li>Random Craft/material exchange window can be opened by pressing on the appropriate button on the extra panel under the map.</li>
					    <li>
					        Craft points are added automatically when you killing mobs (10% chance), pick up raw materials, dropped by monsters, or disassemble items. When the bar is 100% filled, you get 1 Craft point.
					        <ul>
					            <li>You can take apart various items, for example, materials, weapons and armor, including enchanted items.</li>
					        </ul>
					    </li>
					    <li>You can have a maximum of 99 Craft points, points above that won’t be counted.</li>
					    <li>
					        When you have at least 1 Craft point and <span className='item'><span className='i_name'>10,000 adena </span> you can update Random Craft list.</span>
					    </li>
					    <li>
					        If you update the list right after getting at least 1 Craft point, you character will receive Sayha's Blessing — 2 pcs., when updating the list without getting Craft points, Sayha's Blessing is not received.
					    </li>
					    <li>Random Craft consumes 500,000 adena, and the character can receive 1 out of 5 items from the list. The probability is the same for all items (20% chance).</li>
					</ul>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_process'>About the process</h3>
					<ul className='trn' data-trn-key='label_randomcraft_02'>
					    <li>Before you use Random Craft for the first time, the item list is empty, you have to press the “Update List” button for the items to appear.</li>
					    <li>
					        Updating the list requires Craft points, which can be received by filling up Random Craft bar using various items. Refilliing is available via the
					        <img src='../assets/img/wiki/randomcraft_button.jpg' alt='' width='19' height='18' /> button in the Random Craft window or in the additional information under the mini-map.
					    </li>
					    <li>Some items can’t be used for bar refilling, for example, any Spiritshots and Soulshots, Fishing Shots, agathions, talismans, bracelets.</li>
					    <li>
					        After the list is updated, there’ll be 5 random items and the “Create” button will become available.
					    </li>
					    <li>
					        You can lock up to 3 item slots using L-Coins, meaning that they won’t change when the list is updated.
					        <ul>
					            <li>The first lock - L-Coin — 100 pcs., the second lock - L-Coin — 500 pcs., the third lock - L-Coin — 1,000 pcs.</li>
					            <li>A locked slot can’t be unlocked, it will stay locked for 20 updates of the list (the amount of updates left is displayed above the lock), then it will break and you won’t be able to lock it again.</li>
					            <li>All locks disappear after the “Create” button is pressed.</li>
					        </ul>
					    </li>					    
					</ul>
					<hr className='invisible' />
					<ul className='trn' data-trn-key='label_randomcraft_03'>
					    <li>
					        After the “Create” button is pressed, you will be charged a fee of 500,000 adena, and a random item from the list will be created.
					        <ul>
					            <li>Random Craft is always successful, one item from the list of five is always created.</li>
					            <li>After that, all items will disappear from the list, not just the crafted one.</li>
					        </ul>
					    </li>
						<hr className='invisible' />
						<li>The list of items can contain specific items as as well as unknown items indicated by a question mark and purple border. An unknown item can be a helmet, armor, boots, gloves and an accessory. If you manage to create such item, you’ll receive an unsealed item of that type of A-grade or B-grade.</li>
					</ul>

					<hr className='invisible' />
					<div className='float-right padding-default-left'>
						<img className='wiki-featured-image' src='../assets/img/wiki/randomcraft_items.jpg' alt='' />
					</div>
					<ul className='trn' data-trn-key='label_randomcraft_04'>
					    <li>
					        Items of different grades are displayed with different backgrounds:
					        <ul>
					            <li>D-grade and C-grade items have a normal background.</li>
					            <li>B-grade items have a yellow background.</li>
					            <li>A-grade items, brooches and bracelets have a blue background.</li>
					            <li>Epic accessories, special armor have a red background.</li>
					        </ul>
					    </li>
					</ul>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_randomcraftcharge'>Random Craft charge bar refill</h3>
					<ul className='trn' data-trn-key='label_randomcraft_05'>
					    <li>Monsters and Raid Bosses sometimes drop raw materials. Items of that type go directly towards Random Craft charge: items aren’t placed in the inventory, but automatically and immediatelly fill up Random Craft charge bar.</li>
					    <li>Monsters and Raids sometimes give automatically Random Craft charge bar points by depending the amount of Exp obtained.</li>
					    <hr className='invisible' />
					    <li>
					        When level 65-80 Raid Bosses are defeated, the Command Channel might find an NPC Chest of Vengeance, which drops a lot of raw materials for Random Craft charge when destroyed.
					        <ul>
					            <li>The higher the Raid Boss, the more raw materials a Chest of Vengeance will drop.</li>
					            <li>Raw materials can be picked up by any character.</li>
					            <li>Chest of Vengeance can drop the following raw materials: Material: Leather Scraps, Material: Plain Fabric, Material: Unknown Metal, Material: Sack Shreds, Material: Bag of Low-grade Stuff, Material: Animal Spirit.</li>
					        </ul>
					    </li>
					    <li>
					        The following raw materials can be acquired through spoils.
					        <ul>
					            <li>Craft points gained from raw materials fill Random Craft charge for specific amounts of percents.</li>
					        </ul>
					    </li>
					</ul>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th>Item</th>
						            <th className='trn' data-trn-key='label_result'>Result</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr className=''>
						            <td>
						                <span className='item'><span className='i_name'>Old Resources</span></span>
						            </td>
						            <td>Craft points +100</td>
						        </tr>
						        <tr>
						            <td>Unknown Resources</td>
						            <td>Craft points +500</td>
						        </tr>
						        <tr>
						            <td>Shining Resources</td>
						            <td>Craft points +1000</td>
						        </tr>
						        <tr className=''>
						            <td>High-grade Resources</td>
						            <td>Craft points +5000</td>
						        </tr>
						        <tr className=''>
						            <td>Thons</td>
						            <td>Craft points +10,000</td>
						        </tr>
						        <tr>
						            <td>Asofe</td>
						            <td>Craft points +100,000</td>
						        </tr>
						        <tr>
						            <td>Enria</td>
						            <td>Craft points +500,000</td>
						        </tr>
						    </tbody>
						</table>
					</div>
					<p className='trn' data-trn-key='label_randomcraft_06'>
					Item Charging Stone of Random Craft - 1 Charge, that gives 1 Craft point (+100% to the bar), can be received by participating in various events, doing Daily missions, and found in Orc King's Treasure.</p>
				</div>
			</div>
		</div>
	</div>
	)
}

export default RandomCraft