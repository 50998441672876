import React from 'react'
import HeaderWiki from './HeaderWiki'
import { NavLink } from 'react-router-dom'

const Starting = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />

					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a className='trn' data-trn-key='label_startingguide'>Starting Guide</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_startingguide'>Starting Guide</h2>
						<p className='wiki-paragraph trn' data-trn-key='label_startingguide_desc'>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, et.
						</p>
						<h3 className='wiki-subtitle trn' data-trn-key='label_starting_01'>Starting quests</h3>
						<p className='trn' data-trn-key='label_starting_02'>Para ter uma grande inicial na sua jornada, você pode fazer as quests iniciais do nível 1 ao 40, além de te dar Exp e consumíveis muito úteis, você também recebe set e armadura D-Grade ao atingir o nível 20 e completar a 1st profissão.<br/>Veja a listas das principais quests e o que você recebe:</p>
						<div className='table-scrolling'>
							<table style={{ width: '100%' }}>
							    <thead>
							        <tr>
							            <th style={{ minWidth: 70 }} className='trn' data-trn-key='label_lv'>Lv.</th>
							            <th style={{ minWidth: 220 }}>Quest</th>
							            <th style={{ minWidth: 200 }}>Classes</th>
							            <th>NPC</th>
							            <th style={{ minWidth: 260 }} className='trn' data-trn-key='label_reward'>Rewards</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>1</td>
							            <td>Tutorial</td>
							            <td>-</td>
							            <td>-</td>
							            <td>
			                                <div>
			                                	<img className='icon-image-xsmall' src='./Icon/Texture/etc_spirit_bullet_gold_i00.png' />
			                                	Soulshot x200
			                                </div>
			                                or
			                                <div>
			                                	<img className='icon-image-xsmall' src='./Icon/Texture/	etc_spell_shot_gold_i00.png' />
			                                	Spiritshot x100
			                                </div>
							            </td>
							        </tr>
							        <tr>
							        	<td>2-20</td>
							        	<td>
							        		Unbearable Wolves' Howling<br/>
							        		Troubled Forest<br/>
							        		Cleaning-up the Grounds<br/>
							        		Plundered Graves<br/>
							        		Dangerous Predators<br/>
							        		Effective Training<br/>
							        		Exploring New Opportunities
							        	</td>
							        	<td>Human Classes<br/>Elf Classes<br/>Dark Elf Classes<br/>Orc Classes<br/>Dwarven Classes<br/>Kamael Classes<br/>Death Knight Classes</td>
							        	<td>Beginners Guild</td>
										<td>
			                                <div>
			                                    <img className='icon-image-xsmall' src='./Icon/Texture/etc_scroll_of_return_i00.png' />
			                                    Scroll of Escape (Novice) x10
			                                </div>
			                                <div>
			                                    <img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' />
			                                    EXP 260000
			                                </div>
			                                <div>
			                                    <img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' />
			                                    SP 6000
			                                </div>
			                                <div>
			                                    <img className='icon-image-xsmall' src='./Icon/Texture/accessary_necklace_of_knowledge_i00.png' />
			                                    Necklackle (Novice) x1
			                                </div>
			                                <div>
			                                    <img className='icon-image-xsmall' src='./Icon/Texture/accessary_mage_earing_i00.png' />
			                                    Earring (Novice) x2
			                                </div>
			                                <div>
			                                    <img className='icon-image-xsmall' src='./Icon/Texture/accessary_ring_of_knowledge_i00.png' />
			                                    Ring (Novice) x2
			                                </div>
			                            </td>
							        </tr>
							        <tr>
							        	<td>15-20</td>
							        	<td>
							        		Spider Hunt<br/>
							        		Spider Web<br/>
							        		Terrible Swamp Monsters<br/>
							        		Conspiracy<br/>
							        		Poison Extraction<br/>
							        		New Horizons<br/>
							        		Challenging Your Destiny
							        	</td>
							        	<td>Human Classes<br/>Elf Classes<br/>Dark Elf Classes<br/>Orc Classes<br/>Dwarven Classes<br/>Kamael Classes<br/>Death Knight Classes</td>
							        	<td>Jackson<br/>Herbiel<br/>Vollodos<br/>Asuka<br/>Herald<br/>Leygon<br/>Mathorn</td>
										<td>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' /> EXP <span className='color-accent'>600000</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' /> SP <span className='color-accent'>13500</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_scroll_of_return_i00.png' /> Scroll of Escape (Novice) <span className='color-accent'>x20</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_lesser_potion_red_i00.png' /> HP Potion <span className='color-accent'>x50</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_stone_gray_i00.png' /> Spirit Ore<span className='color-accent'>x50</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_recall_exp_scroll.png' /> XP Growth Scroll</div>
			                            </td>
							        </tr>
							        <tr>
							            <td>20+</td>
							            <td>A Trip Begins</td>
							            <td>-</td>
							            <td>Captain Bathis</td>
							            <td>
			                                <div>
			                                	<span className='icon-image-xsmall panel-blessed'>
			                                		<img src='./Icon/Texture/etc_scroll_of_return_i01.png' />
			                                	</span>
			                                	 Improved Scroll of Escape x10 </div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' /> EXP 1000000 </div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' /> SP 27000 </div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/bm_aden_talisman.png' /> Talisman of Aden x1 </div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/aden_talisman_scroll.png' /> Scroll : Enchant Talisman of Aden x1 </div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-blessed'>
			                                		<img src='./Icon/Texture/etc_rbracelet_c_i00.png' />
			                                	</span>
			                                	 Adventurer's Bracelet</div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-blessed'>
			                                		<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i01.png' />
			                                	</span>
			                                	 Scroll: Enchant Aden Weapon x2
			                                </div>
			                            </td>
							        </tr>
							        <tr>
							            <td>25-30</td>
							            <td>More Experience</td>
							            <td>-</td>
							            <td>Captain Bathis</td>
			                            <td>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' /> EXP 2500000</div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' /> SP 67500</div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-opt'>
			                                		<img src='./Icon/Texture/etc_bm_brooch_lavianrose_i00.png' />
			                                	</span>
			                                	 Adventurer's Brooch
			                                </div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-step-level-1'>
			                                		<img src='./Icon/Texture/etc_crystal_piece_i00.png' />
			                                	</span>
			                                	 Adventurer's Rough Jewel
			                                </div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-blessed'>
			                                		<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i01.png' />
			                                	</span>
			                                	 Scroll: Enchant Aden Weapon x2
			                                </div>
			                            </td>
							        </tr>
							        <tr>
							            <td>30-35</td>
							            <td>Secret Garden</td>
							            <td>-</td>
							            <td>Captain Bathis</td>
							            <td>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' /> EXP 3500000</div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' /> SP 95000</div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-opt'>
			                                		<img src='./Icon/Texture/agathion_magmeld_teleport.png' />
			                                	</span>
			                                	 Adventurer's Agathion Bracelet
			                                </div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-opt'>
			                                		<img src='./Icon/Texture/griffin_vehicle.png' />
			                                	</span>
			                                	 Adventurer's Agathion - Griffin
			                                </div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-blessed'>
			                                		<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i01.png' />
			                                	</span>
			                                	 Scroll: Enchant Aden Weapon x2
			                                </div>
			                            </td>
							        </tr>
							        <tr>
							            <td>35-40</td>
							            <td>Death Mysteries</td>
							            <td>-</td>
							            <td>Raymond</td>
							            <td>
							            	<div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' /> EXP <span className='color-accent'>5000000</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' /> SP <span className='color-accent'>135000</span></div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-star-level-1'>
			                                		<img src='./Icon/Texture/r99_soul_stone_i02.png' />
			                                	</span>
			                                	 Blue Lantern
			                                </div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_recall_potion2.png' /> Magic Lamp Potion</div>
			                                <div>
			                                	<span className='icon-image-xsmall panel-blessed'>
			                                		<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i01.png' />
			                                	</span>
			                                	 Scroll: Enchant Aden Weapon x2
			                                </div>
			                            </td>
							        </tr>
							        <tr>
							            <td>39+</td>
							            <td>Magic Lamp Power</td>
							            <td>-</td>
							            <td>Maximillian</td>
							            <td>
							            	<div><img className='icon-image-xsmall' src='./Icon/Texture/bm_soul_of_shilen.png' /> Magic Fire</div>
			                            </td>
							        </tr>
							        <tr>
							            <td>40-45</td>
							            <td>Spore-infested place</td>
							            <td>-</td>
							            <td>Maximillian</td>
							            <td>
							            	<div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' /> EXP <span className='color-accent'>1000000</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' /> SP <span className='color-accent'>27000</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_lesser_potion_red_i00.png' /> HP Potion <span className='color-accent'>x100</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exchange_ticket_i03.png' /> Soulshot Ticket <span className='color-accent'>x50</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_stone_gray_i00.png' /> Spirit Ore <span className='color-accent'>x500</span></div>
			                            </td>
							        </tr>
							        <tr>
							            <td>40+</td>
							            <td>New Stylish Equipment</td>
							            <td>-</td>
							            <td>Orven</td>
							            <td>
			                                <div>
			                                	<span className='icon-image-xsmall panel-cursed'>
			                                		<img src='./Icon/Texture/bm_pendant_pve.png' />
			                                	</span>
			                                	Fire Dragon Pendant</div>
			                            </td>
							        </tr>
							        <tr>
							            <td>45+</td>
							            <td>Respect for the Graves</td>
							            <td>-</td>
							            <td>Orven</td>
							            <td>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exp_point_i00.png' /> EXP 2500000</div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_sp_point_i00.png' /> SP 67500</div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exchange_ticket_i03.png' /> Asofe <span className='color-accent'>x1</span></div>
			                            </td>
							        </tr>
							        <tr>
							            <td>45+</td>
							            <td>
							            	Memories of Glorious Times<br/>
							            	Let's Pay Respect<br/>
							            	Traces of Battle
							            </td>
							            <td>-</td>
							            <td>Orven</td>
							            <td>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_exchange_ticket_i03.png' /> Soulshot Ticket <span className='color-accent'>x10</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_stone_gray_i00.png' /> Spirit Ore <span className='color-accent'>x450</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/etc_recall_potion2.png' /> Magic Lamp Potion <span className='color-accent'>x3</span></div>
			                                <div><img className='icon-image-xsmall' src='./Icon/Texture/ev_magic_flame_game_wind2.png' /> Sayha's Gust  <span className='color-accent'>x9</span></div>
			                            </td>
							        </tr>
							    </tbody>
							</table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_starting_03'>Self Dependence Buffs</h3>
						<p className='trn' data-trn-key='label_starting_04'>No Lineage II Essence você não precisa de personagens extras para suporte, cada personagem tem seus próprios buffs para ajudar na sua jornada.</p>
						<img src='../assets/img/wiki/starting_buffs.jpg' alt='' className='wiki-featured-image' />
						<ul className='trn' data-trn-key='label_starting_05'>
							<li>Seus buffs são aprendidos de acordo com o nível, eles estarão disponíveis na janela 'Skills > Learn Skills' (Alt + K). Você pode ler mais sobre os níveis para aprender as skills em: <a href='#'>Class Skills</a>.</li>
							<li>Algumas skills de buffs precisam de livros para serem aprendidas.</li>
							<li>Para usar alguns bufs você precisa de uma certa quantidade de 'Spirit Ore'.</li>
							<li>Buffs podem ser ativados para serem usados automaticamente quando estiver próximo de acabar o tempo.</li>
						</ul>
						<hr className='invisible' />
						<h3 className='wiki-subtitle' data-trn-key=''>Auto-hunting</h3>
						<div className='trn' data-trn-key='label_starting_06'>
							Você pode ativar/desativar o modo de 'Auto-hunting' para que seu personagem possa atacar alvos de forma automática. Uma ótima maneira de garantir que seu personagem continue ganhando experência, adena e itens enquanto você não estiver no computador.
						</div>
						
						<hr className='invisible' />
						<div className='trn' data-trn-key='label_starting_07'>
							<p><strong>Ativando o auto-hunting</strong></p>
							<ul>
								<li>Para ativar o auto hunting, basta clicar no botão apropriado na interface do jogo. Você também pode ativar o auto hunting através do atalho (Alt + Z).</li>
								<li>O auto hunting utilizará o ataque básico inicialmente, para que skills e itens sejam usados automaticamente, você deve arrastar o item ou skill para a barra de habilidades e clicar com o botão direito.</li>
								<li>Alguns itens e skills não podem ser usados automaticamente, os que podem ser usados são informados através do ícone de auto-uso.</li>
							</ul>
						</div>
						<div className='table-scrolling'>
							<table className='content-center'>
							    <thead>
							        <tr>
							            <th width='100'>Auto-hunting on</th>
							            <th width='100'>Auto-hunting off</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td><img src='../assets/img/wiki/starting_autohunt_on.jpg' alt='' /></td>
							            <td><img src='../assets/img/wiki/starting_autohunt_off.jpg' alt='' /></td>
							        </tr>
							    </tbody>
							</table>
						</div>

						<hr className='invisible' />
						<div className='trn' data-trn-key='label_starting_08'>
							<p><strong>Funções do auto-hunting</strong></p>
							<p>As funções do auto-hunting podem ser ativadas ou desativadas clicando em cada um dos ícones na interface.</p>
						</div>
						<img src='../assets/img/wiki/starting_autohunt.jpg' alt='' className='wiki-featured-image' />
						<ol className='trn' data-trn-key='label_starting_09'>
							<li>Combat mode: quando ativado é possível acatar outros jogadores em modo de combate (flag ou pk) quando estiverem ao alcance. Quando desativado, atacará apenas monstros e Raids.</li>
							<li>Auto-loot: ativa/desativa a obtenção automatica de itens dropados no chão.</li>
							<li>Respectful Hunting Mode: quando ativado, não ataca monstros que outros jogadores já estão atacando.</li>
							<li>Auto-target Range: muda o modo em que o personagem irá selecionar alvos com base na distância. Long-range seleciona alvos em uma distância maior, short-range seleciona alvos em uma distância menor.</li>
						</ol>
					</div>
				</div>
			</div>
		</div>		
	)
}

export default Starting