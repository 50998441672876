import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Brooches = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/otheritems' className='trn' data-trn-key='wiki_otheritems'>Other Items</NavLink>
						<a className='trn' data-trn-key='label_brooches'>Brooches</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_brooches'>Brooches</h2>
						<img src='/assets/img/wiki/brooches.jpg' className='wiki-featured-image float-right margin-default-left' />
						<p className='wiki-paragraph trn' data-trn-key='label_brooches_01'>
							Brooch  is a unique accessory that has its own slot in the inventory. Brooch slot can be filled with jewels that give various buffs.
						</p>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_brooches_02'>
							<li>All brooches and jewels are No-Grade items.</li>
							<li>Brooches unlock jewel slots when they are equipped in an appropriate slot. Depending on brooch’s level, it can unlock from 2 to 6 slots.</li>
							<li>Unlocked slots can be filled with jewels which grant various buffs, see: List of Jewels.</li>
							<li>To install a jewel, equip a brooch, then double-click on a jewel in your inventory (or press RMB) to install it into the available slot. After that the character will receive passive or active effects of the jewel.</li>
							<li>Brooches not only activate jewel slots, but also add inventory slots:</li>
						</ul>
						<table>
							<thead>
								<tr>
									<th>Brooch</th>
									<th>Amount of jewel slots</th>
									<th>Amount of inventory slots</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i00.png' alt='Brooch Lv. 1' /> <strong>Brooch Lv. 1</strong>
									</td>
									<td>2</td>
									<td>+5</td>
								</tr>
								<tr>
									<td>
										<img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i01.png' alt='Brooch Lv. 2' /> <strong>Brooch Lv. 2</strong>
									</td>
									<td>3</td>
									<td>+9</td>
								</tr>
								<tr>
									<td>
										<img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i03.png' alt='Brooch Lv. 3' /> <strong>Brooch Lv. 3</strong>
									</td>
									<td>4</td>
									<td>+12</td>
								</tr>
								<tr>
									<td>
										<img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i05.png' alt='Brooch Lv. 4' /> <strong>Brooch Lv. 4</strong>
									</td>
									<td>5</td>
									<td>+12</td>
								</tr>
								<tr>
									<td>
										<img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i06.png' alt='Brooch Lv. 5' /> <strong>Brooch Lv. 5</strong>
									</td>
									<td>6</td>
									<td>+15</td>
								</tr>
							</tbody>
						</table>
						<ul className='trn' data-trn-key='label_brooches_03'>
							<li>You can use Compounding, to increase the level of a brooch.</li>
							<li>You can use Compounding, to increase the level of a jewel.</li>
							<li>All jewels and their active skills can be use in the Olympiad.</li>
							<li>Brooches can’t be enchanted.</li>
						</ul>	
						<hr className='invisible' />

						<h3 className='wiki-subtitle trn' data-trn-key='label_howtoobtain'>How to obtain</h3>

						<div className='trn' data-trn-key='label_brooches_04'>
							<ul>
								<li>
									<img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i00.png' alt='Brooch Lv. 1' /> <strong>Brooch Lv. 1</strong> can be bought in <strong>L-Coin Store</strong>.
								</li>
								<li>Acquiring a higher level brooch requires <strong>Compounding</strong>.</li>
							</ul>				
							
							<p>Brooch Compounding</p>
							<ul>
								<li>There are 5 levels of brooches. To get the maximum effect you need to increase the brooch’s level by pressing «Compound» in your inventory.</li>
								<li>
									Compounding requires two identical brooches of the same level, for example:<br/>
									<img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i00.png' alt='Brooch Lv. 1' /> <strong>Brooch Lv. 1</strong> + <img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i00.png' alt='Brooch Lv. 1' /> <strong>Brooch Lv. 1</strong> = <img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i01.png' alt='Brooch Lv. 2' /> <strong>Brooch Lv. 2</strong>
								</li>
								<li>On a successful attempt, you will have a brooch that is 1 level higher.</li>
								<li>On a failed attempt, one brooch will be destroyed, but the other one can be used in another Compounding attempt.</li>
								<li>Compounding Rate:</li>
							</ul>
						</div>

						<table>
							<thead>
								<tr>
									<th>Brooch</th>
									<th className='trn' data-trn-key='label_successrate'>Success Rate</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Lv. 1 → Lv. 2</td>
									<td>25%</td>
								</tr>
								<tr>
									<td>Lv. 2 → Lv. 3</td>
									<td>25%</td>
								</tr>
								<tr>
									<td>Lv. 3 → Lv. 4</td>
									<td>25%</td>
								</tr>
								<tr>
									<td>Lv. 4 → Lv. 5</td>
									<td>25%</td>
								</tr>
							</tbody>
						</table>

						<hr className='invisible' />

						<div className='trn' data-trn-key='label_brooches_05'>
							<h3 className='wiki-subtitle trnn' data-trn-key=''>Jewels</h3>
							<p>Jewels are special stones giving powerful active and/or passive skills once a brooch is equipped. There are 7 types of jewels in the current version of the game.</p>	
							<ul>
								<li>Jewels can be of 8 levels.</li>
								<li>A brooch can contain up to 6 jewels (the amount of jewel slots depends on a brooch).</li>
								<li>You can change jewels any time you like: to replace it, you need to open the chest, right-click the stone which needs to be replaced, and then insert the new one.</li>
								<li>If you use jewels of the same type, but their levels are different, only the effect of the highest level jewel is applied. For example, if you use Onyx level 4 and 8, only the effect of the jewel level 8 will be applied.</li>
							</ul>
							<p className='wiki-paragraph'>
								L-Coin Store sells item <span className='icon-image panel-redlibra'><img src='/Icon/Texture/bm_2018_jewelbox_random.png' alt='Reinforced Jewel Box' /></span> <strong>Reinforced Jewel Box</strong> can be bought in <strong>L-Coin Store</strong>. It contains 1 random Lv. 1 Jewel.
							</p>
						</div>

						<hr className='invisible' />

						<div className='trn' data-trn-key='label_brooches_06'>
							<h3 className='wiki-subtitle'>How to increase level of a jewel</h3>
							<p>
								Each jewel has 8 levels: the higher the level, the more powerful is jewel’s effect. To get the maximum effect you need to increase the jewel’s level by pressing «Compound» in your inventory.
							</p>
							<ul>
								<li>
									Only jewels of the same type and level can be used in Compounding. Example:<br/>
									<span className='icon-image panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 1' /></span> <strong>Onyx Lv. 1</strong> + <span className='icon-image panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 1' /></span> <strong>Onyx Lv. 1</strong> = <span className='icon-image panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 2' /></span> <strong>Onyx Lv. 2</strong>
								</li>
								<li>On a successful attempt, you will have a jewel that is 1 level higher.</li>
								<li>On a failed attempt, one jewel will be destroyed, but the other one can be used in another Compounding attempt.</li>
								<li>Compounding Rate:</li>
							</ul>
						</div>
						<table>
							<thead>
								<tr>
									<th className='trn' data-trn-key='level'>level</th>
									<th className='trn' data-trn-key='label_successrate'>Success Rate</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Lv. 1 → Lv. 2</td>
									<td>65%</td>
								</tr>
								<tr>
									<td>Lv. 2 → Lv. 3</td>
									<td>40%</td>
								</tr>
								<tr>
									<td>Lv. 3 → Lv. 4</td>
									<td>35%</td>
								</tr>
								<tr>
									<td>Lv. 4 → Lv. 5</td>
									<td>30%</td>
								</tr>
								<tr>
									<td>Lv. 5 → Lv. 6</td>
									<td>25%</td>
								</tr>
								<tr>
									<td>Lv. 6 → Lv. 7</td>
									<td>20%</td>
								</tr>
								<tr>
									<td>Lv. 7 → Lv. 8</td>
									<td>15%</td>
								</tr>
							</tbody>
						</table>

						<div className='table-tabs'>
							<span rel='jewel-onyx' className='active'>Onyx</span>
							<span rel='jewel-spinel'>Spinel</span>
							<span rel='jewel-amber'>Amber</span>
							<span rel='jewel-coral'>Coral</span>
							<span rel='jewel-zircon'>Zircon</span>
							<span rel='jewel-moonstone'>Moonstone</span>
						</div>
						<div className='table-content'>
							<table id="jewel-onyx">
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 1' /></span>
											<span><strong>Onyx</strong> Lv. 1</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +2%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 2' /></span>
											<span><strong>Onyx</strong> Lv. 2</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +3%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-3'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 3' /></span>
											<span><strong>Onyx</strong> Lv. 3</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +5%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-4'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 4' /></span>
											<span><strong>Onyx</strong> Lv. 4</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +8%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-5'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 5' /></span>
											<span><strong>Onyx</strong> Lv. 5</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +12%.
											M. Atk. +50
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-6'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 6' /></span>
											<span><strong>Onyx</strong> Lv. 6</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +16%<br/>
											P. Atk. +50<br/>
											M. Atk. +50
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-7'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 7' /></span>
											<span><strong>Onyx</strong> Lv. 7</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +20%<br/>
											P. Atk. +150<br/>
											M. Atk. +150
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-8'><img src='/Icon/Texture/etc_bm_jewel_onyx_i00.png' alt='Onyx Lv. 8' /></span>
											<span><strong>Onyx</strong> Lv. 8</span>
										</td>
										<td>
											Soulshot/ Spiritshot damage +25%<br/>
											P. Atk. +300<br/>
											M. Atk. +300
										</td>
									</tr>
								</tbody>
							</table>
							<table id="jewel-moonstone">
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 1' /></span>
											<span><strong>Moonstone</strong> Lv. 1</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 2' /></span>
											<span><strong>Moonstone</strong> Lv. 2</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +4%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-3'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 3' /></span>
											<span><strong>Moonstone</strong> Lv. 3</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +6%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-4'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 4' /></span>
											<span><strong>Moonstone</strong> Lv. 4</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +8%<br/>
											Sayha's Grace Consumption -10%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-5'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 5' /></span>
											<span><strong>Moonstone</strong> Lv. 5</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +11%<br/>
											Sayha's Grace Consumption -20%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-6'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 6' /></span>
											<span><strong>Moonstone</strong> Lv. 6</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +15%<br/>
											Sayha's Grace Consumption -30%<br/>
											Magic Lamp charging rate +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-7'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 7' /></span>
											<span><strong>Moonstone</strong> Lv. 7</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +20%<br/>
											Sayha's Grace Consumption -30%<br/>
											Magic Lamp charging rate +3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-8'><img src='/Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone Lv. 8' /></span>
											<span><strong>Moonstone</strong> Lv. 8</span>
										</td>
										<td>
											While Sayha's Grace is active, Acquired XP/ SP +25%<br/>
											Sayha's Grace Consumption -30%<br/>
											Magic Lamp charging rate +5%
										</td>
									</tr>
								</tbody>
							</table>
							<table id="jewel-zircon">
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 1' /></span>
											<span><strong>Zircon</strong> Lv. 1</span>
										</td>
										<td>
											P. Critical Damage +2%<br/>
											M. Skill Critical Damage +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 2' /></span>
											<span><strong>Zircon</strong> Lv. 2</span>
										</td>
										<td>
											P. Critical Damage +4%<br/>
											M. Skill Critical Damage +4%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-3'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 3' /></span>
											<span><strong>Zircon</strong> Lv. 3</span>
										</td>
										<td>
											P. Critical Damage +6%<br/>
											M. Skill Critical Damage +6%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-4'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 4' /></span>
											<span><strong>Zircon</strong> Lv. 4</span>
										</td>
										<td>
											P. Critical Damage +8%<br/>
											M. Skill Critical Damage +8%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-5'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 5' /></span>
											<span><strong>Zircon</strong> Lv. 5</span>
										</td>
										<td>
											P. Critical Damage +11%<br/>
											M. Skill Critical Damage +11%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-6'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 6' /></span>
											<span><strong>Zircon</strong> Lv. 6</span>
										</td>
										<td>
											P. Critical Damage +15%<br/>
											M. Skill Critical Damage +15%<br/>
											P. Skill Critical Damage +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-7'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 7' /></span>
											<span><strong>Zircon</strong> Lv. 7</span>
										</td>
										<td>
											P. Critical Damage +15%<br/>
											M. Skill Critical Damage +15%<br/>
											P. Skill Critical Damage +5%<br/>
											Skill Power +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-8'><img src='/Icon/Texture/etc_bm_jewel_bloodstone_i00.png' alt='Zircon Lv. 8' /></span>
											<span><strong>Zircon</strong> Lv. 8</span>
										</td>
										<td>
											P. Critical Damage +15%<br/>
											M. Skill Critical Damage +23%<br/>
											P. Skill Critical Damage +9%<br/>
											Skill Power +5%
										</td>
									</tr>
								</tbody>
							</table>
							<table id="jewel-coral">
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 1' /></span>
											<span><strong>Coral</strong> Lv. 1</span>
										</td>
										<td>
											Atk. Spd. +1%<br/>
											Casting Spd. +1%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 2' /></span>
											<span><strong>Coral</strong> Lv. 2</span>
										</td>
										<td>
											Atk. Spd. +2%<br/>
											Casting Spd. +2%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-3'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 3' /></span>
											<span><strong>Coral</strong> Lv. 3</span>
										</td>
										<td>
											Atk. Spd. +4%<br/>
											Casting Spd. +4%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-4'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 4' /></span>
											<span><strong>Coral</strong> Lv. 4</span>
										</td>
										<td>
											Atk. Spd. +7%<br/>
											Casting Spd. +7%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-5'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 5' /></span>
											<span><strong>Coral</strong> Lv. 5</span>
										</td>
										<td>
											Atk. Spd. +10%<br/>
											Casting Spd. +10%.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-6'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 6' /></span>
											<span><strong>Coral</strong> Lv. 6</span>
										</td>
										<td>
											Atk. Spd. +14%<br/>
											Casting Spd. +14%<br/>
											Speed +1
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-7'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 7' /></span>
											<span><strong>Coral</strong> Lv. 7</span>
										</td>
										<td>
											Atk. Spd. +18%<br/>
											Casting Spd. +18%<br/>
											Speed +2
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-8'><img src='/Icon/Texture/etc_bm_jewel_coral_i00.png' alt='Coral Lv. 8' /></span>
											<span><strong>Coral</strong> Lv. 8</span>
										</td>
										<td>
											Atk. Spd. +22%<br/>
											Casting Spd. +22%<br/>
											Speed +3
										</td>
									</tr>
								</tbody>
							</table>
							<table id="jewel-amber">
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 1' /></span>
											<span><strong>Amber</strong> Lv. 1</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 500 fixed damage.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 2' /></span>
											<span><strong>Amber</strong> Lv. 2</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 700 fixed damage.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-3'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 3' /></span>
											<span><strong>Amber</strong> Lv. 3</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 1000 fixed damage.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-4'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 4' /></span>
											<span><strong>Amber</strong> Lv. 4</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 1500 fixed damage.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-5'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 5' /></span>
											<span><strong>Amber</strong> Lv. 5</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 2000 fixed damage.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-6'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 6' /></span>
											<span><strong>Amber</strong> Lv. 6</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 2500 fixed damage.<br/>
											P./ M. Def. -4%<br/><br/>
											&lt;PvE effect&gt;<br/>
											P./ M. Def. -10%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-7'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 7' /></span>
											<span><strong>Amber</strong> Lv. 7</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 3000 fixed damage.<br/>
											P./ M. Def. -8%<br/><br/>
											&lt;PvE effect&gt;<br/>
											P./ M. Def. -15%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-8'><img src='/Icon/Texture/etc_bm_jewel_amber_i00.png' alt='Amber Lv. 8' /></span>
											<span><strong>Amber</strong> Lv. 8</span>
										</td>
										<td>
											&lt;Active skill&gt;<br/>
											Interrupts a skill used by the target.<br/><br/>
											&lt;PvP effect&gt;<br/>
											Ignores CP and deals 3500 fixed damage.<br/>
											P./ M. Def. -12%<br/><br/>
											&lt;PvE effect&gt;<br/>
											P./ M. Def. -20%
										</td>
									</tr>
								</tbody>
							</table>
							<table id="jewel-opal">
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 1' /></span>
											<span><strong>Opal</strong> Lv. 1</span>
										</td>
										<td>
											Pull/Stun Resistance +3%<br/>
											Fear/Paralysis/Silence Resistance +3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 2' /></span>
											<span><strong>Opal</strong> Lv. 2</span>
										</td>
										<td>
											Pull/Stun Resistance +5%<br/>
											Fear/Paralysis/Silence Resistance +5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-3'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 3' /></span>
											<span><strong>Opal</strong> Lv. 3</span>
										</td>
										<td>
											Pull/Stun Resistance +88%<br/>
											Fear/Paralysis/Silence Resistance +88%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-4'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 4' /></span>
											<span><strong>Opal</strong> Lv. 4</span>
										</td>
										<td>
											Pull/Stun Resistance +12%<br/>
											Fear/Paralysis/Silence Resistance +12%<br/><br/>
											&lt;Active skill&gt;<br/>
											Debuff immunity +5 sec.<br/>
											Cooldown: 5 min.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-5'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 5' /></span>
											<span><strong>Opal</strong> Lv. 5</span>
										</td>
										<td>
											Pull/Stun Resistance +15%<br/>
											Fear/Paralysis/Silence Resistance +15%<br/>
											HP/ MP +100<br/><br/>
											&lt;Active skill&gt;<br/>
											Debuff immunity +10 sec.<br/>
											Cooldown: 5 min.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-6'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 6' /></span>
											<span><strong>Opal</strong> Lv. 6</span>
										</td>
										<td>
											Pull/Stun Resistance +20%<br/>
											Fear/Paralysis/Silence Resistance +20%<br/>
											HP/ MP +200<br/><br/>
											&lt;Active skill&gt;<br/>
											Debuff immunity +15 sec.<br/>
											Cooldown: 5 min.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-7'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 7' /></span>
											<span><strong>Opal</strong> Lv. 7</span>
										</td>
										<td>
											Pull/Stun Resistance +25%<br/>
											Fear/Paralysis/Silence Resistance +25%<br/>
											HP/ MP +300<br/><br/>
											&lt;Active skill&gt;<br/>
											Debuff immunity +20 sec.<br/>
											Cooldown: 5 min.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-8'><img src='/Icon/Texture/etc_bm_jewel_opal_i00.png' alt='Opal Lv. 8' /></span>
											<span><strong>Opal</strong> Lv. 8</span>
										</td>
										<td>
											Pull/Stun Resistance +30%<br/>
											Fear/Paralysis/Silence Resistance +30%<br/>
											HP/ MP +500<br/><br/>
											&lt;Active skill&gt;<br/>
											Debuff immunity +25 sec.<br/>
											Cooldown: 5 min.
										</td>
									</tr>
								</tbody>
							</table>
							<table id="jewel-spinel">
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-1'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 1' /></span>
											<span><strong>Spinel</strong> Lv. 1</span>
										</td>
										<td>
											CON +1<br/>
											P. Def. +20<br/>
											M. Def. +10<br/>
											With a certain chance, MP +12 when using skills.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-2'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 2' /></span>
											<span><strong>Spinel</strong> Lv. 2</span>
										</td>
										<td>
											CON/MEN +1<br/>
											P. Def. +30<br/>
											M. Def. +10<br/>
											With a certain chance, MP +16 when using skills.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-3'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 3' /></span>
											<span><strong>Spinel</strong> Lv. 3</span>
										</td>
										<td>
											CON/MEN/DEX +1<br/>
											P. Def. +60<br/>
											M. Def. +30<br/>
											With a certain chance, MP +20 when using skills.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-4'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 4' /></span>
											<span><strong>Spinel</strong> Lv. 4</span>
										</td>
										<td>
											CON/MEN +1<br/>
											DEX/WIT +1<br/>
											Received P. Critical Damage -4%<br/>
											Received M. Skill Critical Damage -4%<br/>
											P. Def. +100<br/>
											M. Def. +60<br/>
											With a certain chance, MP +30 when using skills.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-5'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 5' /></span>
											<span><strong>Spinel</strong> Lv. 5</span>
										</td>
										<td>
											CON/MEN +1<br/>
											DEX/WIT +1<br/>
											STR/INT +1<br/>
											Received P. Critical Damage -7%<br/>
											Received M. Skill Critical Damage -7%<br/>
											P. Def. +150<br/>
											M. Def. +100<br/>
											With a certain chance, MP +40 when using skills.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-6'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 6' /></span>
											<span><strong>Spinel</strong> Lv. 6</span>
										</td>
										<td>
											CON/MEN +2<br/>
											DEX/WIT +1<br/>
											STR/INT +1<br/>
											Received P. Critical Damage -10%<br/>
											Received M. Skill Critical Damage -10%<br/>
											P. Def. +200<br/>
											M. Def. +150<br/>
											With a certain chance, MP +50 when using skills.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-7'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 7' /></span>
											<span><strong>Spinel</strong> Lv. 7</span>
										</td>
										<td>
											CON/MEN +2<br/>
											DEX/WIT +2<br/>
											STR/INT +2<br/>
											All received Critical Damage -15%<br/>
											P. Def. +250<br/>
											M. Def. +220<br/>
											With a certain chance, MP +60 when using skills.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-etc-level-8'><img src='/Icon/Texture/etc_bm_jewel_garnet_i00.png' alt='Spinel Lv. 8' /></span>
											<span><strong>Spinel</strong> Lv. 8</span>
										</td>
										<td>
											CON/MEN +3<br/>
											DEX/WIT +3<br/>
											STR/INT +3<br/>
											All received Critical Damage -20%<br/>
											P. Def. +350<br/>
											M. Def. +3020<br/>
											With a certain chance, MP +70 when using skills.
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_brooches_07'>Item transfer restrictions</h3>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Item</th>
							            <th>
							                <small>
							                    Trade<br />
							                    Mail
							                </small>
							            </th>
							            <th><small>Drop</small></th>
							            <th><small>Destroy</small></th>
							            <th>
							                <small>
							                    Private<br />
							                    WH
							                </small>
							            </th>
							            <th>
							                <small>
							                    Clan<br />
							                    WH
							                </small>
							            </th>
							            <th>
							                <small>
							                    Private<br />
							                    Store
							                </small>
							            </th>
							            <th><small>Store</small></th>
							            <th><small>Account</small></th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>
							               <img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i00.png' alt='Brooch Lv. 1' /> <strong>Brooch Lv. 1</strong>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i01.png' alt='Brooch Lv. 2' /> <strong>Brooch Lv. 2</strong>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i03.png' alt='Brooch Lv. 3' /> <strong>Brooch Lv. 3</strong>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i05.png' alt='Brooch Lv. 4' /> <strong>Brooch Lv. 4</strong>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <img className='icon-image' src='/Icon/Texture/etc_bm_brooch_lavianrose_i06.png' alt='Brooch Lv. 5' /> <strong>Brooch Lv. 5</strong>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <span className='icon-image panel-redlibra'><img src='/Icon/Texture/bm_2018_jewelbox_random.png' alt='Reinforced Jewel Box' /></span> <strong>Reinforced Jewel Box</strong>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <strong>Jewels</strong>
							                &nbsp;(Lv. 1-3)
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <strong>Jewels</strong>
							                (Lv. 4-8)
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>×</b></big>
							                    </big>
							                </big>
							            </td>
							            <td>
							                <big>
							                    <big>
							                        <big><b>●</b></big>
							                    </big>
							                </big>
							            </td>
							        </tr>
							    </tbody>
							</table>	
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default Brooches