import React from 'react'
import { NavLink } from 'react-router-dom'

const NotFound = props => {
	return (
		<div id='internal' className='notfound'>
			<div className='wrapper'>
				<div className='box-wrapper'>
					<div className='box-content'>
						<div className='text-center margin-small-bottom'>
							<img src='/assets/img/img_404.png' alt='' />
						</div>
						<h2 className='wiki-title text-center trn' data-trn-key='notfound'>No such page was found.</h2>
						<p className='wiki-paragraph text-center trn' data-trn-key='notfound_desc'>
							Perhaps the page that you are looking for is under development.<br/>
							Try again in few minutes or use the menu, it is at the top.
						</p>
						<div className='text-center'>
							<NavLink to='/' className='button trn' data-trn-key='notfound_button' style={{ display: 'inline-flex' }}>Go back to the main page</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotFound