import React from 'react'
import { NavLink } from 'react-router-dom'

const HeaderWiki = props => {
	return (
		<div className='box-navigator'>
		    <div className='navigation show-smartphone'>
		        <div className='menu'>
		            <span></span>
		        </div>
		        Wiki menu
		    </div>
		    <nav>
		        <div className='with-submenu'>
		            <NavLink activeClassName='active' className='trn' data-trn-key='wiki_gameplay' to='/wiki'>
		                General
		            </NavLink>
		            <ul>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/statpoints' className='trn' data-trn-key='label_charpoints'>Characteristic Points</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/sayhasgrace' className='trn' data-trn-key='label_sayhasgrace'>Sayha's Grace</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/attributes' className='trn' data-trn-key='label_attributesystem'>Attribute System</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/randomcraft' className='trn' data-trn-key='label_randomcraft'>Random Craft</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/magiclamp' className='trn' data-trn-key='label_magiclamp'>Magic Lamp</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/pets' className='trn' data-trn-key='label_pets'>Pets</NavLink>
		                </li>
		            </ul>
		        </div>

		        <div className='with-submenu'>
		            <NavLink to='/items' className='trn' data-trn-key='wiki_items'>
		                Items
		            </NavLink>
		            <ul>
		                <li>
		                	<a className='disabled trn' data-trn-key='label_weapons'>Weapons</a>
		                </li>
		                <li>
		                	<a className='disabled trn' data-trn-key='label_armors'>Armors</a>
		                </li>
		                <li>
		                	<a className='disabled trn' data-trn-key='label_accessories'>Accessories</a>
		                </li>
		                <li>
		                	<a className='disabled trn' data-trn-key='label_rareaccessories'>Rare Accessories</a>
		                </li>
		                <li className='border-top'>
		                	<NavLink activeClassName='active-submenu' to='/itemenchanting' className='trn' data-trn-key='label_itemenchanting'>Item Enchanting</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/itemblessing' className='trn' data-trn-key='label_itemblessing'>Item Blessing</NavLink>
		                </li>
		            </ul>
		        </div>

		        <div className='with-submenu'>
		            <NavLink to='/otheritems' className='trn' data-trn-key='wiki_otheritems'>
		                Other Items
		            </NavLink>
		            <ul>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/agathions' data-trn-key='label_agathions'>Agathions</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/belts' className='trn' data-trn-key='label_belts'>Belts</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/bracelets' className='trn' data-trn-key='label_bracelets'>Bracelets</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/brooches' className='trn' data-trn-key='label_brooches'>Brooches</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/cloaks' className='trn' data-trn-key='label_cloaks'>Cloaks</NavLink>
		                </li>
		                <li>
		                	<NavLink activeClassName='active-submenu' to='/dolls' className='trn' data-trn-key='label_dolls'>Dolls</NavLink>
		                </li>
		            </ul>
		        </div>

		        <div className='with-submenu'>
		            <NavLink activeClassName='active' to='/battles' className='trn' data-trn-key='wiki_battles'>
		                Battles
		            </NavLink>
		            <ul>
		                <li><NavLink activeClassName='active-submenu' to='/olympiads' className='trn' data-trn-key='label_olympiads'>Olympiads</NavLink></li>
		                <li><NavLink activeClassName='active-submenu' to='/castlesieges' className='trn' data-trn-key='label_castlesieges'>Castle Sieges</NavLink></li>
		                <li><NavLink activeClassName='active-submenu' to='/orcfortress' className='trn' data-trn-key='label_orcfortress'>Orc Fortress</NavLink></li>
		                <li><NavLink activeClassName='active-submenu' to='/transcendent' className='trn' data-trn-key='label_transcendent'>Transcendent Instance Zones</NavLink></li>
		                <li><NavLink activeClassName='active-submenu' to='/primevalgarden'>Forgotten Primeval Garden</NavLink></li>
		                <li><NavLink activeClassName='active-submenu' to='/primevalisle'>Primeval Isle</NavLink></li>
		            </ul>
		        </div>
		    </nav>
		    {/*<div>
		        <div className='search-box'>
		            <div className='placeholder trn' data-trn-key='label_searchonwiki'>Search on Wiki</div>
		            <input type='text' />
		        </div>
		    </div>*/}
		</div>
	)
}

export default HeaderWiki