import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Cloaks = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/otheritems' className='trn' data-trn-key='wiki_otheritems'>Other Items</NavLink>
						<a className='trn' data-trn-key='label_cloaks'>Cloaks</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_cloaks'>Cloaks</h2>
						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_cloaks_01'>
							<li>Cloaks are equipped in a special slot.</li>
							<li>Cloaks can be enchanted up to +10 with special scrolls for cloaks.</li>
							<li>Safe Enchant isn't available for cloaks.</li>
							<li>On a failed enchanting attempt, cloak disappears.</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle'>Cloak of Protection</h3>
						<ul className='trn' data-trn-key='label_cloaks_02'>
							<li>
								In L-Coin Store you can buy <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span>, which contains <span className='icon-image panel-sa'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Cloak of Protection' /></span> <span><strong>Cloak of Protection</strong></span> — 1 pc. and <span className='icon-image'><img src='/Icon/Texture/etc_kalie_hero_scroll.png' alt='Scroll: Enchant Cloak of Protection' /></span> <span><strong>Scroll: Enchant Cloak of Protection</strong></span> — 10 pcs.
							</li>
							<li>
								Game Assistant can exchange a Sayha's Cloak of any enchantment level for a Cloak of Protection of the same enchantment level. Exchange also requires <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> (amount depending on the enchantment level):
							</li>
						</ul>
						<table>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_level'>Level</th>
						            <th className='trn' data-trn-key='label_count'>Count</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>Sayha's Cloak +0</td>
						            <td>Not required</td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +1</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 1 pc.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +2</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 2 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +3</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 3 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +4</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 5 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +5</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 10 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +6</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 25 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +7</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 81 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +8</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 200 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +9</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 300 pcs.</span>
						            </td>
						        </tr>
						        <tr>
						            <td>Sayha's Cloak +10</td>
						            <td>
						               <span className='icon-image panel-unconfirmed'><img src='/Icon/Texture/bm_2018_sayha_cloak_back_i01.png' alt='Package: Cloak of Protection' /></span> <span><strong>Package: Cloak of Protection</strong></span> <span>— 400 pcs.</span>
						            </td>
						        </tr>
						    </tbody>
						</table>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trna' data-trn-key=''>Cloak of Protection Enchantment effects</h3>						
						<table>
						    <thead>
						        <tr>  
						            <th className='trn' data-trn-key='label_level'>Level</th>
						            <th className='trn' data-trn-key='label_description'>Description</th>
						        </tr>
						    </thead>
							<tbody>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;1)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +3%<br />
							                Max MP +3%<br />
							                Bow Resistance +1%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;1)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +4%<br />
							                Max MP +4%<br />
							                Bow Resistance +1%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;2)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +5%<br />
							                Max MP +5%<br />
							                Bow Resistance +2%<br />
							                Acquired XP +3%<br />
							                Acquired SP +3%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;3)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +6%<br />
							                Max MP +6%<br />
							                Bow Resistance +2%<br />
							                Acquired XP +5%<br />
							                Acquired SP +5%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;4)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +7%<br />
							                Max MP +7%<br />
							                Bow Resistance +3%<br />
							                Acquired XP +7%<br />
							                Acquired SP +7%<br />
							                P. Def. +2%<br />
							                M. Def. +2%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;5)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +9%<br />
							                Max MP +9%<br />
							                Bow Resistance +3%<br />
							                Acquired XP +10%<br />
							                Acquired SP +10%<br />
							                P. Def. +4%<br />
							                M. Def. +4%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;6)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +11%<br />
							                Max MP +11%<br />
							                Bow Resistance +4%<br />
							                Acquired XP +15%<br />
							                Acquired SP +15%<br />
							                P. Def. +6%<br />
							                M. Def. +6%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;7)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +13%<br />
							                Max MP +13%<br />
							                Bow Resistance +6%<br />
							                Acquired XP +20%<br />
							                Acquired SP +20%<br />
							                P. Def. +8%<br />
							                M. Def. +8%<br />
							                All Critical Damage +5%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;8)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +15%<br />
							                Max MP +15%<br />
							                Bow Resistance +9%<br />
							                Acquired XP +25%<br />
							                Acquired SP +25%<br />
							                P. Def. +10%<br />
							                M. Def. +10%<br />
							                All Critical Damage +7%<br />
							                All received Critical Rate -3%<br />
							                All received Critical Damage -3%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;9)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +18%<br />
							                Max MP +18%<br />
							                Bow Resistance +12%<br />
							                Acquired XP +30%<br />
							                Acquired SP +30%<br />
							                P. Def. +12%<br />
							                M. Def. +12%<br />
							                All Critical Damage +10%<br />
							                All received Critical Rate -5%<br />
							                All received Critical Damage -5%
							            </span>
							        </td>
							    </tr>
							    <tr>
							        <td>
							            <span className='icon-image'><img src='/Icon/texture/bm_2018_sayha_cloak_back_i01.png' alt='' /></span>
							            <span><strong>(Lv&nbsp;10)&nbsp;Cloak: Cloak of Protection</strong></span>
							        </td>
							        <td>
							            <span>
							                A long-wearing cloak protecting whole body.<br />
							                <br />
							                &lt;Effect&gt;<br />
							                Max HP +20%<br />
							                Max MP +20%<br />
							                Bow Resistance +15%<br />
							                Acquired XP +30%<br />
							                Acquired SP +30%<br />
							                P. Def. +15%<br />
							                M. Def. +15%<br />
							                All Critical Damage +15%<br />
							                All received Critical Rate -10%<br />
							                All received Critical Damage -10%
							            </span>
							        </td>
							    </tr>
							</tbody>    
						</table>	

						<p>Enchantment success rate:</p>
						<table>
							<thead>
								<tr>
									<th className='trn' data-trn-key='label_level'>Level</th>
									<th className='trn' data-trn-key='label_successrate'>Success rate</th>
								</tr>
							</thead>
							<tbody>
							    <tr>
							        <td>+0 → +1</td>
							        <td>85%</td>
							    </tr>
							    <tr>
							        <td>+1 → +2</td>
							        <td>80%</td>
							    </tr>
							    <tr>
							        <td>+2 → +3</td>
							        <td>75%</td>
							    </tr>
							    <tr>
							        <td>+3 → +4</td>
							        <td>60%</td>
							    </tr>
							    <tr>
							        <td>+4 → +5</td>
							        <td>50%</td>
							    </tr>
							    <tr>
							        <td>+5 → +6</td>
							        <td>40%</td>
							    </tr>
							    <tr>
							        <td>+6 → +7</td>
							        <td>30%</td>
							    </tr>
							    <tr>
							        <td>+7 → +8</td>
							        <td>20%</td>
							    </tr>
							    <tr>
							        <td>+8 → +9</td>
							        <td>15%</td>
							    </tr>
							    <tr>
							        <td>+9 → +10</td>
							        <td>7%</td>
							    </tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cloaks