import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const LCoinStore = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a>L2 Store</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title'>L2 Store</h2>
						<p className='wiki-paragraph trn' data-trn-key='label_l2store_desc'>L2Coin Store is a special shop where you can buy different items and services by using dollars.</p>
						<div>
							<img className='float-right' src='../assets/img/wiki/l2store_button.png' alt='' />
							<ul className='trn' data-trn-key='label_l2store_01'>
								<li>To add dollars into the game, you need to have a balance at your Web Account Panel.</li>
								<li>The dollars in-game are shared by all the characters inside the game account.</li>
								<li>Some items can be sent as a gift by any character that you need. All the items received as a gift can be claimed by game mail.</li>
								<li>Information about L2 Store, the amount of Dollars your account has, and the button of L2 Store, are available in a special window on the right side.</li>
								<li>There’s a limit on buying certain items and services in L2 Store. I the item or service is limited by once per day, the limit is reset every day at 6.30 a.m.</li>
							</ul>
						</div>

						<hr className='invisible' />
						<div className='trn' data-trn-key='label_l2store_02'>
							<h3 className='wiki-subtitle'>Adding and transfering balance</h3>
							<p>You need to add a balance to your Master Account first, then, transfer it to your in-game account for use.</p>
							<img src='../assets/img/wiki/panel_transferbalance.png' alt='' />
							<ul>
								<li>When you have balance in your control panel Master Account, click on the "Transfer to the game" button.</li>
								<li>Select the amount, game account, and click to Transfer.</li>
								<li>Your balance has successfully transferred to the game and now can you use it for buying items or services.</li>
							</ul>
						</div>
						<img src='../assets/img/wiki/l2store_balance.png' alt='' />
						
						<hr />
						<h2 className='wiki-title'>L2 Store Items</h2>
						<hr className='invisible' />
						<div className="wiki-item-details weapon">
							<div className="details">
								<div className="wiki-game-icon">
									<img src="Icon/Texture/etc_pi_gift_box_i00.png" alt="" />
								</div>
								<div className="description">
									<h2 className="wiki-title">
										Contributors Hair Accessory Pack
									</h2>
									<div className="wiki-paragraph">
										$ 5.00
									</div>
								</div>
							</div>
						</div>
						<table className='table-small'>
							<thead>
								<tr>
									<th width='250'>Item</th>
									<th>Qty</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/etc_pi_gift_box_i00.png" /></span>
										<span><strong>Contributors Hair Accessory</strong></span>
									</td>
									<td>1</td>
									<td>Double-click to obtain a Random Hair Accessory. It's only a cosmetic item, it does not provide any  status..</td>
								</tr>
							</tbody>
						</table>
						<hr className='invisible' />
						<div className="wiki-item-details weapon">
							<div className="details">
								<div className="wiki-game-icon">
									<img src="Icon/Texture/four_leaf_clover_box_i00.png" alt="" />
								</div>
								<div className="description">
									<h2 className="wiki-title">
										Great Hunt Pack
									</h2>
									<div className="wiki-paragraph">
										3 per day per account • $ 1.00
									</div>
								</div>
							</div>
						</div>
						<table className='table-small'>
							<thead>
								<tr>
									<th width='220'>Item</th>
									<th>Qty</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/scrl_of_ladies_silver_knife_01.png" /></span>
										<span><strong>Boost Attack</strong></span>
									</td>
									<td>10</td>
									<td>For 20 min. P. Atk +800, M. Atk +800, Max HP +1000, all received damage -100.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/scrl_of_ench_bracelet_lucien_01.png" /></span>
										<span><strong>Boost Defense</strong></span>
									</td>
									<td>10</td>
									<td>For 20min. P. Def +500, M. Def +500, Speed +2, all critical rate +30.
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/etc_recall_exp_scroll.png" /></span>
										<span><strong>XP Growth Scroll</strong></span>
									</td>
									<td>15</td>
									<td>For 20 min. Acquired XP/SP +30% for 20 min.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/bm_soul_of_shilen.png" /></span>
										<span><strong>Magic Fire</strong></span>
									</td>
									<td>5</td>
									<td>For 1 hour. Magic Lamp Charging Rate +100%. Required level 1-75.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/ev_magic_flame_game_wind2.png" /></span>
										<span><strong>Sayha's Gust</strong></span>
									</td>
									<td>10</td>
									<td>For 20min. Sayha's Grace Consumption +100%, Acquired XP/SP +300%. Required level 1-75.</td>
								</tr>
							</tbody>
						</table>
						<hr className='invisible' />
						<div className="wiki-item-details weapon">
							<div className="details">
								<div className="wiki-game-icon">
									<img src="Icon/Texture/four_leaf_clover_box_i00.png" alt="" />
								</div>
								<div className="description">
									<h2 className="wiki-title">
										Welcome Gift
									</h2>
									<div className="wiki-paragraph">
										1 per account • Free
									</div>
								</div>
							</div>
						</div>
						<table className='table-small'>
							<thead>
								<tr>
									<th width='220'>Item</th>
									<th>Qty</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/bm_sayha_rune.png" /></span>
										<span><strong>Sayha's XP Amulet</strong></span>
									</td>
									<td>1</td>
									<td>Available for 10 days. When in inventory, Acquired XP/SP +100%, Sayha's Grace Consumption -30%.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/accessory_necklace_of_valakas_i00.png" /></span>
										<span><strong>Valakas' Necklace</strong></span>
									</td>
									<td>1</td>
									<td>P. Atk. +5%, M. Atk. +5%, Skill Power +5%, Skill Power +5%, Skill Cooldown -4%, Max MP +30, Paralysis Resistance/ Atk. Rate +10%, Damage Reflection Resistance +5%, Cannot be enchanted.
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/br_valentine_cookie_i00.png" /></span>
										<span><strong>Sayha's Cookie</strong></span>
									</td>
									<td>200</td>
									<td>Sayha's Grace points +35000, Magic Lamp Points +50000, craft points +3250.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/bm_sayha_storm.png" /></span>
										<span><strong>Sayha's Storm Lv. 3</strong></span>
									</td>
									<td>50</td>
									<td>For 20min. Sayha's Grace Consumption +100%, Acquired XP/SP +200%.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/etc_exchange_ticket_i03.png" /></span>
										<span><strong>Soulshot Ticket</strong></span>
									</td>
									<td>200</td>
									<td>Can be exchanged by the Game Assistant for Soulshots/Spiritshots, Beast Soulshots/Spiritshots.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/etc_stone_gray_i00.png" /></span>
										<span><strong>Spirit Ore</strong></span>
									</td>
									<td>5000</td>
									<td>Material required for using certain skills.</td>
								</tr>
							</tbody>
						</table>
						<hr className='invisible' />
						<div className="wiki-item-details weapon">
							<div className="details">
								<div className="wiki-game-icon">
									<img src="Icon/Texture/four_leaf_clover_box_i00.png" alt="" />
								</div>
								<div className="description">
									<h2 className="wiki-title">
										Sayha's Support Pack
									</h2>
									<div className="wiki-paragraph">
										1 per day per account • Free
									</div>
								</div>
							</div>
						</div>
						<table style={{width: '100%'}} className='table-small'>
							<thead>
								<tr>
									<th width='220'>Item</th>
									<th>Qty</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/br_valentine_cookie_i00.png" /></span>
										<span><strong>Sayha's Cookie</strong></span>
									</td>
									<td>50</td>
									<td>Sayha's Grace points +35000, Magic Lamp Points +50000, craft points +3250.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/bm_sayha_storm.png" /></span>
										<span><strong>Sayha's Storm Lv. 3</strong></span>
									</td>
									<td>15</td>
									<td>For 20min. Sayha's Grace Consumption +100%, Acquired XP/SP +200%.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/etc_exchange_ticket_i03.png" /></span>
										<span><strong>Soulshot Ticket</strong></span>
									</td>
									<td>100</td>
									<td>Can be exchanged by the Game Assistant for Soulshots/Spiritshots, Beast Soulshots/Spiritshots.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/etc_stone_gray_i00.png" /></span>
										<span><strong>Spirit Ore</strong></span>
									</td>
									<td>1000</td>
									<td>Material required for using certain skills.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/etc_lesser_potion_red_i00.png" /></span>
										<span><strong>HP Potion</strong></span>
									</td>
									<td>3000</td>
									<td>Recover 50 HP. If HP is full, CP is restored.</td>
								</tr>
							</tbody>
						</table>
						<hr className='invisible' />
						<div className="wiki-item-details weapon">
							<div className="details">
								<div className="wiki-game-icon">
									<img src="Icon/Texture/four_leaf_clover_box_i00.png" alt="" />
								</div>
								<div className="description">
									<h2 className="wiki-title">
										Equipment Pack
									</h2>
									<div className="wiki-paragraph">
										1 per account • Min. Level 52 • Free
									</div>
								</div>
							</div>
						</div>
						<table style={{width: '100%'}} className='table-small'>
							<thead>
								<tr>
									<th width='220'>Item</th>
									<th>Qty</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/bm_royal_white_save_ticket.png" /></span>
										<span><strong>C-Grade Weapon Ticket</strong></span>
									</td>
									<td>1</td>
									<td>Can be exchanged for a C-Grade Weapon through the Game Assistant.</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image-small'><img src="Icon/Texture/bm_royal_gold_ticket_all.png" /></span>
										<span><strong>C-Grade Armor Ticket</strong></span>
									</td>
									<td>10</td>
									<td>Can be exchanged for a C-Grade Armor through the Game Assistant.</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
export default LCoinStore