import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Agathions = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/otheritems' className='trn' data-trn-key='wiki_otheritems'>Other Items</NavLink>
						<a className='trn' data-trn-key='label_agathions'>Agathions</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_agathions'>Agathions</h2>
						<img src='/assets/img/wiki/agathions.jpg' className='wiki-featured-image float-right margin-default-left' />
						<p className='wiki-paragraph trn' data-trn-key='label_agathions_01'>
							Agathions are a special type of pets. You don't need to feed them. Summoning an Agathion requires a special bracelet. Agathions can be a funny accessory or have some useful skills.
						</p>

						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_agathions_02'>
							<li>Agathions are divided into: Agathion Bracelets, which allow equipping of Agathions, and Agathions themselves.</li>
							<li>Agathion Bracelet can enhanced up to Lv. 5.</li>
							<li>You can't transfer your Agathion to another player.</li>
							<li>The amount of unlocked additional Agathion slots depends on the level of the Agathion Bracelet. Available slots are highlighted and unavailable slots are darkened.</li>
						</ul>

						<hr className='invisible' />
						<div className='trn' data-trn-key='label_agathions_03'>
							<h3 className='wiki-subtitle'>How to acquire and enhance Agathion Bracelets</h3>
							<p>
								<span className='icon-image panel-step-level-1'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 1' /></span> <strong>Agathion Bracelet Lv. 1</strong> can be bought from Game Assistant, who can be found in any town near Warehouse or using the 'Game Assist' button in the side-panel.
							</p>
						</div>
						<table>
							<thead>
								<tr>
									<th>Item</th>
									<th className='trn' data-trn-key='label_price'>Price</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image panel-step-level-1'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 1' /></span> <span><strong>Agathion Bracelet Lv. 1</strong></span>
									</td>
									<td>
										<span className='icon-image'><img src='/Icon/Texture/etc_adena_i00.png' alt='Adena' /></span>
							        	<span>500,000 Adena</span>
									</td>
								</tr>
							</tbody>
						</table>

						<hr className='invisible' />
						<div className='trn' data-trn-key='label_agathions_04'>
							<h3 className='wiki-subtitle'>How to obtain</h3>
							<p><strong>Spirit Agathions</strong></p>
							<ul>
								<li>During promotions in <strong>L-Coin Store</strong> you can buy Agathion packages which contain an appropriate Agathion and <span className='icon-image panel-cursed'><img src='/Icon/Texture/star_scrl_of_ench_ag.png' alt='Scroll: Enchant Agathion' /></span> <strong>Scroll: Enchant Agathion</strong> — 10 pcs. for it.</li>
								<li>Spirit Agathions can also be obtained during events</li><li>All Agathions from L-Coin Store can be enchanted to +10 using <span className='icon-image panel-cursed'><img src='/Icon/Texture/star_scrl_of_ench_ag.png' alt='Scroll: Enchant Agathion' /></span> <strong>Scroll: Enchant Agathion</strong>, Safe Enchant up to +3.</li>
								<li>On a failed enchanting attempt Agathion will disappear.</li>
								<li>All Agathions have the same enchantment success rate.</li>
								<li>Starting with level +7, Agathions can be transferred in the account using Game Assistant.</li>
								<li><span className='icon-image panel-cursed'><img src='/Icon/Texture/star_scrl_of_ench_ag.png' alt='Scroll: Enchant Agathion' /></span> <strong>Scroll: Enchant Agathion</strong> also works with Zodiac Agathions.</li>
								<li>Enchantment Success Rate:</li>					
							</ul>
						</div>
						<table>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_enchantment'>Enchantment Lv.</th>
						            <th className='trn' data-trn-key='label_successrate'>Success Rate</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>+0 → +1</td>
						            <td>100%</td>
						        </tr>
						        <tr>
						            <td>+1 → +2</td>
						            <td>100%</td>
						        </tr>
						        <tr>
						            <td>+2 → +3</td>
						            <td>100%</td>
						        </tr>
						        <tr>
						            <td>+3 → +4</td>
						            <td>65%</td>
						        </tr>
						        <tr>
						            <td>+4 → +5</td>
						            <td>50%</td>
						        </tr>
						        <tr>
						            <td>+5 → +6</td>
						            <td>35%</td>
						        </tr>
						        <tr>
						            <td>+6 → +7</td>
						            <td>24%</td>
						        </tr>
						        <tr>
						            <td>+7 → +8</td>
						            <td>18%</td>
						        </tr>
						        <tr>
						            <td>+8 → +9</td>
						            <td>14%</td>
						        </tr>
						        <tr>
						            <td>+9 → +10</td>
						            <td>8%</td>
						        </tr>
						    </tbody>
						</table>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_effect'>Effects</h3>

						<div className='table-tabs'>
							<span rel='agathion-ignis' className='active'>Ignis</span>
							<span rel='agathion-nebula'>Nebula</span>
							<span rel='agathion-procella'>Procella</span>
							<span rel='agathion-petram'>Petram</span>
							<span rel='agathion-joy'>Joy</span>
						</div>
						<div className='table-content'>
							<table style={{minWidth: 800}} id='agathion-ignis'>
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +1%<br/>
											M. Atk. +1%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +1 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +2%<br/>
											M. Atk. +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +2 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +3%<br/>
											M. Atk. +3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +3 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +4%<br/>
											M. Atk. +4%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +4 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +5%<br/>
											M. Atk. +5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +5 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +6%<br/>
											M. Atk. +6%<br/>
											Hold Atk. Rate +2%<br/>
											Sleep Atk. Rate +2%<br/>
											Paralysis Atk. Rate +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +6 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +7%<br/>
											M. Atk. +7%<br/>
											Hold Atk. Rate +5%<br/>
											Sleep Atk. Rate +5%<br/>
											Paralysis Atk. Rate +5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +7 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +9%<br/>
											M. Atk. +9%<br/>
											Hold Atk. Rate +8%<br/>
											Sleep Atk. Rate +8%<br/>
											Paralysis Atk. Rate +8%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +8 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +11%<br/>
											M. Atk. +11%<br/>
											Hold Atk. Rate +14%<br/>
											Sleep Atk. Rate +14%<br/>
											Paralysis Atk. Rate +14%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +9 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +13%<br/>
											M. Atk. +13%<br/>
											Hold Atk. Rate +22%<br/>
											Sleep Atk. Rate +22%<br/>
											Paralysis Atk. Rate +22%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_fire_1.png' alt='Agathion: Agathion Ignis' /></span>
											<span><strong>Agathion: +10 Agathion Ignis</strong></span>
										</td>
										<td>
											P. Atk. +15%<br/>
											M. Atk. +15%<br/>
											Hold Atk. Rate +30%<br/>
											Sleep Atk. Rate +30%<br/>
											Paralysis Atk. Rate +30%
										</td>
									</tr>
								</tbody>
							</table>
							<table style={{minWidth: 800}} id='agathion-joy'>
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +1%<br/>
											With Sayha's Grace, acquired SP +1%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +1 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +2%<br/>
											With Sayha's Grace, acquired SP +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +2 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +3%<br/>
											With Sayha's Grace, acquired SP +3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +3 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +4%<br/>
											With Sayha's Grace, acquired SP +4%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +4 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +6%<br/>
											With Sayha's Grace, acquired SP +6%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +5 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +9%<br/>
											With Sayha's Grace, acquired SP +1%<br/>
											Acquired XP +1%<br/>
											Acquired SP +1%<br/>
											Max HP +9%<br/>
											Max MP +1%<br/>
											Max CP +1%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +6 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +9%<br/>
											With Sayha's Grace, acquired SP +2%<br/>
											Acquired XP +2%<br/>
											Acquired SP +2%<br/>
											Max HP +9%<br/>
											Max MP +2%<br/>
											Max CP +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +7 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +12%<br/>
											With Sayha's Grace, acquired SP +5%<br/>
											Acquired XP +3%<br/>
											Acquired SP +3%<br/>
											Max HP +12%<br/>
											Max MP +5%<br/>
											Max CP +5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +8 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +16%<br/>
											With Sayha's Grace, acquired SP +8%<br/>
											Acquired XP +4%<br/>
											Acquired SP +4%<br/>
											Max HP +16%<br/>
											Max MP +8%<br/>
											Max CP +8%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +9 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +20%<br/>
											With Sayha's Grace, acquired SP +11%<br/>
											Acquired XP +5%<br/>
											Acquired SP +5%<br/>
											Max HP +20%<br/>
											Max MP +11%<br/>
											Max CP +11%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/pleasure_agathion_i00.png' alt='Agathion: Agathion Joy' /></span>
											<span><strong>Agathion: +10 Agathion Joy</strong></span>
										</td>
										<td>
											With Sayha's Grace, acquired XP +30%<br/>
											With Sayha's Grace, acquired SP +15%<br/>
											Acquired XP +6%<br/>
											Acquired SP +6%<br/>
											Max HP +30%<br/>
											Max MP +15%<br/>
											Max CP +15%
										</td>
									</tr>
								</tbody>
							</table>
							<table style={{minWidth: 800}} id='agathion-petram'>
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +1%<br/>
											M. Def. +1%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +1 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +2%<br/>
											M. Def. +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +2 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +3%<br/>
											M. Def. +3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +3 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +4%<br/>
											M. Def. +4%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +4 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +5%<br/>
											M. Def. +5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +5 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +6%<br/>
											M. Def. +6%<br/>
											Stun Atk. Rate +2%<br/>
											Silence Atk. Rate +2%<br/>
											Fear Atk. Rate +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +6 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +7%<br/>
											M. Def. +7%<br/>
											Stun Atk. Rate +5%<br/>
											Silence Atk. Rate +5%<br/>
											Fear Atk. Rate +5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +7 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +9%<br/>
											M. Def. +9%<br/>
											Stun Atk. Rate +8%<br/>
											Silence Atk. Rate +8%<br/>
											Fear Atk. Rate +8%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +8 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +11%<br/>
											M. Def. +11%<br/>
											Stun Atk. Rate +14%<br/>
											Silence Atk. Rate +14%<br/>
											Fear Atk. Rate +14%<br/>
											Speed +2
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +9 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +13%<br/>
											M. Def. +13%<br/>
											Stun Atk. Rate +22%<br/>
											Silence Atk. Rate +22%<br/>
											Fear Atk. Rate +22%<br/>
											Speed +3
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_earth_1.png' alt='Agathion: Agathion Petram' /></span>
											<span><strong>Agathion: +10 Agathion Petram</strong></span>
										</td>
										<td>
											P. Def. +15%<br/>
											M. Def. +15%<br/>
											Stun Atk. Rate +30%<br/>
											Silence Atk. Rate +30%<br/>
											Fear Atk. Rate +30%<br/>
											Speed +4
										</td>
									</tr>
								</tbody>
							</table>
							<table style={{minWidth: 800}} id='agathion-procella'>
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +2<br/>
											Casting Spd. +2
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +1 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +4<br/>
											Casting Spd. +4
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +2 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +7<br/>
											Casting Spd. +7
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +3 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +10<br/>
											Casting Spd. +10
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +4 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +15<br/>
											Casting Spd. +15
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +5 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +20<br/>
											Casting Spd. +20<br/>
											MP Consumption -1%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +6 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +30<br/>
											Casting Spd. +30<br/>
											MP Consumption -2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +7 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +50<br/>
											Casting Spd. +50<br/>
											MP Consumption -4%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +8 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +80<br/>
											Casting Spd. +80<br/>
											MP Consumption -6%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +9 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +110<br/>
											Casting Spd. +110<br/>
											MP Consumption -8%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_wind_1.png' alt='Agathion: Agathion Procella' /></span>
											<span><strong>Agathion: +10 Agathion Procella</strong></span>
										</td>
										<td>
											Atk. Spd. +150<br/>
											Casting Spd. +150<br/>
											MP Consumption -12%
										</td>
									</tr>
								</tbody>
							</table>
							<table style={{minWidth: 800}} id='agathion-nebula'>
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +1%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +1 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +2 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +3 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +4%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +4 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +5 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +7%<br/>
											Skill Cooldown -1%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +6 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +9%<br/>
											Skill Cooldown -2%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +7 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +12%<br/>
											Skill Cooldown -3%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +8 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +15%<br/>
											Skill Cooldown -5%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +9 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +18%<br/>
											Skill Cooldown -7%
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'><img src='/Icon/Texture/agathion_water_1.png' alt='Agathion: Agathion Nebula' /></span>
											<span><strong>Agathion: +10 Agathion Nebula</strong></span>
										</td>
										<td>
											All Critical Rate +22%<br/>
											Skill Cooldown -10%
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Agathions