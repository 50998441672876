import React from 'react'
import ReactDOM from 'react-dom'
import api from '../api'

class News extends React.Component {

	state = {
		news: [],
		updates: []
	}

	async componentDidMount() {
		const responseNews = await api.get('/index.php?/forums/topics&forums=2&key=42fe934c2a519ceb944e6a3c10ab100b&sortDir=desc&hidden=0')
		const responseUpdates = await api.get('/index.php?/forums/topics&forums=3&key=42fe934c2a519ceb944e6a3c10ab100b&sortDir=desc&hidden=0')

		this.setState({ news: responseNews.data.results })
		this.setState({ updates: responseUpdates.data.results })

	}

	render() {
		const { news } = this.state
		const { updates } = this.state

		return (
			<div id='internal'>
				<div className='wrapper'>
					<h2 className='internal-title trn' data-trn-key='news'>
						News & Updates
					</h2>
					<div className='box-wrapper'>
						<div className='box-navigator'>
							<nav className='tabs'>
								<div>
									<span rel='news' className='trn active' data-trn-key='internal_news'>
										News
									</span>
								</div>
								<div>
									<span rel='updates' className='trn' data-trn-key='internal_updates'>
										Updates
									</span>
								</div>
							</nav>
						</div>

						<div className='box-content'>
							<div rel='news' className='content-news filter-news active'>
								{news.map(newItem => (
									<a key={newItem.id} href={ newItem.url }>	
										<div className='thumb'>
											<img src='' alt='' />
										</div>
										<span className={ newItem.prefix }>
											{ newItem.prefix }
										</span>
										<h3>{ newItem.title }</h3>
										<small>
											{ new Date(newItem.firstPost.date).toLocaleDateString()
											}
										</small>
									</a>
								))} 
							</div>
							<div rel='updates' className='content-updates filter-updates'>
								{updates.map(updateItem => (
									<a key={updateItem.id} href={ updateItem.url }>	
										<h3>{ updateItem.title }</h3>
										<span>
											{ new Date(updateItem.firstPost.date).toLocaleDateString()
												} <img src='/assets/img/ic_arrow_right.svg' alt='' />
										</span>
									</a>
								))} 
							</div>
						</div>
					</div>
				</div>
			</div>
		)	
	}
}
export { News }