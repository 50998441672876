import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const CastleSieges = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/battles' className='trn' data-trn-key='wiki_battles'>Battles</NavLink>
						<a className='trn' data-trn-key='label_castlesieges'>Castle Sieges</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_castlesieges'>Castle Sieges</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='/assets/img/wiki/castlesieges_header.jpg' alt='' />
						</div>

						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_castlesieges_01'>
							<li><b>Castle Sieges </b>are a part of PvP content for clans of Lineage 2 Essence. A clan owning a castle gets various bonuses.</li>
						    <li>The siege can take place either in <strong>Giran Castle</strong> or in <strong>Goddard Castle</strong>. A clan can own only one castle.</li>
						    <li>
						        Sieges take place weekly on Sundays. The siege preparations begin at 16:00 CET, and the siege itself takes place from 18:00 till 19:00 CET.
						        <ul>
						            <li>Clans are free to apply for both sieges, but after an intermediate victory in one of the castles, the siege registration for another is canceled.</li>
						            <li>Mercenaries can apply only for one siege.</li>
						        </ul>
						    </li>
						    <li>Not only clan members but characters who have not joined any clan can also participate in the castle sieges.</li>
						</ul>
						<hr className='invisible small' />
						<p><strong>Siege stages</strong></p>
						<img className='float-right wiki-featured-image' src='/assets/img/wiki/sieges_time.png' alt='' />
						<ul className='trn' data-trn-key='label_castlesieges_02'>
						    <li>
						        There are two siege stages. The first one is preparations when characters are applying for the siege. The second one is the siege itself.
						        <ul>
						            <li>During the preparations and the siege, you can see a timer near the map. It shows the remaining time of the stages.</li>
						        </ul>
						    </li>
						    <li>
						        During the preparation stage, you can apply either for attacking a castle or for defending it. Also, you can recruit mercenaries, or be recruited.
						        <ul>
						            <li>The application form can be seen in the siege info window that opens by clicking on the siege icon shown during the preparations.</li>
						            <li>The siege info window allows you to use free teleport to the left, central, or right side of the castle during the battle.</li>
						        </ul>
						    </li>
						    <li>A clan can apply for the siege only if it&nbsp;has reached Lv. 3&nbsp;or higher.</li>
						    <li>There is no death penalty decreasing XP if your character dies in a combat zone.</li>
						    <li>There is a special resurrection zone for those who have died during the siege, except castle defenders.</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_participating'>Participating</h3>	

						<div className='trn' data-trn-key='label_castlesieges_03'>	
							<p>
								There is no need for registration if you want to just walk across the siege combat zones without joining the battle.
							</p>		

							<hr className='invisible small' />
							<p><strong>Attackers / Defenders</strong></p>	
							<ul>
							    <li>The registration takes place during the siege preparation stage.</li>
							    <li>A clan owning the castle is automatically registered on the defending side. Other clans planning to fight as defenders have to register during the preparation stage.</li>
							</ul>
						</div>

						<hr className='invisible small' />
						<p><strong>Mercenaries</strong></p>
						<ul className='trn' data-trn-key='label_castlesieges_04'>
						    <li>You can take part in the siege as a mercenary even if you are not in any clan or your clan isn't registered for the siege.</li>
						    <li>Registration for mercenaries is available only when clans have joined the attackers' camp or the defenders' camp and have placed requests of recruitment.</li>
						    <li>When recruitment is announced, a character can choose a clan to join for the siege as a mercenary. To do so click on the List of Mercenaries button in the siege info window and look for the clan you like.</li>
						    <li>
						        Requirements for mercenaries:
						        <ul>
						            <li>a clan member whose clan is not registered for the siege or a character who is not in any clan;</li>
						            <li>your clan must not be at war with the clan you wish to join for the siege.</li>
						        </ul>
						    </li>
						    <li>
						        As soon as a mercenary application is sent:
						        <ul>
						            <li>the character's name is changed to '***-a number'. For example, ***-001 or ***-031;</li>
						            <li>the character's clan crest is switched for a while;</li>
						            <li>the clan/alliance/command/private channels are blocked;</li>
						        </ul>
						    </li>
						    <li>
						        For participating as a mercenary, you will be rewarded with adena from the castle treasury.
						        <ul>
						            <li>You are rewarded only if your clan has won the siege.</li>
						            <li>You are rewarded only if you have taken an active part in the siege.</li>
						        </ul>
						    </li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_siegerules'>Siege Rules</h3>	
						<ul className='trn' data-trn-key='label_castlesieges_04'>
						    <li>Attackers and defenders fight each other. Allied clans can join different sides.</li>
						    <li>
						        At the beginning of the siege, all castle gates become closed and can be damaged.
						    </li>
						    <li>During the siege, the castle and nearby area are turned into a combat zone. </li>
						    <li>There is a special resurrection zone for those who have died during the siege, except castle defenders.</li>
						</ul>

						<hr className='invisible small' />
						<div className='trn' data-trn-key='label_castlesieges_05'>
							<p><strong>Capturig the Castle</strong></p>
							<ul>
							    <li>During the first siege on a server all castles belong to NPC.</li>
							    <li>If no clan manages to capture the castle, its ownership doesn't change.</li>
							    <li>
							        Victory is claimed by the clan whose leader is the last one who has used Seal of Ruler on Giran Holy Artifact (Giran Castle) or Goddard Holy Artifact (Goddard Castle). The seal can be used once per 2 minutes, the process lasts for
							        15 seconds.
							        <ul>
							            <li>Giran Holy Artifact appears in the castle courtyard on the special pedestal. To make Giran Holy Artifact appear attackers have to destroy Giran Relic&nbsp;in the chamber behind the throne room.</li>
							            <li>
							                Goddard Holy Artifacts appear in two places: on the right and left walls. Only one of them has to be activated with <img src='./Icon/Texture/skill0246.png' alt='' className='icon-image-small' /> <strong>Seal of Ruler</strong>the victory. To make Goddard Holy Artifact appear attackers have to destroy one of Goddard Relics in the castle rooms.
							            </li>
							        </ul>
							    </li>
							</ul>
						</div>

						<hr className='invisible small' />
						<div className='trn' data-trn-key='label_castlesieges_06'>
							<p><strong>PvP Rules</strong></p>
							<ul>
								<li>When the battle begins, all registered characters are split into two sides - attackers and defenders. The attacking clans can assault each other as well as each other's headquarters. The same way there cannot be any alliances among the defenders.</li>
								<li>In the combat zone, you do not need to press Ctrl to attack enemy or neutral characters. You do not get PK status for killing them.</li>
							</ul>
						</div>

						<hr className='invisible small' />
						<div className='trn' data-trn-key='label_castlesieges_07'>
							<p><strong>Siege Headquarters</strong></p>
							<ul>
							    <li>
							        When a clan has reached Lv. 3, its leader gets the <img className='icon-image-small' src='./Icon/Texture/skill0247.png' /> <strong>Build Headquarters</strong> skill. The headquarters is a resurrection place for the clan members in the combat zone. When you are near the headquarters, your HP and MP are regenerating faster.
							    </li>
							    <li>
							        The skill can be used only by a leader of an attacking clan. The headquarters can be built in the combat zone only. The building requires <img src='./Icon/Texture/etc_crystal_blue_i00.png' alt='' className='icon-image-small' /> <strong>Crystal (D-Grade)</strong> — 500 pcs.
							    </li>
							    <li>The headquarters has its own HP, so it can be damaged and even destroyed by enemies. In that case, the clan leader can rebuild it as many times as necessary.</li>
							    <li>Dead characters of attacking clans are resurrected near the siege headquarters. It also increases HP and MP regeneration of the clan members in its range.</li>
							</ul>
						</div>

						<hr className='invisible small' />
						<div className='trn' data-trn-key='label_castlesieges_08'>
							<p><strong>Intermediate Victory</strong></p>
							<ul>
							    <li>
							        Intermediate victory is a change of ownership during the siege.
							        <ul>
							            <li>When the intermediate victory happens, the clan that captured the castle becomes the only defender, while all other clans registered for the siege are turned into attackers.</li>
							            <li>The headquarters of the new castle owner disappears.</li>
							        </ul>
							    </li>
							    <li>After the intermediate victory, the castle gates restore 50% of HP and become closed again.</li>
							    <li>The clan that lost the castle comes over to the attacking side and can build its headquarters in the combat zone.</li>
							</ul>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_owningcastles'>Owning the Castles</h3>	
						<div className='trn' data-trn-key='label_castlesieges_09'>
							<ul>
							    <li>A castle owner gains control of taxes and political activity in the conquered area.</li>
							    <li>A clan that captured the castle can use its warehouse and teleport to different castle parts.</li>
							    <li>
							        By default, only the Castle Lord can use management functions. However, they can temporarily delegate authority to the clan or alliance members to use certain castle functions. It can be done through the rights delegation system in
							        the Clan Actions window.
							    </li>
							    <li>Taxes are collected by Chamberlain who delivers them to the castle owner once a week (on Mondays at 10:00 CET).</li>
							</ul>
							<p><strong>A leader of the clan owning a castle can set a tax rate from 0 to 10% through Chamberlain.</strong></p>
						</div>
					</div>
				</div>
			</div>
		</div>		
	)
}

export default CastleSieges