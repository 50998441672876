import React from 'react';
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const SayhasGrace = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a>Sayha`s Grace</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title'>Sayha`s Grace</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='../assets/img/wiki/sayhas_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_sayhas_01'>
							<li>Sayha`s Grace is a system that allows to receive additional XP, items and adena when hunting monsters.</li>
							<li>
								Sayha`s Grace bar is shown on the character selection screen and under the world map in the game.
								<ul>
									<li>Sayha`s Grace points are separate for each character attached to an account.</li>
								</ul>
							</li>
						    <li>Acquired XP and SP, item drop chance and amount of received adena are higher while Sayha`s Grace is active.</li>
						    <li>When you’re hunting monsters, Sayha`s Grace is consumed (the more XP you acquire, the faster Sayha`s Grace is consumed).</li>
						    <li>Sayha`s Grace can be replenished by using special items which instantly add Sayha`s Grace points.</li>
						    <li>There are also items that affect Sayha`s Grace Consumption.</li>
						    <li>When a character is created, its Sayha`s Grace points are at 35,000 (1 bar).</li>
						    <li>The maximum amount of Sayha`s Grace is a 100 bars with 3,500,000 points, which is displayed in the info window as “Sayha`s Grace x25(MAX)”.</li>
						    <li>
						        At 6:30 AM Sayha`s Grace is automatically replenished for 35,000 points, up to the maximum amount of 140,000 points (4 bars).
						        <ul>
						            <li>Automatic replenishing doesn’t happen, if 4 bars are already filled.</li>
						        </ul>
						    </li>
						    <li>Sayha`s Grace being on or off is shown on screen in the side panel and in the party interface:</li>
						</ul>
						
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Sayha`s Grace on</th>
							            <th>Sayha`s Grace off</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>Character:&nbsp; &nbsp;<img src='../assets/img/wiki/sg_char_on.jpg' alt='' width='50' height='47' /></td>
							            <td>Character:&nbsp; &nbsp;<img src='../assets/img/wiki/sg_char_off.jpg' alt='' width='50' height='47' /></td>
							        </tr>
							        <tr>
							            <td>Party:&nbsp;&nbsp;<img src='../assets/img/wiki/sg_party_on.jpg' alt='' width='158' height='56' /></td>
							            <td>Party:&nbsp;&nbsp;<img src='../assets/img/wiki/sg_party_off.jpg' alt='' width='158' height='56' /></td>
							        </tr>
							    </tbody>
							</table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle'>Sayha`s Grace Bonus</h3>
						<p><strong>Bonus XP / SP:</strong></p>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Using buff or item</th>
							            <th>Bonus</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>No Sayha`s Grace, no Limited Sayha`s Blessing</td>
							            <td>-</td>
							        </tr>
							        <tr>
							            <td>Using Limited Sayha`s Blessing</td>
							            <td>200%</td>
							        </tr>
							        <tr>
							            <td>Sayha`s Grace is on</td>
							            <td>300%</td>
							        </tr>
							    </tbody>
							</table>
						</div>
						<p><strong>Amount of adena dropped</strong><br/>(Example with 1,000 adena):</p>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th>Using buff or item</th>
							            <th>Bonus</th>
							            <th>The resulting amount of adena</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>Sayha`s Grace is off</td>
							            <td>5%</td>
							            <td>50</td>
							        </tr>
							        <tr>
							            <td>Using Limited Sayha`s Blessing</td>
							            <td>30%</td>
							            <td>300</td>
							        </tr>
							        <tr>
							            <td>Sayha`s Grace is on</td>
							            <td>100%</td>
							            <td>1000</td>
							        </tr>
							    </tbody>
							</table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_itemsmaintinsayha'>Items that maintain Sayha`s Grace</h3>
						<ul className='trn' data-trn-key='label_sayhas_02'>
						    <li>
						        There are special items:&nbsp;
						        <img src='./Icon/Texture/g_bm_limited_sayha_01.png' alt='Limited Sayha`s Blessing' className='icon-image-small' /> <strong>Limited Sayha`s Blessing</strong> (1 d.) and <img src='./Icon/Texture/g_bm_limited_sayha_01.png' alt='Limited Sayha`s Blessing' className='icon-image-small' /> <strong>Limited Sayha`s Blessing</strong> (30 d.), which can be bought in <strong>L-Coin Store</strong>.
						    </li>
						    <li>
						        Limited Sayha`s Blessing isn’t consumed gradually like Sayha`s Grace, but stays on for 1 or 30 days.
						        <ul styles={{ listStyleType: 'circle' }}>
						            <li>If you use Limited Sayha`s Blessing, while Sayha`s Grace is on, Sayha`s Grace will be consumed first, and then Limited Sayha’s Blessing will start working.</li>
						        </ul>
						    </li>
						</ul>
						<div className='table-scrolling'>
							<table>
								<thead>
									<tr>
										<th width='250'>Item</th>
										<th styles={{ minWidth: 250 }} className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/g_bm_limited_sayha_01.png' alt='Limited Sayha`s Blessing' className='icon-image' />
											</span>
											<span>
												<strong>Limited Sayha`s Blessing</strong><br/>(1 d.)
											</span>
										</td>
										<td>
											Double-click to get the Limited Sayha`s effect for 1 d. If Sayha`s Grace is inactive, Acquired XP/ SP +200%, adena's drop rate penalty is decreased.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/g_bm_limited_sayha_01.png' alt='Limited Sayha`s Blessing' className='icon-image' />
											</span>
											<span>
												<strong>Limited Sayha`s Blessing</strong><br/>(30 d.)
											</span>
										</td>
										<td>
											Double-click to get the Limited Sayha`s effect for 30 d. If Sayha`s Grace is inactive, Acquired XP/ SP +200%, adena's drop rate penalty is decreased.
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle'>Sayha’s Luck</h3>
						<ul className='trn' data-trn-key='label_sayhas_03'>
						    <li>
						        When you’re using items that replenish Sayha`s Grace points, you have a chance to activate the “Sayha’s Luck” effect. Sayha`s Grace icon will start glowing, and there’ll be a message in system chat, and it will maintain Sayha’s
						        Grace for 1 hour.
						    </li>
						    <li>The Sayha`s Grace maintaining effect can be prolonged up to 4 hours a day (the counter is reset at 6:30 AM).</li>
						    <li>
						        When using an item <img src='./Icon/Texture/etc_wind_potion_i00.png' alt='Sayha`s Blessing' className='icon-image-small' /> <strong>Sayha`s Blessing</strong> the chance to receive Sayha’s Luck effect is twice higher than with other items.
						    </li><br/>
							<li>Effects and items that affect Sayha`s Grace Consumption:</li>
						</ul>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th width='200'>Item/Skill</th>
							            <th styles={{ minWidth: 250 }} className='trn' data-trn-key='label_effect'>Effect</th>
							            <th className='trn' data-trn-key='label_howtoobtain'>How to obtain</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>
							            	<span className='icon-image'>
												<img src='./Icon/Texture/ev_magic_flame_game_wind2.png' alt='Sayha Gust' className='icon-image' />
											</span>
											<span>
												<strong>Sayha Gust</strong>
											</span>
							            </td>
							            <td>
							                For characters of level 75 and lower. For 20 min., Sayha`s Grace Consumption +100%, Acquired XP/ SP +300%. The effect remains after death. Cooldown: 1 min. Available if Sayha`s Grace bar is filled by one unit or more.
							            </td>
							            <td>
							                <strong>Daily Missions</strong>,<br />
							                <strong>Quests</strong>
							            </td>
							        </tr>
							        <tr>
							            <td>
							            	<span className='icon-image panel-etc-material-level-3'>
												<img src='./Icon/Texture/bm_sayha_storm.png' alt='Sayha Storm Lv. 3' className='icon-image' />
											</span>
											<span>
												<strong>Sayha Storm Lv. 3</strong>
											</span>
										</td>
							            <td>
							                Sayha`s Grace Consumption +100%, Acquired XP/ SP +200%. The effect remains even after the character dies. Applied Time: 20 min. Cooldown: 1 min. Available if Sayha`s Grace bar is filled by one unit or more.
							            </td>
							            <td>
							                <strong>L-Coin Store</strong>,<br />
							                Loot from <strong>Bosses</strong>&nbsp;
							            </td>
							        </tr>
							        <tr>
							            <td>
							            	<span className='icon-image'>
												<img src='./Icon/Texture/high_stone_i01.png' alt='Sayha`s Grace' className='icon-image' />
											</span>
											<span>
												<strong>Sayha`s Grace</strong>
											</span>
										</td>
							            <td>Sayha`s Grace Consumption -10% for 20 min.</td>
							            <td>Loot from <strong>Bosses</strong>.</td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image panel-etc-level-4'>
												<img src='./Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone' />
											</span>
											<span>
												<strong>Moonstone</strong><br/>(Lv. 4)
											</span>
										</td>
							            <td>While Sayha`s Grace is active, Acquired XP/ SP +8%, Sayha`s Grace Consumption -10%. Effects of two identical jewels do not stack, the higher-level jewel takes precedence.</td>
							            <td><strong>L-Coin Store</strong></td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image panel-etc-level-5'>
												<img src='./Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone' />
											</span>
											<span>
												<strong>Moonstone</strong><br/>(Lv. 5)
											</span>
										</td>
							            <td>While Sayha`s Grace is active, Acquired XP/ SP +15%, Sayha`s Grace Consumption -30%. Effects of two identical jewels do not stack, the higher-level jewel takes precedence.</td>
							            <td><strong>L-Coin Store</strong></td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image panel-etc-level-6'>
												<img src='./Icon/Texture/etc_bm_jewel_moonstone_i00.png' alt='Moonstone' />
											</span>
											<span>
												<strong>Moonstone</strong><br/>(Lv. 6)
											</span>
										</td>
							            <td>While Sayha`s Grace is active, Acquired XP/ SP +18%, Sayha`s Grace Consumption -30%. Effects of two identical jewels do not stack, the higher-level jewel takes precedence.</td>
							            <td><strong>L-Coin Store</strong></td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/skill19014.png' alt='Clan Exuberance' className='icon-image' />
											</span>
											<span>
												<strong>Clan Exuberance</strong><br/>(Lv. 1)
											</span>
										</td>
							            <td>Clan members' HP/ MP/ CP Recovery Bonus +4%, Aquired XP/ SP +5%, received PVE damage -3%, Sayha Grace Consumption -5%.</td>
							            <td><strong>Clan Skills - Clan Arena</strong></td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/skill19014.png' alt='Clan Exuberance' className='icon-image' />
											</span>
											<span>
												<strong>Clan Exuberance</strong><br/>(Lv. 2)
											</span>
										</td>
							            <td>Clan members' HP/ MP/ CP Recovery Bonus +8%, Aquired XP/ SP +5%, received PVE damage -5%, Sayha Grace Consumption -8%.</td>
							            <td><strong>Clan Skills - Clan Arena</strong></td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/skill19014.png' alt='Clan Exuberance' className='icon-image' />
											</span>
											<span>
												<strong>Clan Exuberance</strong><br/>(Lv. 3)
											</span>
										</td>
							            <td>Clan members' HP/ MP/ CP Recovery Bonus +12%, Aquired XP/ SP +10%, received PVE damage -7%, Sayha Grace Consumption -11%.</td>
							            <td><strong>Clan Skills - Clan Arena</strong></td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/skill19014.png' alt='Clan Exuberance' className='icon-image' />
											</span>
											<span>
												<strong>Clan Exuberance</strong><br/>(Lv. 4)
											</span>
										</td>
							            <td>Clan members' HP/ MP/ CP Recovery Bonus +20%, Aquired XP/ SP +15%, received PVE damage -10%, Sayha Grace Consumption -15%.</td>
							            <td><strong>Clan Skills - Clan Arena</strong></td>
							        </tr>
							        <tr>
							            <td>
											<span className='icon-image'>
												<img src='./Icon/Texture/skill19014.png' alt='Clan Exuberance' className='icon-image' />
											</span>
											<span>
												<strong>Clan Exuberance</strong><br/>(Lv. 5)
											</span>
										</td>
							            <td>Clan members' HP/ MP/ CP Recovery Bonus +30%, Acquired XP/ SP +20%, Mental/ Stun/ Paralysis Atk. Rate +5%, Received PvE Damage -10%, Sayha`s Grace Consumption -15%.</td>
							            <td><strong>Clan Skills - Clan Arena</strong></td>
							        </tr>
							    </tbody>
							</table>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default SayhasGrace