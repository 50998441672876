import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Dolls = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/otheritems' className='trn' data-trn-key='wiki_otheritems'>Other Items</NavLink>
						<a className='trn' data-trn-key='label_dolls'>Dolls</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_dolls'>Dolls</h2>
						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul>
						    <li><b>Dolls</b>&nbsp; are special items, which grant various buffs when they are in a character's inventory.</li>
						    <li>
						        Dolls can be:
						        <ul>
						            <li>of three types: Queen Ant Doll, Baium Doll, Orfen Doll.</li>
						            <li>of three levels: 1, 2, 3, the higher the level - the stronger the Doll's buff.</li>
						            <li>of two kinds: time-limited and not time-limited. Time-limited Dolls will disappear from character's inventory after a certain time.</li>
						        </ul>
						    </li>
						    <li>
						        Levelling Dolls up requires Compounding.&nbsp;
						        <ul>
						            <li>Time-limited Dolls can only be of one level and can't be used in Compounding.</li>
						        </ul>
						    </li>
						    <li>Dolls can be obtained as a part of an event in <strong>L-Coin Store</strong> on Special craft page.</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trna' data-trn-key=''>Compounding</h3>
						<ul>
						    <li>
						        Only dolls of the same type and level can be used in Compounding. Example:
						        <ul>
						            <li>You can't use Lv. 1 and Lv. 2 Dolls, Baium Doll and Orfen Doll, Baium Doll 7d. and Baium Doll Lv. 1 in Compounding.</li>
						            <li>
						                You can use in Compounding: <strong>Queen Ant Doll Lv. 1</strong> + <strong>Queen Ant Doll Lv. 1 </strong> = <strong>Queen Ant Doll Lv. 2</strong>.
						            </li>
						        </ul>
						    </li>
						    <li>If Compounding is a success, you'll have one Doll of higher level.</li>
						    <li>If Compounding is a failure, one of the Dolls you used will be destroyed, but you'll still be able to use the other one in Compounding.</li>
						    <li>Compounding Success Rate:</li>
						</ul>
						<table>
						    <thead>
						        <tr>
						            <th>Doll</th>
						            <th>Rate</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>Lv. 1 → Lv. 2</td>
						            <td>7%</td>
						        </tr>
						        <tr>
						            <td>Lv. 2 → Lv. 3</td>
						            <td>70%</td>
						        </tr>
						    </tbody>
						</table>
						<table>
						    <thead>
						        <tr>
						            <th width='250' className='trn' data-trn-key='label_name'>Name</th>
						            <th className='trn' data-trn-key='label_description'>Description</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						                <span className='icon-image-big panel-star-1'>
						                    <img src='/Icon/Texture/bm_baium_doll_30d.png' alt='Baium Doll Lv. 1' />
						                </span>
						                <span>
						                    <strong>Baium Doll Lv. 1</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Baium Doll's Sorcery Lv. 1&gt;<br />
						                Skill Cooldown -2%<br />
						                P. Def. +5%<br />
						                M. Def. +5%<br />
						                Silence Resistance +10%<br />
						                Fear Resistance +10%<br />
						                Paralysis Resistance +5%<br />
						                Knockdown Resistance +5%<br />
						                Pull Resistance +5%<br />
						                <br />
						                Boosts parameters when in inventory.<br />
						                Can be upgraded through compounding.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <span className='icon-image-big panel-star-2'>
						                    <img src='/Icon/Texture/bm_baium_doll_30d.png' alt='Baium Doll Lv. 2' />
						                </span>
						                <span>
						                    <strong>Baium Doll Lv. 2</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Baium Doll's Sorcery Lv. 2&gt;<br />
						                Skill Cooldown -3%<br />
						                P. Def. +12%<br />
						                M. Def. +12%<br />
						                Silence Resistance +40%<br />
						                Fear Resistance +40%<br />
						                Paralysis Resistance +20%<br />
						                Knockdown Resistance +20%<br />
						                Pull Resistance +20%<br />
						                <br />
						                Boosts parameters when in inventory.<br />
						                Can be upgraded through compounding.
						            </td>
						        </tr>
						        <tr>
						            <td>
						               <span className='icon-image-big panel-star-3'>
						                    <img src='/Icon/Texture/bm_baium_doll_30d.png' alt='' />
						                </span>
						                <span>
						                    <strong>Baium Doll Lv. 3</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Baium Doll's Sorcery Lv. 3&gt;<br />
						                Skill Cooldown -4%<br />
						                P. Def. +15%<br />
						                M. Def. +15%<br />
						                Silence Resistance +45%<br />
						                Fear Resistance +45%<br />
						                Paralysis Resistance +25%<br />
						                Knockdown Resistance +25%<br />
						                Pull Resistance +25%<br />
						                <br />
						                Boosts parameters when in inventory.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <span className='icon-image-big panel-star-1'>
						                    <img src='/Icon/Texture/bm_queen_ant_doll_30d.png' alt='' />
						                </span>
						                <span>
						                    <strong>Queen Ant Doll Lv. 1</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Queen Ant Doll's Sorcery Lv. 1&gt;<br />
						                P. Atk. +2%<br />
						                P. Critical Damage +3%<br />
						                P. Skill Critical Damage +3%<br />
						                Max HP +4%<br />
						                <br />
						                Boosts parameters when in inventory.<br />
						                Can be upgraded through compounding.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <span className='icon-image-big panel-star-2'>
						                    <img src='/Icon/Texture/bm_queen_ant_doll_30d.png' alt='Queen Ant Doll Lv. 2' />
						                </span>
						                <span>
						                    <strong>Queen Ant Doll Lv. 2</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Queen Ant Doll's Sorcery Lv. 2&gt;<br />
						                P. Atk. +8%<br />
						                P. Critical Damage +8%<br />
						                P. Skill Critical Damage +8%<br />
						                Max HP +12%<br />
						                <br />
						                Boosts parameters when in inventory.<br />
						                Can be upgraded through compounding.
						            </td>
						        </tr>
						        <tr>
						            <td>
						               <span className='icon-image-big panel-star-3'>
						                    <img src='/Icon/Texture/bm_queen_ant_doll_30d.png' alt='Queen Ant Doll Lv. 3' />
						                </span>
						                <span>
						                    <strong>Queen Ant Doll Lv. 3</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Queen Ant Doll's Sorcery Lv. 3&gt;<br />
						                P. Atk. +12%<br />
						                P. Critical Damage +10%<br />
						                P. Skill Critical Damage +10%<br />
						                Max HP +15%.<br />
						                <br />
						                Boosts parameters when in inventory.
						            </td>
						        </tr>

						        <tr>
						            <td>
						                <span className='icon-image-big panel-star-1'>
						                    <img src='/Icon/Texture/bm_orfen_doll_30d.png' alt='Orfen Doll Lv. 1' />
						                </span>
						                <span>
						                    <strong>Orfen Doll Lv. 1</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Orfen Doll's Sorcery Lv. 1&gt;<br />
						                M. Atk. +2%<br />
						                M. Skill Critical Damage +3%<br />
						                Max MP +4%<br />
						                <br />
						                Boosts parameters when in inventory.<br />
						                Can be upgraded through compounding.
						            </td>
						        </tr>
						        <tr>
						            <td>
						               <span className='icon-image-big panel-star-2'>
						                    <img src='/Icon/Texture/bm_orfen_doll_30d.png' alt='Orfen Doll Lv. 2' />
						                </span>
						                <span>
						                    <strong>Orfen Doll Lv. 2</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Orfen Doll's Sorcery Lv. 2&gt;<br />
						                M. Atk. +8%<br />
						                M. Skill Critical Damage +8%<br />
						                Max MP +12%<br />
						                <br />
						                Boosts parameters when in inventory.<br />
						                Can be upgraded through compounding.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <span className='icon-image-big panel-star-3'>
						                    <img src='/Icon/Texture/bm_orfen_doll_30d.png' alt='Orfen Doll Lv. 3' />
						                </span>
						                <span>
						                    <strong>Orfen Doll Lv. 3</strong>
						                </span>
						            </td>
						            <td>
						                &lt;Orfen Doll's Sorcery Lv. 3&gt;<br />
						                M. Atk. +15%<br />
						                M. Skill Critical Damage +10%<br />
						                Max MP +15%<br />
						                <br />
						                Boosts parameters when in inventory.
						            </td>
						        </tr>
						        <tr>
						            <td>
						               <span className='icon-image-big'>
						                    <img src='/Icon/Texture/bm_baium_doll_30d.png' alt='Baium Doll 7-day' />
						                </span>
						                <span>
						                    <strong>Baium Doll</strong> 7-day
						                </span>
						            </td>
						            <td>
						                &lt;Baium Doll's Sorcery Lv. 2&gt;<br />
						                Skill Cooldown -3%<br />
						                P. Def. +12%<br />
						                M. Def. +12%<br />
						                Silence Resistance +40%<br />
						                Fear Resistance +40%<br />
						                Paralysis Resistance +20%<br />
						                Knockdown Resistance +20%<br />
						                Pull Resistance +20%<br />
						                <br />
						                Available for 7 d. Boosts parameters when in inventory.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <span className='icon-image-big'>
						                    <img src='/Icon/Texture/bm_orfen_doll_30d.png' alt='Orfen Doll 7-day' />
						                </span>
						                <span>
						                    <strong>Orfen Doll</strong> 7-day
						                </span>
						            </td>
						            <td>
						                &lt;Orfen Doll's Sorcery Lv. 2&gt;<br />
						                M. Atk. +8%<br />
						                M. Skill Critical Damage +8%<br />
						                Max MP +12%.<br />
						                <br />
						                For 7 days. Boosts parameters when in inventory.
						            </td>
						        </tr>
						        <tr>
						            <td>
						                <span className='icon-image-big'>
						                    <img src='/Icon/Texture/bm_queen_ant_doll_30d.png' alt='Queen Ant Doll 7-day' />
						                </span>
						                <span>
						                    <strong>Queen Ant Doll</strong> 7-day
						                </span>
						            </td>
						            <td>
						                &lt;Queen Ant Doll's Sorcery&gt;<br />
						                P. Atk. +12%<br />
						                P. Critical Damage +10%<br />
						                P. Skill Critical Damage +10%<br />
						                Max HP +15%.<br />
						                <br />
						                For 7 days. Boosts parameters when in inventory.
						            </td>
						        </tr>
						    </tbody>
						</table>

					</div>
				</div>
			</div>
		</div>
	)
}

export default Dolls