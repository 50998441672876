import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const ItemBlessing = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/items' className='trn' data-trn-key='wiki_items'>Items</NavLink>
						<a className='trn' data-trn-key='label_itemblessing'>Item Blessing</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_itemblessing'>Item Blessing</h2>

						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='/assets/img/wiki/itemblessing_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_itemblessing_01'>
							<li>Weapons can be made more powerful by using special Scrolls of Blessing, which grant various buffs.</li>
							<li>Only weapon of C-grade and higher can be blessed. Temporary weapon can’t be blessed.</li>
							<li>Item blessing requires a special Scroll of Blessing. Additional materials or adena are not required.</li>
						</ul>
						<table>
							<thead>
								<tr>
									<th width='300' className='trn' data-trn-key='label_name'>Name</th>
									<th width='400'className='trn' data-trn-key='label_description'>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image-big'>
											<img src='./Icon/Texture/bless_scroll.png' alt='Scroll of Blessing' />
										</span>
										<span>
											<strong>Scroll of Blessing</strong>
										</span>
									</td>
									<td>When used, blesses your weapon with a certain chance.</td>
								</tr>
							</tbody>
						</table>
						<ul className='trn' data-trn-key='label_itemblessing_02'>
							<li>This scroll can be bought from L-Coin Store (category `Adena store`) for 30,000,000 adena. Maximum amount of 1 pc. per day for an account.</li>
							<li><strong>Scroll of Blessing</strong>, with a certain chance, can be obtained as loot in Dragon Valley, Sel Mahum Base, Antharas' Lair (dimensional zone).</li>
							<li>
								To attempt to bless a weapon, click with right mouse button on a <strong>Scroll of Blessing</strong> in your inventory. A window will open that will show a list of weapons that can be blessed.
								<ul>
									<li>On a failed blessing attempt only a Scroll of Blessing will disappear, the weapon will remain the same.</li>
									<li>On a successful blessing attempt, the weapon will receive an enhancement depending on its type (see the table).</li>
								</ul>
							</li>
							<li>On successful blessing of A-grade weapons and special weapons (Boss Weapons and Death Knight's Flame Sword), there will be a main effect but also an additional enhancement depending on the enchantment level.</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle'><span className='trn' data-trn-key='label_effect'>Effect</span>s</h3>
						
						<p className='trn' data-trn-key='label_itemblessing_03'>There’s a different blessing effects depending on weapon type:</p>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th width='300' className='trn' data-trn-key='label_weapons'>Weapon</th>
							            <th width='400' className='trn' data-trn-key='label_effect'>Effect</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td><span className='trn' data-trn-key='label_onehandedswords'>One-handed Sword</span>/<span className='trn' data-trn-key='label_rapiers'>Rapier</span></td>
							            <td>Skill Power +2%</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_twohandedswords'>Two-handed Sword</span>/<span className='trn' data-trn-key='label_ancientswords'>Ancient Sword</span></td>
							            <td>Attack range +20</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_onehandedblunts'>One-handed Blunt</span></td>
							            <td>Skill Critical Rate +1%</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_twohandedblunts'>Two-handed Blunt</span></td>
							            <td>Atk. Spd. +30, Casting Spd. +30</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_bows'>Bow</span></td>
							            <td>Arrows do not consume MP</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_daggers'>Dagger</span></td>
							            <td>Vital Point Attack Rate +5%</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_dualswords'>Dual Swords</span>/<span className='trn' data-trn-key='label_fistweapon'>Fist weapon</span></td>
							            <td>P. Skill Critical Damage +50</td>
							        </tr>
							        <tr>
							            <td><span className='trn' data-trn-key='label_polearms'>Spear</span></td>
							            <td>Attack range +20, number of targets +1</td>
							        </tr>
							    </tbody>
							</table>
						</div>

						<p className='trn' data-trn-key='label_itemblessing_04'>Additional blessing effects for Boss Weapons:</p>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th width='200' className='trn' data-trn-key='label_enchantment'>Enchantment</th>
							            <th width='500' className='trn' data-trn-key='label_effect'>Effect</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>+5</td>
							            <td>Attribute Attack +20</td>
							        </tr>
							        <tr>
							            <td>+6</td>
							            <td>P. Atk. +130, M. Аtk. +170</td>
							        </tr>
							        <tr>
							            <td>+7</td>
							            <td>All Critical Rate +5%</td>
							        </tr>
							        <tr>
							            <td>+8</td>
							            <td>Skill Power +5%</td>
							        </tr>
							        <tr>
							            <td>+9</td>
							            <td>All Critical Damage +150, Increases Max Critical Rate</td>
							        </tr>
							        <tr>
							            <td>+10</td>
							            <td>Soulshot/ Spiritshot Damage +15%</td>
							        </tr>
							    </tbody>
							</table>
						</div>
						<p className='trn' data-trn-key='label_itemblessing_05'>Additional blessing effects for Death Knight's Flame Sword:</p>
						<div className='table-scrolling'>
							<table>
							    <thead>
							        <tr>
							            <th width='200' className='trn' data-trn-key='label_enchantment'>Enchantment</th>
							            <th width='500' className='trn' data-trn-key='label_effect'>Effect</th>
							        </tr>
							    </thead>
							    <tbody>
							        <tr>
							            <td>+1</td>
							            <td>Skill Power +15%</td>
							        </tr>
							        <tr>
							            <td>+2</td>
							            <td>All Critical Damage +200, Increases Max Critical Rate</td>
							        </tr>
							        <tr>
							            <td>+3</td>
							            <td>Soulshot/ Spiritshot Damage +20%</td>
							        </tr>
							    </tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ItemBlessing