import Header from './components/Header'
import Footer from './components/Footer'
import Maintenance from './components/Maintenance'
import NotFound from './components/NotFound'
import ScrollToTop from './components/ScrollToTop'
import Home from './components/Home'
import { News } from './components/News'
import Terms from './components/Terms'
import Streamers from './components/Streamers'
import Privacity from './components/Privacity'
import Agreement from './components/Agreement'
import HomeWiki from './components/Wiki/HomeWiki'
import Connect from './components/Wiki/Connect'
import Starting from './components/Wiki/Starting'

import LCoinStore from './components/Wiki/General/LCoinStore'
import L2Store from './components/Wiki/General/L2Store'
import Statpoints from './components/Wiki/General/Statpoints'
import SayhasGrace from './components/Wiki/General/SayhasGrace'
import Attributes from './components/Wiki/General/Attributes'
import RandomCraft from './components/Wiki/General/RandomCraft'
import MagicLamp from './components/Wiki/General/MagicLamp'
import Pets from './components/Wiki/General/Pets'

import Battles from './components/Wiki/Battles/Battles'
import Olympiads from './components/Wiki/Battles/Olympiads'
import CastleSieges from './components/Wiki/Battles/CastleSieges'
import OrcFortress from './components/Wiki/Battles/OrcFortress'
import Transcendent from './components/Wiki/Battles/Transcendent'
import PrimevalGarden from './components/Wiki/Battles/PrimevalGarden'
import PrimevalIsle from './components/Wiki/Battles/PrimevalIsle'

import Items from './components/Wiki/Items/Items'
import ItemBlessing from './components/Wiki/Items/ItemBlessing'
import ItemEnchanting from './components/Wiki/Items/ItemEnchanting'

import OtherItems from './components/Wiki/OtherItems/OtherItems'
import Agathions from './components/Wiki/OtherItems/Agathions'
import Belts from './components/Wiki/OtherItems/Belts'
import Bracelets from './components/Wiki/OtherItems/Bracelets'
import Brooches from './components/Wiki/OtherItems/Brooches'
import Cloaks from './components/Wiki/OtherItems/Cloaks'
import Dolls from './components/Wiki/OtherItems/Dolls'

import history from 'connect-history-api-fallback'
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop />
        <Header/>
        <Switch>
          /* Main */
          <Route path='/news' component={News} />
          <Route path='/terms' component={Terms} />
          <Route path='/privacity' component={Privacity} />
          <Route path='/agreement' component={Agreement} />
          <Route path='/wiki' component={HomeWiki} />
          <Route path='/connect' component={Connect} />
          <Route path='/streamers' component={Streamers} />

          /* Wiki - General */
          <Route path='/lcoinstore' component={LCoinStore} />
          <Route path='/l2store' component={L2Store} />
          <Route path='/starting' component={Starting} />
          <Route path='/statpoints' component={Statpoints} />
          <Route path='/sayhasgrace' component={SayhasGrace} />
          <Route path='/attributes' component={Attributes} />
          <Route path='/randomcraft' component={RandomCraft} />
          <Route path='/magiclamp' component={MagicLamp} />
          <Route path='/pets' component={Pets} />

          /* Wiki - Battles */
          <Route path='/battles' component={Battles} />
          <Route path='/olympiads' component={Olympiads} />
          <Route path='/castlesieges' component={CastleSieges} />
          <Route path='/orcfortress' component={OrcFortress} />
          <Route path='/transcendent' component={Transcendent} />
          <Route path='/primevalgarden' component={PrimevalGarden} />
          <Route path='/primevalisle' component={PrimevalIsle} />

          /* Wiki - Items */
          <Route path="/items" component={Items} />
          <Route path='/itemblessing' component={ItemBlessing} />
          <Route path='/itemenchanting' component={ItemEnchanting} />

          /* Wiki - Other Items */
          <Route path='/otheritems' component={OtherItems} />
          <Route path='/agathions' component={Agathions} />
          <Route path='/belts' component={Belts} />
          <Route path='/bracelets' component={Bracelets} />
          <Route path='/brooches' component={Brooches} />
          <Route path='/cloaks' component={Cloaks} />
          <Route path='/dolls' component={Dolls} />

          <Route path='/' exact={true} component={Home} />
          <Route path="/maintenance" component={Maintenance} />

          <Route path="*" component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
