import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Olympiads = props => {
	return (
	<div id='internal' className='wiki'>
		<div className='wrapper'>
			<h1 className='internal-title'>
				Essence Wiki
			</h1>
			<div className='box-wrapper'>
				<HeaderWiki />
				<div className='box-breadcrumb'>
					<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
					<NavLink to='/battles' className='trn' data-trn-key='wiki_battles'>Battles</NavLink>
					<a className='trn' data-trn-key='label_olympiads'>Olympiads</a>
				</div>

				<div className='box-content wiki-content'>
					<div className='alert trn margin-small-bottom' data-trn-key='label_feature_disabled'>
						This feature is currently disabled on Lineage II Essence.Land.
					</div>
					<h2 className='wiki-title trn' data-trn-key='label_olympiads'>Olympiads</h2>
					<div className='wiki-title-image'>
						<img className='wiki-featured-image' src='/assets/img/wiki/olympiads_header.jpg' alt='' />
					</div>

					<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
					<ul className='trn' data-trn-key='label_olympiads_01'>
						<li><strong>Characters level 70 and above, who have completed the second class transfer can take part in the Olympiad.</strong></li>
					    <li>Olympiad cycle lasts for one week.</li>
					    <li>Olympiad cycles take place from Monday to Saturday from 22:00 to 23:00 (server time).</li>
					    <li>There are 5 rounds available in a day.</li>
					    <li>During the competition, the Olympiad icon appears to the left of the minimap. To open the Olympiad window, click on this icon.</li>
					    <li>Olympiad window can also be opened by the “/olympiadstat” command.</li>
					    <li>Additionally, the statistics of the Olympiad are displayed in the system chat: the number of points, victories and defeats.</li>
					    <li>
					        Press the “Rank” button in the Olympiad window to get to the ranking system interface. It shows the number of accumulated Olympiad points, wins and defeats, how many times the character was a Hero, as well as information about their
					        rivals on all servers.
					    </li>
					    <li>The Olympiad points is reset to 1000 every Monday with the start of a new cycle.</li>
					    <li>The first place for each of the classes after the end of the cycle is transferred to the state of expectation of Heroism (Monday, 12:00).</li>
					    <li>Heroic Expectation Status allows any character to receive the title of Hero &nbsp;via NPC Monument of Heroes in each city.</li>
					    <li>A character who becomes a Hero gains a special ability - 'Heroic Miracle', check <a href='#rewards'>“Rewards”</a>.</li>
					    <li>For winning the battle, the characters receive the Maliss' HP Recovery Potion, see details in the Rewards section.</li>
					</ul>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_participating'>Participating</h3>
					<ul className='trn' data-trn-key='label_olympiads_02'>
						<img src='../assets/img/wiki/olympiads_time.jpg' alt='' className='float-right' />
					    <li>
					        During the Olympiad you must sign up for participation in the Olympiad.
					        <ul>
					            <li>During the Olympics, a special icon is displayed to the left of the minimap with a countdown of the remaining time.&nbsp;When clicked, the Olympiad window opens.</li>
					        </ul>
					    </li>
					    <li>
					        When moving to the arena, certain conditions are met:
					        <ul>
					            <li>All the positive and negative effects that were granted on the character earlier are saved, and 5 seconds after moving to the Olympiad, the HP, MP and CP are completely restored.</li>
					            <li>Participants can use any kind of HP / MP / CP potions, scrolls of positive effects, etc.&nbsp;Also automatically usage of HP potions is available.</li>
					            <li>Cubes and summoned servants are released when moving to the arena.</li>
					            <li>Re-use timer resets for skills that have a cooldown of less than 15 minutes.</li>
					        </ul>
					    </li>
					    <li>
					        The battle lasts up to 6 minutes or until the HP of one of the opponents drops to 0. If at the end of the fight the participants still have HP above 0, then the victory goes to the character who caused more damage during the fight.
					    </li>
					</ul>

					<hr className='invisible' />
					<span className='anchor' id='rewards'></span>
					<h3 className='wiki-subtitle'>Hero / <span className='trn' data-trn-key='label_reward'>Rewards</span></h3>
					<ul className='trn' data-trn-key='label_olympiads_03'>
						<li>The winner is the character who scored the most points in his class at the time the Olympiad cycle ended.</li>
						<li>A character who becomes a Hero gains a heroic skill for the duration of the rank:</li>
					</ul>
					<div className='table-scrolling'>
						<table>
							<thead>
								<tr>
									<th width='250'>Skill</th>
									<th width='500' style={{ minWidth: 250 }} className='trn' data-trn-key='label_description'>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image-big'>
											<img src='./Icon/Texture/skill0912.png' alt='Heroic Miracle' className='icon-image' />
										</span>
										<span>
											<strong>Heroic Miracle</strong>
										</span>
									</td>
									<td>
										<p>
											<strong>&lt;Main effect&gt;</strong><br/>
											Max HP +850. During attack Atk. Spd., Casting Spd. and Speed are increased. The buff of continual MP recovery is activated.
										</p>
										<p>
											<strong>&lt;Trigger effect&gt;</strong><br/>
											For 10 sec. Atk. Spd. by +100, Casting Spd. +100, Speed +20. Continual MP recovery is activated. Cooldown: 100 sec.
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className='table-scrolling'>
						<table className='content-center'>
							<thead>
								<tr>
									<th colSpan={2} style={{ minWidth: 500 }}><span className='trn' data-trn-key='label_olympiads_05'>Visual effects added</span></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<img src='../assets/img/wiki/olympiads_hero_aura.jpg' alt='' className='wiki-featured-image' />
									</td>
									<td>
										<img src='../assets/img/wiki/olympiads_hero_cloak.jpg' alt='' className='wiki-featured-image' />
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<ul className='trn' data-trn-key='label_olympiads_04'>
					    <li>The skill is automatically removed when the Hero status expires.</li>
					    <li>
					        Heroes get a distinctive shining aura around the characters. And their equipped weapons start to emit bright light. The visual effect is added to the equipped cloak in the form of wings. Characteristics of the character or his items
					        do not increase,&nbsp; only visual effects are added.
					    </li>
					    <li>Heroes can chat in a special channel (%). The chat font color is sky blue and is displayed to all players on the server.</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	)
}

export default Olympiads