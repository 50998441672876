import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Belts = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/otheritems' className='trn' data-trn-key='wiki_otheritems'>Other Items</NavLink>
						<a className='trn' data-trn-key='label_belts'>Belts</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_belts'>Belts</h2>
						<hr className='invisible' />
						<img src='/assets/img/wiki/belts.jpg' className='wiki-featured-image float-right margin-default-left' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_belts_01'>
							<li>Belts are equipped in a special inventory slot.</li>
							<li>Belts slightly increase character’s P. Def., but also give a variety of buffs, for example: increased amount of inventory slots, increased Weight Limit, etc.</li>
							<li>Belts can be enchanted with special scrolls.</li>
						</ul>
						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_howtoobtain'>How to Obtain</h3>
						
						<div className='trn' data-trn-key='label_belts_02'>
							<p><strong>Acquiring Dragon Belt</strong></p>
							<ul>
								<li><strong>L-Coin Store</strong> sells <img className='icon-image-small' src='/Icon/Texture/etc_readybox_bronze.png' alt='Dragon Belt' /> <strong>Dragon Belt Pack</strong>, which contains <img className='icon-image-small' src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' /> <strong>Dragon Belt</strong> and <img className='icon-image-small' src='/Icon/Texture/etc_blesscharm_ward_val_i00.png' alt='Dragon Belt' /> <strong>Scroll: Enchant Dragon Belt</strong> — 10 pcs., which can be used for enchanting Dragon Belt up to +10.</li>
							</ul>
							<p><strong>How to enchant Dragon Belt</strong></p>
							<ul>
								<li>Enchanting Dragon Belt requires IMG Scroll: Enchant Dragon Belt .</li>
								<li>Extra effect of enchanting: P. Def. +1 (enchant from +1 to +3), then - P. Def. +3.</li>
								<li>Safe Enchant is not available.</li>
							</ul>
						</div>

						<div className='table-scrolling'>
							<table>
								<thead>
									<tr>
										<th width='200' className='trn' data-trn-key='label_level'>Level</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +1</strong>
											</span>
										</td>
										<td>
											Weight limit +2000<br/>
											Inventory slots +2<br/>
											DEX +1<br/>
											WIT +1<br/>
											CON +1<br/>
											MEN +1.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +2</strong>
											</span>
										</td>
										<td>
											Weight limit +3000<br/>
											Inventory slots +4<br/>
											DEX +1<br/>
											WIT +1<br/>
											CON +1<br/>
											MEN +1<br/>
											STR +1<br/>
											INT +1	
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +3</strong>
											</span>
										</td>
										<td>	
											Weight limit +4000<br/>
											Inventory slots +6<br/>
											DEX +2<br/>
											WIT +2<br/>
											CON +2<br/>
											MEN +2<br/>
											STR +2<br/>
											INT +2					
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +4</strong>
											</span>
										</td>
										<td>
											Weight limit +5000<br/>
											Inventory slots +6<br/>
											Max HP +100<br/>
											Max MP +50<br/>
											Max CP +100<br/>
											DEX +3<br/>
											WIT +3<br/>
											CON +3<br/>
											MEN +3<br/>
											STR +3<br/>
											INT +3
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +5</strong>
											</span>
										</td>
										<td>
											Weight limit +6000<br/>
											Inventory slots +6<br/>
											Max HP +150<br/>
											Max MP +100<br/>
											Max CP +200<br/>
											HP Recovery Potions' Effect +80<br/>
											MP Recovery Potions' Effect +10<br/>
											P. Def. +30<br/>
											M. Def. +20<br/>
											DEX +3<br/>
											WIT +3<br/>
											CON +4<br/>
											MEN +4<br/>
											STR +3<br/>
											INT +3											
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +6</strong>
											</span>
										</td>
										<td>
											Weight limit +7000<br/>
											Inventory slots +6<br/>
											Max HP +200<br/>
											Max MP +150<br/>
											Max CP +300<br/>
											HP Recovery Potions' Effect +100<br/>
											MP Recovery Potions' Effect +20<br/>
											P. Def. +40<br/>
											M. Def. +30<br/>
											DEX +4<br/>
											WIT +4<br/>
											CON +4<br/>
											MEN +4<br/>
											STR +3<br/>
											INT +3											
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +7</strong>
											</span>
										</td>
										<td>
											Weight limit +8000<br/>
											Inventory slots +8<br/>
											Max HP +300<br/>
											Max MP +200<br/>
											Max CP +500<br/>
											HP Recovery Potions' Effect +120<br/>
											MP Recovery Potions' Effect +30<br/>
											P. Def. +50<br/>
											M. Def. +40<br/>
											Atk. Spd. +20<br/>
											P. Critical Rate +10<br/>
											M. Skill Critical Rate +10<br/>
											DEX +4<br/>
											WIT +4<br/>
											CON +4<br/>
											MEN +4<br/>
											STR +4<br/>
											INT +4											
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +8</strong>
											</span>
										</td>
										<td>
											Weight limit +9000<br/>
											Inventory slots +8<br/>
											Max HP +400<br/>
											Max MP +250<br/>
											Max CP +700<br/>
											HP Recovery Potions' Effect +150<br/>
											MP Recovery Potions' Effect +50<br/>
											P. Def. +80<br/>
											M. Def. +50<br/>
											Atk. Spd. +30<br/>
											P. Critical Rate +20<br/>
											M. Skill Critical Rate +20<br/>
											Received Healing +5%<br/>
											Received damage -3%<br/>
											DEX +4<br/>
											WIT +4<br/>
											CON +5<br/>
											MEN +5<br/>
											STR +4<br/>
											INT +4											
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +9</strong>
											</span>
										</td>
										<td>
											Weight limit +10000<br/>
											Inventory slots +10<br/>
											Max HP +600<br/>
											Max MP +300<br/>
											Max CP +1000<br/>
											HP Recovery Potions' Effect +200<br/>
											MP Recovery Potions' Effect +80<br/>
											P. Def. +100<br/>
											M. Def. +80<br/>
											Atk. Spd. +50<br/>
											P. Critical Rate +30<br/>
											M. Skill Critical Rate +30<br/>
											Received Healing +10%<br/>
											Received damage -5%<br/>
											DEX +5<br/>
											WIT +5<br/>
											CON +5<br/>
											MEN +5<br/>
											STR +4<br/>
											INT +4											
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='/Icon/Texture/r2_belt_i01.png' alt='Dragon Belt' />
											</span>
											<span>
												<strong>Dragon Belt +10</strong>
											</span>
										</td>
										<td>
											Weight limit +12000<br/>
											Inventory slots +12<br/>
											Max HP +1000<br/>
											Max MP +500<br/>
											Max CP +1500<br/>
											HP Recovery Potions' Effect +300<br/>
											MP Recovery Potions' Effect +100<br/>
											P. Def. +150<br/>
											M. Def. +120<br/>
											Atk. Spd. +100<br/>
											P. Critical Rate +50<br/>
											M. Skill Critical Rate +50<br/>
											Received Healing +20%<br/>
											Received damage -10%<br/>
											DEX +5<br/>
											WIT +5<br/>
											CON +5<br/>
											MEN +5<br/>
											STR +5<br/>
											INT +5											
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<h3 className='wiki-subtitle trn' data-trn-key='label_howtoobtain'>Enchantment Success Rate:</h3>
						<table>
							<thead>
								<tr>
									<th className='trn' data-trn-key='label_level'>Level</th>
									<th className='trn' data-trn-key='label_successrate'>Success Rate</th>
								</tr>
							</thead>
							<tbody>
							    <tr>
							        <td>+0 → +1</td>
							        <td>60%</td>
							    </tr>
							    <tr>
							        <td>+1 → +2</td>
							        <td>50%</td>
							    </tr>
							    <tr>
							        <td>+2 → +3</td>
							        <td>40%</td>
							    </tr>
							    <tr>
							        <td>+3 → +4</td>
							        <td>35%</td>
							    </tr>
							    <tr>
							        <td>+4 → +5</td>
							        <td>30%</td>
							    </tr>
							    <tr>
							        <td>+5 → +6</td>
							        <td>28%</td>
							    </tr>
							    <tr>
							        <td>+6 → +7</td>
							        <td>26%</td>
							    </tr>
							    <tr>
							        <td>+7 → +8</td>
							        <td>24%</td>
							    </tr>
							    <tr>
							        <td>+8 → +9</td>
							        <td>22%</td>
							    </tr>
							    <tr>
							        <td>+9 → +10</td>
							        <td>20%</td>
							    </tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Belts