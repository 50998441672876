import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const MagicLamp = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<a>Magic Lamp</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title'>Magic Lamp</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='../assets/img/wiki/magiclamp_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_magiclamp_01'>
							<li>Information about Magic Lamp: scale fill level, amount of received Magic Lamps as well as the 'Play' button are in a separated window under the map.</li>
							<li>In the process of hunting, the character gains experience, and the lamp scale is gradually filled. Experience gained from Fishing and from other sources does not affect the scale.</li>
							<li>When the scale is full, the character will receive the Magic Lamp and will be able to click on the “Play” button to try his luck and receive one of the rewards.</li>
							<li>In a game of luck, one of four items can be earn, which will give directly to the character a certain amount of experience and SP:</li>
						</ul>
						<table>
							<thead>
								<tr>
									<th>Item</th>
									<th  className='trn' data-trn-key='label_reward'>Reward</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image panel-star-level-3'>
											<img src='./Icon/Texture/r99_soul_stone_i00.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>Red Arc</strong>
										</span>
									</td>
									<td>
										100,000,000 EXP<br/>
										2,700,000 SP
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-star-level-2'>
											<img src='./Icon/Texture/r99_soul_stone_i05.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>Purple Arc</strong>
										</span>
									</td>
									<td>
										30,000,000 EXP<br/>
										810,000 SP
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-star-level-1'>
											<img src='./Icon/Texture/r99_soul_stone_i02.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>Blue Arc</strong>
										</span>
									</td>
									<td>
										10,000,000 EXP<br/>
										270,000 SP
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-gold'>
											<img src='./Icon/Texture/r99_soul_stone_i04.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>Green Arc</strong>
										</span>
									</td>
									<td>
										5,000,000 EXP<br/>
										135,000 SP
									</td>
								</tr>
							</tbody>
						</table>

						<ul className='trn' data-trn-key='label_magiclamp_02'>
							<li>
								Characters Level 76 and higher with completed 3rd class transfer may to choose improved game option.
							</li>
							<li>
								1 use of Greater Magic Lamp is available when <strong>5 Sayha’s Blessing and 10 Magic Lamp</strong> are used.
							</li>
							<li>
								After activating the improved game the character will receive a High-grade Arc:
							</li>
						</ul>
						<table>
							<thead>
								<tr>
									<th>Item</th>
									<th  className='trn' data-trn-key='label_reward'>Reward</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<span className='icon-image panel-star-level-3'>
											<img src='./Icon/Texture/ensoul_big_pp.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>High-grade Red Arc</strong>
										</span>
									</td>
									<td>
										1,200,000,000 EXP<br/>
										32,400,000 SP
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-star-level-2'>
											<img src='./Icon/Texture/ensoul_big_pm.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>High-grade Purple Arc</strong>
										</span>
									</td>
									<td>
										360,000,000 EXP<br/>
										9,720,000 SP
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-star-level-1'>
											<img src='./Icon/Texture/ensoul_big_m.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>High-grade Blue Arc</strong>
										</span>
									</td>
									<td>
										120,000,000 EXP<br/>
										3,240,000 SP
									</td>
								</tr>
								<tr>
									<td>
										<span className='icon-image panel-gold'>
											<img src='./Icon/Texture/ensoul_big_ep.png' alt='Red Arc' className='icon-image' />
										</span>
										<span>
											<strong>High-grade Green Arc</strong>
										</span>
									</td>
									<td>
										60,000,000 EXP<br/>
										1,620,000 SP
									</td>
								</tr>
							</tbody>
						</table>
						<p className='trn' data-trn-key='label_magiclamp_03'>
							Magic Lamp rewards are given as EXP/SP points directly to a character.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
export default MagicLamp