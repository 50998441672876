import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Bracelets = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/otheritems' className='trn' data-trn-key='wiki_otheritems'>Other Items</NavLink>
						<a className='trn' data-trn-key='label_bracelets'>Bracelets</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_bracelets'>Bracelets</h2>
						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_bracelets_01'>
							<li>
								There are two types of Bracelets in Lineage 2 Essence.
								<ul>
									<li>Left hand Bracelets are for summoning <strong>Agathions</strong>, which are creatures that have various skills and also serve as decorations.</li>
									<li>Right hand Bracelets are for using <strong>Talismans</strong>, which help with fighting monsters and other players.</li>
								</ul>
							</li>
						</ul>
						<hr className='invisible' />

						<div className='trn' data-trn-key='label_bracelets_02'>
							<h3 className='wiki-subtitle'>Left hand Bracelets</h3>
							<img src='/assets/img/wiki/bracelets.jpg' className='wiki-featured-image float-right margin-default-left' />
							<ul>
								<li>Left hand Bracelets are for Agathions.</li>
								<li>Equipping a left hand Bracelet unlocks Agathion slots.</li>
								<li>The amount of slots depends on the Lv. of the Bracelet, there's always 1 main slot and from 1 to 4 of additional slots.</li>
								<li>When an Agathion bracer is equipped, open the Skills Window (Alt+K or by pressing 'Skills'), and you'll see the skills related to the Agathion in the active skills section (if the Agathion has any active skills).</li>
							</ul>
						</div>

						<hr className='invisible' />
						<div className='trn' data-trn-key='label_bracelets_03'>
							<h3 className='wiki-subtitle'>Acquiring left hand Bracelets</h3>	
							<ul>
								<li>There's only one kind of left hand Bracelets: Agathion Bracelet, which can be enhanced up to Lv. 5.</li>
								<li><span className='icon-image panel-step-level-1'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 1' /></span> <strong>Agathion Bracelet Lv. 1</strong> can be bought from Game Assistant:</li>
							</ul>	
						</div>				

						<hr className='invisible' />
						<table>
							<thead>
								<tr>
									<th data-trn-key='label_item'>Item</th>
									<th className='trn' data-trn-key='label_price'>Price</th>
								</tr>
							</thead>
							<tbody>
							    <tr>
							        <td>
							        	<span className='icon-image panel-step-level-1'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 1' /></span>
							        	<span><strong>Agathion Bracelet Lv. 1</strong></span>
							        </td>
							        <td>
							        	<span className='icon-image'><img src='/Icon/Texture/etc_adena_i00.png' alt='Adena' /></span>
							        	<span>500,000 Adena</span>
							        </td>
							    </tr>
							</tbody>
						</table>

						<hr className='small invisible' />
						<div className='trn' data-trn-key='label_bracelets_04'>
							<p><strong>Synthesis</strong></p>
							<ul>
							    <li>Agathion Bracelets can be used in Compounding from Lv. 1 to Lv. 5.</li>
							    <li>Lv. 5 and 6 Bracelets can only be acquired through Compounding.</li>
							    <li>
							        Compounding requires two Bracelets of the same level, example: <br/>
							        <span className='icon-image panel-step-level-1'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 1' /></span> <strong>Agathion Bracelet Lv. 1</strong>
							        &nbsp;+&nbsp;
							        <span className='icon-image panel-step-level-1'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 1' /></span> <strong>Agathion Bracelet Lv. 1</strong>
							        =&nbsp;
							        <span className='icon-image panel-step-level-2'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 2' /></span> <strong>Agathion Bracelet Lv. 2</strong>.
							        <ul>
							            <li>On a successful attempt, you will have a Bracelet that is 1 level higher.</li>
							            <li>On a failed attempt, one Bracelet will be destroyed, but the other one can be used in another Compounding attempt.</li>
							        </ul>
							    </li>
							</ul>
						</div>

						<hr className='invisible' />

						<div className='trn' data-trn-key='label_bracelets_05'>
							<h3 className='wiki-subtitle'>Right hand Bracelets</h3>
							<img src='/assets/img/wiki/bracelets_talisman.jpg' className='wiki-featured-image float-right margin-default-left' />
							<ul>
								<li>Right hand Bracelets are required for using Talismans.</li>
								<li>Equipping a right hand Bracelet unlocks Talisman slots.</li>
								<li>The amount of Talisman slots depends on the Bracelet, there can be 1-6 slots.</li>
							</ul>
						</div>

						<hr className='invisible' />

						<div className='trn' data-trn-key='label_bracelets_06'>
							<h3 className='wiki-subtitle'>Acquiring right hand Bracelets</h3>	
							<ul>
								<li>There's only one kind of right hand Bracelets: Talisman Bracelet, which can be enhanced up to Lv. 6.</li>
								<li>Bracelet: <span className='icon-image panel-gold-level-1'><img src='/Icon/Texture/dimension_bracelet_i00.png' alt='Talisman Bracelet Lv. 1' /></span> <strong>Talisman Bracelet Lv. 1</strong> can be bought in L-Coin Store.</li>
								<li>Higher level Bracelets have to be created using Compounding.</li>
							</ul>
						</div>
						<hr className='small invisible' />

						<div className='trn' data-trn-key='label_bracelets_07'>
							<p><strong>Synthesis</strong></p>
							<ul>
								<li>Talisman Bracelets can be used in Compounding from Lv. 1 to Lv. 6.</li>
								<li>
									Compounding requires two Bracelets of the same level, example:<br/>
									<span className='icon-image panel-gold-level-4'><img src='/Icon/Texture/dimension_bracelet_i03.png' alt='Talisman Bracelet Lv. 4' /></span>
										<span><strong>Talisman Bracelet Lv. 4</strong></span>&nbsp;+&nbsp;
									<span className='icon-image panel-gold-level-4'><img src='/Icon/Texture/dimension_bracelet_i03.png' alt='Talisman Bracelet Lv. 4' /></span>
										<span><strong>Talisman Bracelet Lv. 4</strong></span>&nbsp;=&nbsp;
									<span className='icon-image panel-gold-level-5'><img src='/Icon/Texture/dimension_bracelet_i04.png' alt='Talisman Bracelet Lv. 5' /></span>
										<span><strong>Talisman Bracelet Lv. 5</strong></span>
								</li>
							</ul>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_bracelets_08'>List of Bracelets</h3>

						<div className='table-scrolling'>
							<table style={{ width: '100%' }}>
								<thead>
									<tr>
										<th width='250' className='trn' data-trn-key='label_name'>Name</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-step-level-1'><img src='/Icon/Texture/agathion_magmeld_teleport.png' alt='Agathion Bracelet Lv. 1' /></span>
											<span><strong>Agathion Bracelet Lv. 1</strong></span>
										</td>
										<td>
											Unlocks 1 agathion slots.<br/>
											Can be compounded with Agathion Bracelet of the same level to get Agathion Bracelet Lv. 2.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-step-level-2'><img src='/Icon/Texture/bracelet_tersi_agathion.png' alt='Agathion Bracelet Lv. 2' /></span>
											<span><strong>Agathion Bracelet Lv. 2</strong></span>
										</td>
										<td>
											Unlocks 2 agathion slots.<br/>
											Can be compounded with Agathion Bracelet of the same level to get Agathion Bracelet Lv. 3.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-step-level-3'><img src='/Icon/Texture/high_agathion_bracelet_i00.png' alt='Agathion Bracelet Lv. 3' /></span>
											<span><strong>Agathion Bracelet Lv. 3</strong></span>
										</td>
										<td>
											Unlocks 3 agathion slots. STR/ INT/ CON/ MEN +1.<br/>
											Can be compounded with Agathion Bracelet of the same level to get Agathion Bracelet Lv. 4
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-step-level-4'><img src='/Icon/Texture/high_agathion_bracelet_i01.png' alt='Agathion Bracelet Lv. 4' /></span>
											<span><strong>Agathion Bracelet Lv. 4</strong></span>
										</td>
										<td>
											Unlocks 4 agathion slots. STR/ INT/ CON/ MEN +1. Fear/ Stun/ Sleep/ Paralysis/ Silence Resistance +5%.<br/>
											Can be compounded with Agathion Bracelet of the same level to get Agathion Bracelet Lv. 5.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-step-level-5'><img src='/Icon/Texture/pi_high_agathion_bracelet_i00.png' alt='Agathion Bracelet Lv. 5' /></span>
											<span><strong>Agathion Bracelet Lv. 5</strong></span>
										</td>
										<td>
											Unlocks 5 agathion slots. STR/ INT/ CON/ MEN/ DEX/ WIT +1. Cannot be dropped. Fear/ Stun/ Sleep/ Paralysis/ Silence Resistance +8%.
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<hr className='invisible' />
						<div className='table-scrolling'>
							<table style={{ width: '100%' }}>
								<thead>
									<tr>
										<th width='250' className='trn' data-trn-key='label_name'>Name</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big panel-gold-level-1'><img src='/Icon/Texture/dimension_bracelet_i00.png' alt='Talisman Bracelet Lv. 1' /></span>
											<span><strong>Talisman Bracelet Lv. 1</strong></span>
										</td>
										<td>
											Unlocks 1 talisman slot. Can be compounded with a Talisman Bracelet of the same level.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-gold-level-2'><img src='/Icon/Texture/dimension_bracelet_i01.png' alt='Talisman Bracelet Lv. 2' /></span>
											<span><strong>Talisman Bracelet Lv. 2</strong></span>
										</td>
										<td>
											Unlocks 2 talisman slots. Can be compounded with a Talisman Bracelet of the same level.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-gold-level-3'><img src='/Icon/Texture/dimension_bracelet_i02.png' alt='Talisman Bracelet Lv. 3' /></span>
											<span><strong>Talisman Bracelet Lv. 3</strong></span>
										</td>
										<td>
											Unlocks 3 talisman slots. Can be compounded with a Talisman Bracelet of the same level.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-gold-level-4'><img src='/Icon/Texture/dimension_bracelet_i03.png' alt='Talisman Bracelet Lv. 4' /></span>
											<span><strong>Talisman Bracelet Lv. 4</strong></span>
										</td>
										<td>
											Unlocks 4 talisman slots.<br/><br/>
											&lt;Special effects&gt;<br/>
											HP/ MP Recovery Rate +7%, P. Accuracy +1, M. Accuracy +1, weight limit +7000.<br/>
											Can be compounded with another Talisman Bracelet of the same level.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-gold-level-5'><img src='/Icon/Texture/dimension_bracelet_i04.png' alt='Talisman Bracelet Lv. 5' /></span>
											<span><strong>Talisman Bracelet Lv. 5</strong></span>
										</td>
										<td>
											Unlocks 5 talisman slots.<br/><br/>
											&lt;Special effects&gt;<br/>
											HP/ MP Recovery Rate +15%, P. Accuracy +2, M. Accuracy +2, weight limit +10,000.<br/>
											Can be compounded with a Talisman Bracelet of the same level.
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image-big panel-gold-level-6'><img src='/Icon/Texture/dimension_bracelet_i05.png' alt='Talisman Bracelet Lv. 6' /></span>
											<span><strong>Talisman Bracelet Lv. 6</strong></span>
										</td>
										<td>
											Unlocks 6 talisman slots.<br/><br/>
											&lt;Special effects&gt;<br/>
											HP/ MP Recovery Rate +15%, P. Accuracy +2, M. Accuracy +2, weight limit +10,000.
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Bracelets