import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const OrcFortress = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />
					<div className='box-breadcrumb'>
						<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
						<NavLink to='/battles' className='trn' data-trn-key='wiki_battles'>Battles</NavLink>
						<a className='trn' data-trn-key='label_orcfortress'>Orc Fortress</a>
					</div>

					<div className='box-content wiki-content'>
						<h2 className='wiki-title trn' data-trn-key='label_orcfortress'>Orc Fortress</h2>
						<div className='wiki-title-image'>
							<img className='wiki-featured-image' src='/assets/img/wiki/orcfortress_header.jpg' alt='' />
						</div>
						<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
						<ul className='trn' data-trn-key='label_orcfortress_01'>
							<li>The Orc Fortress is situated at the border of Oren and Goddard territories.</li>
							<li>Battle starts: daily at 8 PM (GMT+3) within duration of 30 minutes.</li>
							<li>
								A system notification warns players about the start of the battle 20 minutes in advance.
								<ul>
									<li>Before the battle starts, you can use teleportation (Oren) to get to a place near the Orc Fortress.</li>
								</ul>
							</li>
							<li>Any character can participate in the battle for the Orc Fortress, but only a 5th level Clan can own the fortress.</li>
							<li>
								To win, you have to display the Combat flag.
								<ul>
									<li>
										The player who displayed the flag receives <img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' className='icon-image-small' /> <strong>30,000,000 adena</strong> (the reward is sent by mail).
									</li>
									<li>The clan of the player who displayed the flag receives the Orc Fortress Siege skill: Acquired XP/ SP +30%, adena drop +20%.</li>
								</ul>
							</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_guide'>Guide</h3>
						<ul className='trn' data-trn-key='label_orcfortress_02'>
							<li>
								The battle for the fortress ends when the Combat flag is taken and successfully displayed before the duration of combat runs out, after that players can receive the reward.

								<ul>
									<li>If the Combat flag is successfully displayed before the time runs out, the battle is over and the Fortress becomes the property of the clan.</li>
									<li>If the Combat flag is not displayed before the time runs out, the Fortress has no owner until next battle.</li>
								</ul>
							</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_process'>About the process</h3>
						<ul className='trn' data-trn-key='label_orcfortress_03'>
							<li>Before combat starts, there are no monsters in the fortress, the zone is safe.</li>
							<li>The place where characters resurrect/return from the battle for the fortress is a peaceful zone.</li>
							<li>
								<span>When the battle starts, all of the fortress territory becomes a combat zone, monsters start to appear, and a timer starts next to the map.</span>
								<ul>
									<li>If there are characters in the fortress territory at the moment when the battle starts, they’ll be teleported outside of the fortress.</li>
								</ul>
							</li>
							<li>
								<span>NPC Flag Sentry Greg appears 20 minutes after the start of the battle, Flag Sentry Greg drops a flag when killed.</span>

								<ul>
									<li>Up to 3 Combat flags can exist at the same time.</li>
								</ul>
							</li>
							<li>
								<span>The character that picked up a Combat flag can’t attack and use attack skills, they receive special skills Flag Display and Remote Flag Display in the skill window.</span>
								<ul>
									<li>If the character can successfully use those skills on the flagpole inside the fortress, their clan wins the battle for the fortress.</li>
								</ul>
							</li>
						</ul>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_benefitsfortress'>Benefits of owning a fortress</h3>
						<p className='trn' data-trn-key='label_orcfortress_04'>After the siege is done, the clan whose player displayed the flag receives the following buff:</p>
						<div className='table-scrolling'>
							<table style={{  maxWidth: 800 }}>
								<thead>
									<tr>
										<th width='250' className='trn' data-trn-key='label_name'>Name</th>
										<th width='550'className='trn' data-trn-key='label_description'>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image-big'>
												<img src='./Icon/Texture/skill32121.png' alt='Orc Fortress Siege' />
											</span>
											<span>
												<strong>Orc Fortress Siege</strong><br/>(Lv 1)
											</span>
										</td>
										<td>A special ability given to the clan members who own the Orc Fortress. Acquired XP/ SP +30%, gained adena +20%.</td>
									</tr>
								</tbody>
							</table>
						</div>

						<p className='trn' data-trn-key='label_orcfortress_05'>Clan members of the clan that owns the fortress can buy items from <strong>NPC Logistics Officer</strong>:</p>
						<div className='table-scrolling'>
							<table style={{ maxWidth: 800 }}>
								<thead>
									<tr>
										<th width='400'>Item</th>
										<th width='400'  className='trn' data-trn-key='label_price'>Price</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_spell_shot_gold_i01.png' alt='Blessed Spiritshot' />
											</span>
											<span>
												<strong>Blessed Spiritshot</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												82 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/bm_spirit_bullet_gold.png' alt='Soulshot' />
											</span>
											<span>
												<strong>Soulshot</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												22 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_stone_gray_i00.png' alt='Spirit Ore' />
											</span>
											<span>
												<strong>Spirit Ore</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												440 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_scroll_of_return_castle_i00.png' alt='Scroll of Escape: Fortress' />
											</span>
											<span>
												<strong>Scroll of Escape: Fortress</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												550 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image panel-cursed'>
												<img src='./Icon/Texture/etc_scroll_of_enchant_armor_i03.png' alt='Scroll: Enchant B-grade Armor' />
											</span>
											<span>
												<strong>Scroll: Enchant B-grade Armor</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												264,000 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image panel-cursed'>
												<img src='./Icon/Texture/etc_scroll_of_enchant_armor_i02.png' alt='Scroll: Enchant C-grade Armor' />
											</span>
											<span>
												<strong>Scroll: Enchant C-grade Armor</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												49,500 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image panel-cursed'>
												<img src='./Icon/Texture/etc_scroll_of_enchant_armor_i01.png' alt='Scroll: Enchant D-grade Armor' />
											</span>
											<span>
												<strong>Scroll: Enchant D-grade Armor</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												19,800 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image panel-cursed'>
												<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i03.png' alt='Scroll: Enchant B-grade Weapon' />
											</span>
											<span>
												<strong>Scroll: Enchant B-grade Weapon</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												1,650,000 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image panel-cursed'>
												<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i02.png' alt='Scroll: Enchant C-grade Weapon' />
											</span>
											<span>
												<strong>Scroll: Enchant C-grade Weapon</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												363,000 adena
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span className='icon-image panel-cursed'>
												<img src='./Icon/Texture/etc_scroll_of_enchant_weapon_i01.png' alt='Scroll: Enchant D-grade Weapon' />
											</span>
											<span>
												<strong>Scroll: Enchant D-grade Weapon</strong>
											</span>
										</td>
										<td>
											<span className='icon-image'>
												<img src='./Icon/Texture/etc_adena_i00.png' alt='Adena' />
											</span>
											<span>
												165,000 adena
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<hr className='invisible' />
						<h3 className='wiki-subtitle trn' data-trn-key='label_relatedmissions'>Related missions</h3>
						<div className='table-scrolling'>
							<table >
								<thead>
									<tr>
										<th className='trn' data-trn-key='label_type'>Type</th>
										<th className='trn' data-trn-key='label_name'>Name</th>
										<th className='trn' data-trn-key='label_description'>Description</th>
										<th style={{ minWidth: 250 }} className='trn' data-trn-key='label_reward'>Rewards</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className='trn' data-trn-key='label_daily'>Daily</span><br/>(account)
										</td>
										<td><strong>Assault on Orc Fortress</strong></td>
										<td>
											Kill weak monsters in the Orc Fortress. A reward is given for killing titleless monsters during the battle for the Orc Fortress.
											<ul>
												<li>Character of 60-99 level.</li>
											</ul>
										</td>
										<td>
											<div>
												<span className='icon-image panel-cursed'>
													<img src='./Icon/Texture/etc_recall_exp_scroll.png' alt='XP Growth Scroll' />
												</span>
												<span>
													<strong>XP Growth Scroll</strong> — 2 pcs.
												</span>
											</div>
											<div className='padding-supersmall-top'>
												<span className='icon-image panel-star-level-1'>
													<img src='./Icon/Texture/r99_soul_stone_i02.png' alt='Blue Lantern' />
												</span>
												<span>
													<strong>Blue Lantern</strong> — 5 pcs.
												</span>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<span className='trn' data-trn-key='label_daily'>Daily</span><br/>(account)
										</td>
										<td><strong>Assault on Orc Fortress</strong></td>
										<td>
											Kill weak monsters in the Orc Fortress. A reward is given for killing titleless monsters during the battle for the Orc Fortress.
											<ul>
												<li>Character of 60-99 level.</li>
											</ul>
										</td>
										<td>
											<div>
												<span className='icon-image panel-cursed'>
													<img src='./Icon/Texture/etc_recall_exp_scroll.png' alt='XP Growth Scroll' />
												</span>
												<span>
													<strong>XP Growth Scroll</strong> — 3 pcs.
												</span>
											</div>
											<div className='padding-supersmall-top'>
												<span className='icon-image panel-star-level-3'>
													<img src='./Icon/Texture/r99_soul_stone_i00.png' alt='Red Lantern' />
												</span>
												<span>
													<strong>Red Lantern</strong> — 1 pcs.
												</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>	
	)
}

export default OrcFortress
