import React from 'react'
import HeaderWiki from '../HeaderWiki'
import { NavLink } from 'react-router-dom'

const Attributes = props => {
	return (
	<div id='internal' className='wiki'>
		<div className='wrapper'>
			<h1 className='internal-title'>
				Essence Wiki
			</h1>
			<div className='box-wrapper'>
				<HeaderWiki />
				<div className='box-breadcrumb'>
					<NavLink to='/wiki' className='trn' data-trn-key='wiki_gameplay'>Essence Wiki</NavLink>
					<a className='trn' data-trn-key='label_attributesystem'>Attribute System</a>
				</div>

				<div className='box-content wiki-content'>
					<h2 className='wiki-title trn' data-trn-key='label_attributesystem'>Attribute System</h2>
					<div className='wiki-title-image'>
						<img className='wiki-featured-image' src='../assets/img/wiki/attributes_header.jpg' alt='' />
					</div>
					<h3 className='wiki-subtitle trn' data-trn-key='label_generalinformation'>General Information</h3>
					<ul className='trn' data-trn-key='label_attributes_01'>
					    <li>The <b>attribute system</b> is unlocked when a character reaches Lv. 40 and lets you add attribute properties to your character's attack or defense.</li>
					    <li>There are many attribute-related items that can be bought from NPC Svein. Talk to him to learn about attributes. The NPC can be found in Aden, Giran, and Goddard.</li>
					    <li>You can find information about attributes and additional related actions in the Character window (Alt + T).</li>
					    <li>Each attribute is growing stronger when its Spirit levels up. Spirit's level can be increased in the special attribute zones.</li>
					    <li>
					        There are special items for buffing your acquired attribute XP: Blessed Soul obtained for completing daily quests, or Mid-grade Spirit's Blessing and High-grade Spirit's Blessing which can be purchased for Attribute Fragments from
					        Svein.
					    </li>
					    <li>
					        Monster hunting in the attribute zones is not the only way to gain attribute XP, it can be gained through four special items as well: Water Spirit Ore, Fire Spirit Ore, Wind Spirit Ore, and Earth Spirit Ore.
					    </li>
					    <li>Attribute effects come in handy when hunting monsters and raid bosses as well as fighting in PvP. Attribute damage bonus also affects servitors of the summoner. It has an effect on servitor's basic attacks and skills.</li>
					    <li>When using skills related to the so-called 'old attribute system', e.g. Hydro Blast, you still get the attribute damage bonus, even with the Fire attribute active.</li>
					</ul>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_usingattribute'>Using Attribute Power</h3>
					<div className='trn' data-trn-key='label_attributes_02'>
						<p>
							To switch an attribute choose one of the four icons in the Character window (Alt + T). You can do it in the character window as well as on a right sidebar.
						</p>
						<ul>
							<li>Attribute stats depend on the power of your Spirits. Attributes dominate each other. Water is stronger than Fire, Fire is stronger than Earth, Earth is stronger than Wind, and Wind is stronger than Water.</li>
							<li>Depending on the chosen attribute inflicted damage changes as follows:</li>
						</ul>
					</div>
					<div className='table-scrolling'>
						<table className='content-center'>
							<thead>
								<tr>
									<th className='trn' data-trn-key='label_attribute_correlation'>Attribute correlation</th>
									<th className='trn' data-trn-key='label_attribute_damagebonus'>Damage bonus<br/>(basic damage is 100%)</th>
									<th className='trn' data-trn-key='label_example'>Example</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='trn' data-trn-key='label_attribute_strong'>Strong attribute</td>
									<td>130%</td>
									<td><span className='trn' data-trn-key='label_water'>Water</span> vs <span className='trn' data-trn-key='label_fire'>Fire</span></td>
								</tr>
								<tr>
									<td className='trn' data-trn-key='label_attribute_same'>Same attribute</td>
									<td>110%</td>
									<td><span className='trn' data-trn-key='label_water'>Water</span> vs <span className='trn' data-trn-key='label_water'>Water</span></td>
								</tr>
								<tr>
									<td className='trn' data-trn-key='label_attribute_neutral'>Neutral attribute</td>
									<td>110%</td>
									<td><span className='trn' data-trn-key='label_water'>Water</span> vs <span className='trn' data-trn-key='label_wind'>Wind</span> / <span className='trn' data-trn-key='label_earth'>Earth</span></td>
								</tr>
								<tr>
									<td className='trn' data-trn-key='label_attribute_weak'>Weak attribute</td>
									<td>110%</td>
									<td><span className='trn' data-trn-key='label_fire'>Fire</span> vs <span className='trn' data-trn-key='label_water'>Water</span></td>
								</tr>
								<tr>
									<td className='trn' data-trn-key='label_attribute_no'>Non-attribute</td>
									<td>100%</td>
									<td>No attribute</td>
								</tr>
							</tbody>
						</table>
					</div>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_spiritcharacteristics'>Spirit's Characteristics</h3>
					<div>
						<img className='wiki-featured-image' src='../assets/img/wiki/spirit_characteristics.png' alt='' />
					</div>

					<ul className='trn' data-trn-key='label_attributes_03'>
						<li>Click the Spirits button to open the spirit control interface.</li>
						<li>On the <i>Characteristics</i> tab, you can find general information about the character's Spirits.</li>
						<li>Spirit's level and XP required for the next level. Max spirit level is 10 on every evolution level.</li>
						<li>Spirit's stats: Attack, Defense, Critical Rate, and Critical Damage of the corresponding attribute, and their values. These stats increase with each Spirit’s level.</li>
					</ul>
					<div className='table-scrolling'>
						<table className='content-center'>
						    <thead>
						        <tr>
						            <th>Evolution level</th>
						            <th>Stat points per evolution level</th>
						            <th>Total available points</th>
						            <th>
						                Points to be added<br />
						                on evolution level
						            </th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>1</td>
						            <td>10 stat points (1 point per level)</td>
						            <td >10</td>
						            <td>Up to 5 points to any stat</td>
						        </tr>
						        <tr>
						            <td>2</td>
						            <td>10 stat points (1 point per level)</td>
						            <td>20</td>
						            <td>Up to 10 points to any stat</td>
						        </tr>
						        <tr>
						            <td>3</td>
						            <td>20 stat points (2 points per level)</td>
						            <td>40</td>
						            <td>Up to 15 points to any stat</td>
						        </tr>
						        <tr>
						            <td>4</td>
						            <td>20 stat points (2 points per level)</td>
						            <td>60</td>
						            <td>Up to 20 points to any stat</td>
						        </tr>
						        <tr>
						            <td>5</td>
						            <td>20 stat points (2 points per level)</td>
						            <td>80</td>
						            <td>Up to 30 points to any stat</td>
						        </tr>
						    </tbody>
						</table>
					</div>
					<p>
						Spirit stats can be reset and redistributed. To do so you have to click on the Reset button in the Characteristics tab. No matter how many stat points you are going to redistribute you have to pay 500,000 adena for a reset.
					</p>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_evolution'>Evolution</h3>
					<div>
						<img className='wiki-featured-image' src='../assets/img/wiki/spirit_evolution.png' alt='' />
					</div>
					<ul className='trn' data-trn-key='label_attributes_04'>
						<li>On the Evolution tab, you can find information about evolution of your Spirit.</li>
						<li>Evolution level is indicated by stars placed above the spirit's portrait. All Spirits are on the 1st level of evolution by default and can evolve 4 times (up to the 5th level).</li>
						<li>Evolution option is unlocked when Spirit has reached Lv. 10 and keeps 100% of XP.</li>
						<li>Evolution chance is 100% and Evolution level cannot be reset.</li>
					</ul>
					<div className='table-scrolling'>
						<table className='content-center'>
						    <thead>
						        <tr>
						            <th>Evolution levels</th>
						            <th>Evolution stones</th>
						            <th>Adena</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>1st -&gt; 2nd</td>
						            <td>1 fitting evolution stone</td>
						            <td>1,000,000 adena</td>
						        </tr>
						        <tr>
						            <td>2nd -&gt; 3rd</td>
						            <td>3 fitting evolution stones</td>
						            <td>3,000,000 adena</td>
						        </tr>
						        <tr>
						            <td>3rd -&gt; 4th</td>
						            <td>5 fitting evolution stones</td>
						            <td>5,000,000 adena</td>
						        </tr>
						        <tr>
						            <td>4th -&gt; 5th</td>
						            <td>10 fitting evolution stones</td>
						            <td>7,000,000 adena</td>
						        </tr>
						    </tbody>
						</table>
					</div>
					<p>There are many ways to obtain evolution stones:</p>
					<div className='table-scrolling'>
						<table>
						    <thead>
						        <tr>
						            <th>Evolution stones</th>
						            <th className='trn' data-trn-key='label_howtoobtain'>How to obtain</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>
						                Fire Spirit Evolution Stone<br />
						                Water Spirit Evolution Stone<br />
						                Wind Spirit Evolution Stone<br />
						                Earth Spirit Evolution Stone
						            </td>
						            <td>
						                Bosses (Lv. 70-80)<br />
						                <strong>Random craft</strong><br />
						                <strong>L-Coin Store</strong><br />
						                <strong>Orc King's Treasure</strong>, <strong>Orcish Treasure</strong>
						            </td>
						        </tr>
						    </tbody>
						</table>
					</div>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_absorption'>Absorption</h3>
					<div>
						<img className='wiki-featured-image' src='../assets/img/wiki/spirit_absorption.jpg' alt='' />
					</div>					
					<ul className='trn' data-trn-key='label_attributes_05'>
						<li>On the <i>Absorption</i> tab you can level up your Spirit by using Spirit Ore. Also you can increase the level by defeating monsters of opposite attributes.</li>
					    <li>
					    	Max spirit level is 10. To increase the level set a number of required Spirit Ore. At the bottom of the screen, you will see how much XP is obtained from it. The Spirit Ore must be in your inventory.
					        <ul>
					            <li>
					                The Auto Registration button sets the number of Spirit Ore required to reach the max level (i.e. Lv. 10 and 100% of XP). 
					            </li>
					            <li>The Absorption button opens an additional window showing changes that are going to happen if you use Spirit Ore.</li>
					        </ul>
					    </li>
					    <li>
					        Spirit Ore can be obtained by looting bosses or Buying for Attribute Fragments and adena from Svein.
					    </li>
					</ul>

					<hr className='invisible' />
					<h3 className='wiki-subtitle trn' data-trn-key='label_extraction'>Extraction</h3>
					<div>
						<img className='wiki-featured-image' src='../assets/img/wiki/spirit_extraction.jpg' alt='' />
					</div>
					<ul className='trn' data-trn-key='label_attributes_06'>
						<li>On the <i>Extraction</i> tab, you can extract Attribute Fragments that are needed to buy some goods from Svein.</li>
					    <li>
					        Extraction is unlocked on the 2nd spirit level and the 1st evolution level (marked by one star). After the extraction, the Spirit's level is dropped to 1 (0% of XP), although its evolution level remains the same. All stats and extra
					        stat points gained after level ups are reset to values of the previous spirit level.
					    </li>
					    <li>Extraction chance is 100%. A number of extracted Attribute Fragments depends on Spirit's XP. The more XP it has, the more fragments will be extracted.</li>
					    <li>
					        The extraction fee depends only on the Spirit's evolution level and not on the number of extracted fragments.
					    </li>
					</ul>
					<div className='table-scrolling'>
						<table className='content-center'>
						    <thead>
						        <tr>
						            <th className='trn' data-trn-key='label_evolutionlevel'>Evolution level</th>
						            <th className='trn' data-trn-key='label_fee'>Fee</th>
						        </tr>
						    </thead>
						    <tbody>
						        <tr>
						            <td>1</td>
						            <td>100,000 adena</td>
						        </tr>
						        <tr>
						            <td>2</td>
						            <td>200,000 adena</td>
						        </tr>
						        <tr>
						            <td>3</td>
						            <td>300,000 adena</td>
						        </tr>
						        <tr>
						            <td>4</td>
						            <td>600,000 adena</td>
						        </tr>
						        <tr>
						            <td>5</td>
						            <td>1,500,000 adena</td>
						        </tr>
						    </tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}

export default Attributes