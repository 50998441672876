import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = props => {
	return (
		<div>
			<footer>
				<div>
					<img className='footer-logo' src='./assets/img/logo_l2abyss.png' />
					<span>
						L2Abyss.com &copy; 2022
						<small className='trn' data-trn-key='copy'>These servers are an emulator of the Lineage 2 game. The use of the service is for informational purposes only.<br/>You can familiarize yourself with the original version of the game Lineage 2 on the official servers of NCSoft.</small>
					</span>
				</div>
				<div>
					<NavLink to='./terms#totop' className='trn' data-trn-key='terms'>Terms & Conditions</NavLink>
					<NavLink to='./privacity' className='trn' data-trn-key='privacity'>Privacity Policy</NavLink>
					<NavLink to='./agreement' className='trn' data-trn-key='useragreement'>User Agreement</NavLink>
				</div>
			</footer>

			<a href='#totop' className='scrolltop'>	
			</a>

			<div className='votebanners'>
				<a href="https://en.l2oops.com" target="_blank">
					<img src="https://l2oops.com/logo/black_l2oops_mini.png" alt="New Lineage 2 private servers" title="New Lineage 2 private servers" border="0" />
				</a>
				<a href="https://l2topzone.com/vote/id/17417" target="_blank" title="l2topzone" >
					<img src="https://l2topzone.com/vb/l2topzone-Lineage2-vote-banner-normal-2.png" alt="l2topzone.com" border="0" />
				</a>
			</div>
		</div>
	)
}
export default Footer